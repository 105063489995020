import React, { Component } from 'react';
class UserDashboardTopBar extends Component {

    constructor(props) {
        super(props);
        this.interval = '';
        this.state = {};       
    }
    
    render() {
        return (
          <div>
            <div className="top-bar color-scheme-transparent admin-top-bar"></div>    
          </div>
        );
    }
}
export default UserDashboardTopBar;