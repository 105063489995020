import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";

class IndividualIdentification extends React.Component {
  constructor(props) {
    super(props);
    let identificationData = this.props.identificationData;
    this.state = {
      identificationData: identificationData,
    };
  }

  render() {
    const { identificationData } = this.state;
    let displayFinancialProductsInvested = "";
    let individualLabelClassName = "badge-primary";
    // identificationData.gender === "m" ? "badge-success" : "badge-danger";
    let individualLabel = identificationData.gender === "m" ? "Male" : "Female";
    var dobDate = !_.isUndefined(identificationData.dob)
      ? moment(identificationData.dob).format("DD MMMM YYYY")
      : "";

    let maritalStatusLabelClassName = "badge-primary"
    // identificationData.maritialStatus === "s"
    //   ? "badge-success"
    //   : identificationData.maritialStatus === "m"
    //     ? "badge-info"
    //     : identificationData.maritialStatus === "w"
    //       ? "bandge-danger"
    //       : "badge-warning";
    let maritalStatusLabel =
      identificationData.maritialStatus === "s"
        ? "Single"
        : identificationData.maritialStatus === "m"
          ? "Married"
          : identificationData.maritialStatus === "d"
            ? "Divorced"
            : "Widower";

    if (
      !_.isEmpty(identificationData.financialProductsInvested) &&
      !_.isUndefined(identificationData.financialProductsInvested)
    ) {
      let financialProductsInvested =
        identificationData.financialProductsInvested;
      displayFinancialProductsInvested = financialProductsInvested.map(
        (product, mainIndex) => (
          <div className="col-md-3 text-default text-default-color" key={mainIndex}>
            {
              messageConstants
                .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED[
              _.toUpper(product)
              ]
            }
          </div>
        )
      );
    }

    return (
      <div className="identification-data-container">
        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Personal Details </span>
                <Link to="/users" className="pull-right">
                  Back to users list
                </Link>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Full Name :</span>{" "}
                  {!_.isUndefined(identificationData.fullName) &&
                    identificationData.fullName
                    ? identificationData.fullName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span>Previous Name :</span>{" "}
                  {!_.isUndefined(identificationData.previousName) &&
                    identificationData.previousName
                    ? identificationData.previousName
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Other Name :</span>{" "}
                  {!_.isUndefined(identificationData.otherName) &&
                    identificationData.otherName
                    ? identificationData.otherName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span>Gender :</span>{" "}
                  <span className={"badge " + individualLabelClassName}>
                    {individualLabel}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Date Of Birth : </span>
                  {dobDate ? dobDate : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span>Place of birth :</span>{" "}
                  {!_.isUndefined(identificationData.placeOfBirth) &&
                    identificationData.placeOfBirth
                    ? identificationData.placeOfBirth
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Birth Country :</span>{" "}
                  {!_.isUndefined(identificationData.birthCountry) &&
                    identificationData.birthCountry.name
                    ? identificationData.birthCountry.name
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span> Marital status :</span>{" "}
                  <span className={"badge " + maritalStatusLabelClassName}>
                    {maritalStatusLabel}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Nationality :</span>{" "}
                  {!_.isUndefined(identificationData.nationalityOption1) &&
                    identificationData.nationalityOption1
                    ? identificationData.nationalityOption1
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span>Nationality :</span>{" "}
                  {!_.isUndefined(identificationData.nationalityOption2) &&
                    identificationData.nationalityOption2
                    ? identificationData.nationalityOption2
                    : "N/A"}
                </div>
              </div>
            </div>

            {(!_.isUndefined(identificationData.nationalityOption3) ||
              !_.isUndefined(identificationData.nationalityOption4)) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      <span>Nationality :</span>{" "}
                      {!_.isUndefined(identificationData.nationalityOption3) &&
                        identificationData.nationalityOption3
                        ? identificationData.nationalityOption3
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6 mt-xs-3">
                    <div className="text-default text-default-color">
                      <span>Nationality :</span>{" "}
                      {!_.isUndefined(identificationData.nationalityOption4) &&
                        identificationData.nationalityOption4
                        ? identificationData.nationalityOption4
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            {(!_.isUndefined(identificationData.citizenshipCountry1) ||
              !_.isUndefined(identificationData.citizenshipCountry2)) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      <span>
                        {" "}
                      Country of Citizenship/Residency :
                    </span>{" "}
                      {!_.isUndefined(identificationData.citizenshipCountry1) &&
                        identificationData.citizenshipCountry1.name
                        ? identificationData.citizenshipCountry1.name
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6 mt-xs-3">
                    <div className="text-default text-default-color">
                      <span>
                        {" "}
                      Country of Citizenship/Residency :
                    </span>{" "}
                      {!_.isUndefined(identificationData.citizenshipCountry2) &&
                        identificationData.citizenshipCountry2.name
                        ? identificationData.citizenshipCountry2.name
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            {(!_.isUndefined(identificationData.citizenshipCountry3) ||
              !_.isUndefined(identificationData.citizenshipCountry4)) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      <span>
                        Country of Citizenship/Residency :
                    </span>{" "}
                      {!_.isUndefined(identificationData.citizenshipCountry3) &&
                        identificationData.citizenshipCountry3.name
                        ? identificationData.citizenshipCountry3.name
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6 mt-xs-3">
                    <div className="text-default text-default-color">
                      <span>
                        Country of Citizenship/Residency :
                    </span>{" "}
                      {!_.isUndefined(identificationData.citizenshipCountry4) &&
                        identificationData.citizenshipCountry4.name
                        ? identificationData.citizenshipCountry4.name
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Country of legal domicile :
                  </span>{" "}
                  {!_.isUndefined(identificationData.countryLegalDomicile1) &&
                    identificationData.countryLegalDomicile1.name
                    ? identificationData.countryLegalDomicile1.name
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span>
                    Country of legal domicile :
                  </span>{" "}
                  {!_.isUndefined(identificationData.countryLegalDomicile2) &&
                    identificationData.countryLegalDomicile2.name
                    ? identificationData.countryLegalDomicile2.name
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Country of fiscal residence :
                  </span>{" "}
                  {!_.isUndefined(identificationData.countryFiscalResidence1) &&
                    identificationData.countryFiscalResidence1.name
                    ? identificationData.countryFiscalResidence1.name
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6 mt-xs-3">
                <div className="text-default text-default-color">
                  <span>
                    Country of fiscal residence :
                  </span>{" "}
                  {!_.isUndefined(identificationData.countryFiscalResidence2) &&
                    identificationData.countryFiscalResidence2.name
                    ? identificationData.countryFiscalResidence2.name
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Contact Details </span>
              </div>
            </h6>

            <h3>Permanent Address Details</h3>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Building name :</span>{" "}
                  {!_.isUndefined(
                    identificationData.permanentAddressBuildingName
                  ) && identificationData.permanentAddressBuildingName
                    ? identificationData.permanentAddressBuildingName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Street No :</span>{" "}
                  {!_.isUndefined(
                    identificationData.permanentAddressStreetNo
                  ) && identificationData.permanentAddressStreetNo
                    ? identificationData.permanentAddressStreetNo
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Street :</span>{" "}
                  {!_.isUndefined(identificationData.permanentAddressStreet) &&
                    identificationData.permanentAddressStreet
                    ? identificationData.permanentAddressStreet
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Place :</span>{" "}
                  {!_.isUndefined(identificationData.permanentAddressPlace) &&
                    identificationData.permanentAddressPlace
                    ? identificationData.permanentAddressPlace
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Country :</span>{" "}
                  {!_.isUndefined(identificationData.permanentAddressCountry) &&
                    identificationData.permanentAddressCountry.name
                    ? identificationData.permanentAddressCountry.name
                    : "N/A"}
                </div>
              </div>
            </div>

            <h3>Mailing address</h3>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Building name :</span>{" "}
                  {!_.isUndefined(
                    identificationData.mailingAddressBuildingName
                  ) && identificationData.mailingAddressBuildingName
                    ? identificationData.mailingAddressBuildingName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>PO Box :</span>{" "}
                  {!_.isUndefined(identificationData.mailingAddressPoBox) &&
                    identificationData.mailingAddressPoBox
                    ? identificationData.mailingAddressPoBox
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Street :</span>{" "}
                  {!_.isUndefined(identificationData.mailingAddressStreet) &&
                    identificationData.mailingAddressStreet
                    ? identificationData.mailingAddressStreet
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Street No :</span>{" "}
                  {!_.isUndefined(identificationData.mailingAddressStreetNo) &&
                    identificationData.mailingAddressStreetNo
                    ? identificationData.mailingAddressStreetNo
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Place :</span>{" "}
                  {!_.isUndefined(identificationData.mailingAddressPlace) &&
                    identificationData.mailingAddressPlace
                    ? identificationData.mailingAddressPlace
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Country :</span>{" "}
                  {!_.isUndefined(identificationData.mailingAddressCountry) &&
                    identificationData.mailingAddressCountry.name
                    ? identificationData.mailingAddressCountry.name
                    : "N/A"}
                </div>
              </div>
            </div>

            <h3>Contact Details</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Telephone :</span>{" "}
                  {!_.isUndefined(
                    identificationData.contactDetailsTelephone1
                  ) && identificationData.contactDetailsTelephone1
                    ? identificationData.contactDetailsTelephone1
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Telephone :</span>{" "}
                  {!_.isUndefined(
                    identificationData.contactDetailsTelephone2
                  ) && identificationData.contactDetailsTelephone2
                    ? identificationData.contactDetailsTelephone2
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Fax :</span>{" "}
                  {!_.isUndefined(identificationData.contactDetailsFax1) &&
                    identificationData.contactDetailsFax1
                    ? identificationData.contactDetailsFax1
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Fax :</span>{" "}
                  {!_.isUndefined(identificationData.contactDetailsFax2) &&
                    identificationData.contactDetailsFax2
                    ? identificationData.contactDetailsFax2
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Email :</span>{" "}
                  {!_.isUndefined(identificationData.contactDetailsEmail1) &&
                    identificationData.contactDetailsEmail1
                    ? identificationData.contactDetailsEmail1
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span> Email :</span>{" "}
                  {!_.isUndefined(identificationData.contactDetailsEmail2) &&
                    identificationData.contactDetailsEmail2
                    ? identificationData.contactDetailsEmail2
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Professional Details </span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Status :</span>{" "}
                  {!_.isUndefined(identificationData.professionalStatus) &&
                    identificationData.professionalStatus
                    ? messageConstants.LABEL_INDIVIDUAL_PROFESSIONAL_STATUS[
                    _.toUpper(identificationData.professionalStatus)
                    ]
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Job Title / Function :
                  </span>{" "}
                  {!_.isUndefined(identificationData.jobTitle) &&
                    identificationData.jobTitle
                    ? identificationData.jobTitle
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Sector / Industry :</span>{" "}
                  {!_.isUndefined(identificationData.jobSectorIndustry) &&
                    identificationData.jobSectorIndustry
                    ? identificationData.jobSectorIndustry
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Name of employer :</span>{" "}
                  {!_.isUndefined(identificationData.employerName) &&
                    identificationData.employerName
                    ? identificationData.employerName
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Country of business :
                  </span>{" "}
                  {!_.isUndefined(identificationData.businessCountry) &&
                    identificationData.businessCountry.name
                    ? identificationData.businessCountry.name
                    : "N/A"}
                </div>
              </div>
            </div>

            <h3 className="text-large mt-3">Politically Exposed Person</h3>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Name :</span>{" "}
                  {!_.isUndefined(identificationData.politicalName) &&
                    identificationData.politicalName
                    ? identificationData.politicalName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span> Function :</span>{" "}
                  {!_.isUndefined(identificationData.politicalFunction) &&
                    identificationData.politicalFunction
                    ? identificationData.politicalFunction
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Name :</span>{" "}
                  {!_.isUndefined(identificationData.politicalName2) &&
                    identificationData.politicalName2
                    ? identificationData.politicalName2
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Function :</span>{" "}
                  {!_.isUndefined(identificationData.politicalFunction2) &&
                    identificationData.politicalFunction2
                    ? identificationData.politicalFunction2
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Business Intentions</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    How did you learn about us and our services? :
                  </span>{" "}
                  {!_.isUndefined(identificationData.learnedAboutOurService) &&
                    identificationData.learnedAboutOurService
                    ? messageConstants
                      .LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE[
                    _.toUpper(identificationData.learnedAboutOurService)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.referralByName) &&
              identificationData.referralByName !== "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-default text-default-color">
                      Referral Name :{" "}
                      {identificationData.referralByName
                        ? identificationData.referralByName
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    Which amount of assets do you expect to be serviced by us? :
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.assetAmountExpectedToServiced
                  ) && identificationData.assetAmountExpectedToServiced
                    ? identificationData.assetAmountExpectedToServiced
                    : "N/A"}{" "}
                  USD
                </div>

                <div className="text-default text-default-color">
                  {!_.isUndefined(
                    identificationData.assetPercentageExpectedToServiced
                  ) && identificationData.assetPercentageExpectedToServiced
                    ? identificationData.assetPercentageExpectedToServiced
                    : "N/A"}{" "}
                  % of total net wealth
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    What is the expected annual in/out flow turnover of assets?
                    :
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.expectedInOutTurnoverFlow
                  ) && identificationData.expectedInOutTurnoverFlow
                    ? messageConstants.LABEL_INDIVIDUAL_EXPECTED_IN_OUT_FLOW[
                    _.toUpper(identificationData.expectedInOutTurnoverFlow)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Wealth And Income</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    What is your estimated global net wealth? :
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.estimatedGlobalNetWealth
                  ) && identificationData.estimatedGlobalNetWealth
                    ? messageConstants.LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH[
                    _.toUpper(identificationData.estimatedGlobalNetWealth)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.estimatedGlobalNetWealth) &&
              !_.isUndefined(
                identificationData.estimatedGlobalNetWealthOther
              ) &&
              identificationData.estimatedGlobalNetWealth === "other" &&
              identificationData.estimatedGlobalNetWealthOther !== "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      {identificationData.estimatedGlobalNetWealthOther
                        ? identificationData.estimatedGlobalNetWealthOther
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    How have you built up your global net wealth? :
                  </span>{" "}
                  {!_.isUndefined(identificationData.builtGlobalWealth) &&
                    identificationData.builtGlobalWealth
                    ? messageConstants
                      .LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH[
                    _.toUpper(identificationData.builtGlobalWealth)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.builtGlobalWealth) &&
              !_.isUndefined(identificationData.builtGlobalWealthOther) &&
              identificationData.builtGlobalWealth === "other" &&
              identificationData.builtGlobalWealthOther !== "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      {!_.isUndefined(
                        identificationData.builtGlobalWealthOther
                      ) && identificationData.builtGlobalWealthOther
                        ? identificationData.builtGlobalWealthOther
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    What is your annual regular net income? :
                  </span>{" "}
                  {!_.isUndefined(identificationData.annualNetIncome) &&
                    identificationData.annualNetIncome
                    ? messageConstants
                      .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                    _.toUpper(identificationData.annualNetIncome)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span >
                    How do you make your regular net income? :
                  </span>{" "}
                  {!_.isUndefined(identificationData.regularIncome) &&
                    identificationData.regularIncome
                    ? messageConstants
                      .LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME[
                    _.toUpper(identificationData.regularIncome)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.regularIncomeOther) &&
              identificationData.regularIncomeOther === "other" &&
              identificationData.regularIncomeOther !== "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      {identificationData.regularIncomeOther
                        ? identificationData.regularIncomeOther
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Regular net income country:
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.regularNetIncomeCountry1
                  ) && identificationData.regularNetIncomeCountry1.name
                    ? identificationData.regularNetIncomeCountry1.name
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Regular net income country:
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.regularNetIncomeCountry2
                  ) && identificationData.regularNetIncomeCountry2.name
                    ? identificationData.regularNetIncomeCountry2.name
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    {" "}
                    Regular net income country:
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.regularNetIncomeCountry3
                  ) && identificationData.regularNetIncomeCountry3.name
                    ? identificationData.regularNetIncomeCountry3.name
                    : "N/A"}
                </div>
              </div>

              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Regular net income country:
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.regularNetIncomeCountry4
                  ) && identificationData.regularNetIncomeCountry4.name
                    ? identificationData.regularNetIncomeCountry4.name
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">
                  Your experience and understanding of financial markets and
                  instruments
                </span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    How is your knowledge and understanding of financial markets
                    and products? :
                  </span>{" "}
                  {!_.isUndefined(
                    identificationData.understandingFinancialMarket
                  ) && identificationData.understandingFinancialMarket
                    ? messageConstants
                      .LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET[
                    _.toUpper(
                      identificationData.understandingFinancialMarket
                    )
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(
              identificationData.understandingFinancialMarketOther
            ) &&
              identificationData.understandingFinancialMarket === "other" &&
              identificationData.understandingFinancialMarketOther !== "" && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Other :{" "}
                      {identificationData.understandingFinancialMarketOther
                        ? identificationData.understandingFinancialMarketOther
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  <span>
                    Active On Financial Market :
                  </span>{" "}
                  {!_.isUndefined(identificationData.activeOnFinancialMarket) &&
                    identificationData.activeOnFinancialMarket
                    ? messageConstants
                      .LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET[
                    _.toUpper(identificationData.activeOnFinancialMarket)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.activeOnFinancialMarketOther) &&
              identificationData.activeOnFinancialMarket === "other" &&
              identificationData.activeOnFinancialMarketOther !== "" && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-default text-default-color">
                      Active On Financial Market Other :{" "}
                      {identificationData.activeOnFinancialMarketOther
                        ? identificationData.activeOnFinancialMarketOther
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Assets Managed Before :
                  </span>{" "}
                  {!_.isUndefined(identificationData.assetsManagedBefore) &&
                    identificationData.assetsManagedBefore
                    ? messageConstants
                      .LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE[
                    _.toUpper(identificationData.assetsManagedBefore)
                    ]
                    : "N/A"}
                </div>
              </div>
            </div>

            {!_.isUndefined(identificationData.assetsManagedBeforeOther) &&
              identificationData.assetsManagedBefore === "other" &&
              identificationData.assetsManagedBeforeOther !== "" && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Assets Managed Before Other :{" "}
                      {identificationData.assetsManagedBeforeOther
                        ? identificationData.assetsManagedBeforeOther
                        : "N/A"}
                    </div>
                  </div>
                </div>
              )}

            {displayFinancialProductsInvested !== "" && (
              <div>
                <label className="text-default-color text-default">
                  In which financial products have you already invested on a
                  regular basis?
                </label>
                <div className="row">{displayFinancialProductsInvested}</div>

                {!_.isUndefined(
                  identificationData.financialProductsInvestedOther
                ) &&
                  identificationData.financialProductsInvestedOther !== "" && (
                    <div>
                      Other{" "}
                      {identificationData.financialProductsInvestedOther
                        ? identificationData.financialProductsInvestedOther
                        : "N/A"}
                    </div>
                  )}
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <span className="text-default text-default-color">
                  {" "}
                  How often do you take investments decisions (buy/sell)?
                </span>{" "}
                <span className="text-default text-default-color">
                  {!_.isUndefined(identificationData.investmentDecisions) &&
                    identificationData.investmentDecisions
                    ? messageConstants
                      .LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS[
                    _.toUpper(identificationData.investmentDecisions)
                    ]
                    : "N/A"}
                </span>

              </div>
            </div>
          </div>
        </div>

        {!_.isUndefined(identificationData.signature) &&
          identificationData.signature !== "" && (
            <div className="element-box">
              <div>
                <h6 className="element-header">
                  <div>
                    <span className="text-xl">Signature</span>
                  </div>
                </h6>

                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <img
                        src={identificationData.signature}
                        height={200}
                        className="signature-container-img"
                        alt="Signature"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
export default IndividualIdentification;
