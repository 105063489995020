import React, { Component } from 'react';
import Modal from "react-responsive-modal";

class EditEventModal extends Component {

  render() {
    const { editModalOpen, onCloseModal, onInputValueChange, minimumVerifiers, updateVerificationEvent, buttonLoading } = this.props;
    let buttonDisabled = (buttonLoading === true) ? true : false;
    return (
      <Modal open={editModalOpen} onClose={onCloseModal} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content verifier-modal">
                    <h4 className="onboarding-title"> Edit Verification Event </h4>
                    <form>
                      <div className="form-group">
                        <label htmlFor="minimumVerifiers">Minimum Verifiers<span className="required-field">*</span></label>
                        <input className="form-control" id="minimumVerifiers" placeholder="Enter Minimum Verifiers" type="text" name="minimumVerifiers" onChange={onInputValueChange} value={(minimumVerifiers && minimumVerifiers > 0) ? minimumVerifiers : 0} />
                      </div>
                      <div>
                        <button className="btn btn-primary btn-sm" type="button" onClick={updateVerificationEvent} disabled={buttonDisabled}> Submit {buttonLoading && (
                          <i className="fa-spin fa fa-spinner text-white ml-1" />
                        )}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EditEventModal;