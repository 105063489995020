import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class KycList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      data: [],
      filterByEmail: "",
      filterByContactno: "",
      loading: false,
    };
  }

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  refreshCurrenttable = () => {
    this.getRecords();
  };

  getRecords = async () => {
    const api = new Api();
    const {
      sizePerPage,
      page,
      filterByName,
      filterByEmail,
      filterByContactno,
    } = this.state;
    const authenticationToken = this.props.authToken;
    this.setState({ loading: true });

    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/kyc/requests", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
          filterByContactno: filterByContactno,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.requests,
            totalSize: response.data.number,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      } else {
        this.setState({
          loading: false,
        });
        toast.message(response.message);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  };

  filterRecords = () => {
    this.getRecords();
  };

  clearFilterRecords = () => {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByContactno: "",
      },
      async () => {
        this.getRecords();
      }
    );
  };

  transactionOperation = async (userId, type) => {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("user/update/kyc", {
        userId: userId,
        type: type,
      });

      if (response.code === 200) {
        this.getRecords();
        toast.success(response.message);
      } else {
        toast.error(response.message);
    }
  };

  componentWillMount() {
    document.title =
      messageConstants.KYC_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  kycOperation = (userId, type) => {
    let label = type === "approve" ? "accept" : "reject";
    let _self = this;
    let buttons = "";
    if (type === "approve") {
      buttons = [
        {
          label: "Cancel",
        },
        {
          label: "Accept",
          onClick: () => _self.transactionOperation(userId, "approve"),
        },
      ];
    } else {
      buttons = [
        {
          label: "Cancel",
        },
        {
          label: "Reject",
          onClick: () => _self.transactionOperation(userId, "reject"),
        },
      ];
    }
    confirmAlert({
      title: "Are you sure?",
      message: `Are you sure want to ${label} kyc?`,
      buttons: buttons,
    });
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      filterByContactno,
      loading,
    } = this.state;
    let _self = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "Email",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{row.userId && row.userId.email}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "contactNo",
        text: "Contact No",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.status === "a") {
            label = "Verified";
            className = "badge-success";
          } else if (row.status === "r") {
            label = "Rejected";
            className = "badge-danger";
          }
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <span className={`badge ${className}`}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let redirectLink = "/kyc_detail/" + row._id;
          return (
            <div className="text-left">
              <div className="d-inline-block">
                {!row.adminVerifiedStatus && (
                  <div className="btn-group mr-1 mb-1">
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="btn default-border-btn dropdown-toggle"
                      data-toggle="dropdown"
                      id="dropdownMenuButton6"
                      type="button"
                    >
                      Action
                    </button>
                    <div
                      aria-labelledby="dropdownMenuButton6"
                      className="dropdown-menu"
                    >
                      <Link to={redirectLink} className="dropdown-item">
                        Details
                      </Link>
                      {row.status === "u" && !row.adminVerifiedStatus && (
                        <span>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() =>
                              _self.kycOperation(row.userId, "approve")
                            }
                          >
                            {" "}
                            Accept
                          </button>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() =>
                              _self.kycOperation(row.userId, "reject")
                            }
                          >
                            {" "}
                            Reject
                          </button>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter KYC Requests</h5>
                <form className="form-inline">
                  <div className="row width-full-issue">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Name</label>
                        <input
                          className="form-control mb-1 mr-sm-2 mr-xs-1"
                          name="filterByName"
                          id="filterByName"
                          placeholder="Full Name"
                          type="text"
                          onChange={this.onchange}
                          value={filterByName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Email</label>
                        <input
                          className="form-control mb-1 mr-sm-2 mr-xs-1"
                          placeholder="Email Address"
                          type="text"
                          name="filterByEmail"
                          id="filterByEmail"
                          onChange={this.onchange}
                          value={filterByEmail}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Contact No</label>
                        <input
                          className="form-control mb-1 mr-sm-2 mr-xs-1"
                          placeholder="Contact No"
                          type="text"
                          name="filterByContactno"
                          id="filterByContactno"
                          onChange={this.onchange}
                          value={filterByContactno}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="userList-btn-group">
                    <button
                      className="btn default-btn"
                      type="button"
                      onClick={this.filterRecords}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      className="btn default-border-btn ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      {" "}
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="element-wrapper mt-4">
              <div className="element-box">
                <h5 className="form-header d-flex justify-content-between">
                  <span className="pull-left">Manage KYC Requests</span>
                  <button
                    className="mb-2 btn default-btn "
                    type="button"
                    onClick={() => this.refreshCurrenttable()}
                  >
                    Refresh
                  </button>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default KycList;
