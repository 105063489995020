import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import CKEditor from "ckeditor4-react";
import ChangePasswordModal from "./ChangePasswordModal";
import EditProfileModal from "./EditProfileModal";
import "icheck/skins/all.css";
import { Radio, RadioGroup } from "react-ui-icheck";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import auth from "../../utils/auth";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      phoneNumber: "",
      email: "",
      changePasswordModalOpen: false,
      editProfileModalOpen: false,
      updateEthAddressButtonLoading: false,
      changePasswordButtonLoading: false,
      editProfileButtonLoading: false,
      toTpUri: "",
      twoFAFormattedKey: "",
      twoFAStatus: false,
      twoFAcode: "",
      receivingEthAddress: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      currentKycStatus: "u",
      role: "",
      updateNotificationButtonLoading: false,
      bankName: "",
      bankAccountNumber: "",
      bankSwiftCode: "",
      bankBicCode: "",
      wireTranferButtonLoading: false,
      relationshipDisclosureData: "",
      termsOfEngagementData: "",
      relationshipDisclosureDataLoading: false,
      termsOfEngagementDataLoading: false,
      fatcaCrsStatus: "",
      identificationStatus: "",
      crsFatcaLoading: false,
      countryName: "",
      countryIdValue: null,
      countriesList: [{ value: this.countryIdValue, label: this.countryName }],
    };
    this.optionRef = React.createRef();
    this.onchange = this.onchange.bind(this);
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
    this.onChangePasswordCloseModal =
      this.onChangePasswordCloseModal.bind(this);
    this.openEditProfileModal = this.openEditProfileModal.bind(this);
    this.onEditProfileCloseModal = this.onEditProfileCloseModal.bind(this);
    this.onSubmitEditData = this.onSubmitEditData.bind(this);
    this.twoFaAuthenticationChange = this.twoFaAuthenticationChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateWireTransferDetails = this.updateWireTransferDetails.bind(this);
    this.saveAgreementDetails = this.saveAgreementDetails.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.saveFatcaIdentificationDetails =
      this.saveFatcaIdentificationDetails.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.MY_ACCOUNT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getUserDetails();
    this.getCountries();
  }

  onEditorChange(evt, sessionName) {
    this.setState({
      [sessionName]: evt.editor.getData(),
    });
  }

  async getUserDetails() {
    const api = new Api();
    const profileParams = {
      twoFA: true,
    };

    let authenticationToken = this.props.authToken;
    const profileDetails = await api
      .setToken(authenticationToken)
      .create("user/getUserDetails", profileParams);

    if (profileDetails.code === 200) {
      const stateObj = {};
      stateObj["fullName"] = profileDetails.data.fullName;
      stateObj["phoneNumber"] = profileDetails.data.phoneNumber;
      stateObj["email"] = profileDetails.data.email;
      stateObj["countryName"] = profileDetails.data.country;

      if (profileDetails.data.fatcaSettingsEnabled) {
        stateObj["fatcaCrsStatus"] = profileDetails.data.fatcaSettingsEnabled;
      }

      if (profileDetails.data.identificationSettingsEnabled) {
        stateObj["identificationStatus"] =
          profileDetails.data.identificationSettingsEnabled;
      }

      let wireTransferDetails = profileDetails.data.wireTransferDetails;
      if (wireTransferDetails) {
        if (wireTransferDetails.bankName) {
          stateObj.bankName = wireTransferDetails.bankName;
        }
        if (wireTransferDetails.bankAccountNumber) {
          stateObj.bankAccountNumber = wireTransferDetails.bankAccountNumber;
        }
        if (wireTransferDetails.bankSwiftCode) {
          stateObj.bankSwiftCode = wireTransferDetails.bankSwiftCode;
        }
        if (wireTransferDetails.bankBicCode) {
          stateObj.bankBicCode = wireTransferDetails.bankBicCode;
        }
      }
      if (
        !_.isUndefined(profileDetails) &&
        profileDetails.data.relationshipDisclosureData
      ) {
        stateObj.relationshipDisclosureData =
          profileDetails.data.relationshipDisclosureData;
      }

      if (
        !_.isUndefined(profileDetails) &&
        profileDetails.data.termsOfEngagementData
      ) {
        stateObj.termsOfEngagementData =
          profileDetails.data.termsOfEngagementData;
      }

      if (
        !_.isUndefined(profileDetails) &&
        profileDetails.data.twoFAFormattedKey
      ) {
        stateObj.twoFAFormattedKey = profileDetails.data.twoFAFormattedKey;
      }

      if (!_.isUndefined(profileDetails) && profileDetails.data.toTpUri) {
        stateObj.toTpUri = profileDetails.data.toTpUri;
      }

      if (!_.isUndefined(profileDetails) && profileDetails.data.twoFAStatus) {
        stateObj.twoFAStatus = profileDetails.data.twoFAStatus;
      }
      this.setState(stateObj);
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  getCountries = async () => {
    try {
      const api = new Api();
      const response = await api.get("user/getCountries");
      let countriesList = [];
      if (response.code === 200) {
        if (response.data.countries) {
          response.data.countries.forEach((country) => {
            let obj = { value: country._id, label: country.name };
            countriesList.push(obj);
          });
          if (countriesList) {
            this.setState({
              countriesList: countriesList
            });
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async twoFaAuthenticationChange(type = "enable") {
    let { twoFAcode } = this.state;
    let authenticationToken = this.props.authToken;
    let authUserInfo = this.props.authUserInfo;
    let twoFAStatus = type === "disable" ? false : true;
    if (
      !_.isUndefined(twoFAcode) &&
      authUserInfo.email !== "" &&
      twoFAcode !== ""
    ) {
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/verify2FA", {
          email: authUserInfo.email,
          code: twoFAcode,
          status: twoFAStatus,
        });
      if (response.code === 200) {
        this.setState({
          twoFAcode: "",
          twoFAStatus: response.data.twoFAStatus,
          toTpUri: response.data.toTpUri,
        });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_TWO_FA_CODE);
    }
  }

  async updatePassword() {
    let { oldPassword, newPassword, confirmNewPassword } = this.state;
    let authenticationToken = this.props.authToken;
    if (
      !_.isUndefined(oldPassword) &&
      oldPassword !== "" &&
      newPassword !== "" &&
      newPassword === confirmNewPassword
    ) {
      this.setState({ changePasswordButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/changePassword", {
          oldPassword: oldPassword,
          newPassword: newPassword,
        });
      this.setState({ changePasswordButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onChangePasswordCloseModal();
        setTimeout(() => {
          auth.clearToken();
          auth.clearUserInfo();
        }, 1100);
      } else {
        toast.error(response.message);
      }
    } else {
      let msg =
        newPassword !== confirmNewPassword
          ? "Passwords entered do not match"
          : messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openChangePasswordModal() {
    this.setState({ changePasswordModalOpen: true });
  }

  onChangePasswordCloseModal() {
    this.setState({ changePasswordModalOpen: false });
  }

  openEditProfileModal() {
    this.setState({ editProfileModalOpen: true });
  }

  onEditProfileCloseModal() {
    this.setState({ editProfileModalOpen: false });
  }

  onSubmitEditData() {
    this.getUserDetails();
  }

  async handleRadioChange(e, stateName) {
    this.setState({
      [stateName]: e.target.value,
    });
  }

  async updateWireTransferDetails() {
    let { bankName, bankSwiftCode, bankAccountNumber, bankBicCode } =
      this.state;
    if (bankName !== "" && bankAccountNumber !== "") {
      this.setState({
        wireTranferButtonLoading: true,
      });

      let authenticationToken = this.props.authToken;
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("supplier/wire_transfer/update", {
          bankName: bankName,
          bankAccountNumber: bankAccountNumber,
          bankSwiftCode: bankSwiftCode,
          bankBicCode: bankBicCode,
        });
      if (response.code === 200) {
        this.setState({
          wireTranferButtonLoading: false,
        });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(
        messageConstants.ERROR_MSG_REQUIRED_MESSAGE_FOR_WIRE_TRANSFER
      );
    }
  }

  async saveFatcaIdentificationDetails() {
    try {
      let { fatcaCrsStatus, identificationStatus } = this.state;
      let authenticationToken = this.props.authToken;
      if (fatcaCrsStatus || identificationStatus) {
        this.setState({
          crsFatcaLoading: true,
        });
        const api = new Api();
        const response = await api
          .setToken(authenticationToken)
          .create("user/dealer/fatca/settings/update", {
            fatcaCrsStatus,
            identificationStatus,
          });
        if (response.code === 200) {
          this.setState({
            crsFatcaLoading: false,
          });
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error("");
      }
    } catch (e) {
      console.log("e", e);
    }
  }

  async saveAgreementDetails(event, type = "relationshipDislosure") {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    try {
      const loadingVar =
        type === "termsofengagement"
          ? "termsOfEngagementDataLoading"
          : "relationshipDisclosureDataLoading";
      this.setState({ [loadingVar]: true });
      const { relationshipDisclosureData, termsOfEngagementData } = this.state;
      const paramsData = {};
      if (type === "termsofengagement") {
        paramsData["termsOfEngagementData"] = termsOfEngagementData;
      } else {
        paramsData["relationshipDisclosureData"] = relationshipDisclosureData;
      }
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/dealer/kyc/agreement/details", paramsData);
      if (response) {
        this.setState({ [loadingVar]: false });
      }
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (event) {
      console.log("event", event);
    }
  }

  onMenuOpen = () => {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  };

  handleCountryChange = (selectedOption) => {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
    });
  }

  render() {
    let {
      toTpUri,
      twoFAStatus,
      twoFAFormattedKey,
      twoFAcode,
      fullName,
      email,
      phoneNumber,
      relationshipDisclosureData,
      termsOfEngagementData,
      termsOfEngagementDataLoading,
      relationshipDisclosureDataLoading,
      fatcaCrsStatus,
      identificationStatus,
      crsFatcaLoading,
      countryName
    } = this.state;

    let qrCodeImg = "";
    let twofaButtonClassName = "";
    if (!twoFAStatus && toTpUri !== "") {
      qrCodeImg =
        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=" +
        toTpUri;
    }
    let twofaLabel = "Disabled";
    let twofaClassName = "badge-danger";
    let buttonLabel = "Enable 2FA";
    let buttonType = "enable";
    if (twoFAStatus === true) {
      twofaLabel = "Enabled";
      twofaClassName = "badge-success";
      buttonLabel = "Disable 2FA";
      buttonType = "disable";
      twofaButtonClassName = "btn-danger";
    }

    return (
      <div className="my-account-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 accountInfo">
                <div
                  className="dark-blue-theme-color card-inner-padding my-account-header-block h-100
                d-flex justify-content-between flex-column"
                >
                  <div>
                    <h5>
                      <span className="text-xl my-account-card-title">
                        Account Information
                      </span>
                      <button
                        className="btn default-border-btn pull-right"
                        onClick={() => this.openEditProfileModal()}
                      >
                        Edit
                      </button>
                    </h5>
                    <div style={{ margin: "18px 0px" }}>
                      <div className="text-default text-default-color text-margin">
                        Name : <span className="">{fullName}</span>
                      </div>
                      <div className="text-margin text-default text-default-color">
                        Email : <span className="">{email}</span>
                      </div>
                      <div className="text-margin text-default text-default-color">
                        Contact :{" "}
                        <span className="">
                          {phoneNumber !== "" ? phoneNumber : "N/A"}
                        </span>
                      </div>
                      <div className="text-margin text-default text-default-color">
                        Country : <span className="pl-0">{countryName}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn default-border-btn"
                      onClick={() => this.openChangePasswordModal()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="dark-blue-theme-color card-inner-padding my-account-header-block two-factor-block">
                  <h5>
                    <span className="text-xl my-account-card-title">
                      2 Factor Authentication
                    </span>
                    <span className="pull-right">
                      <span className="text-default">Current Status</span>{" "}
                      <label className={"badge " + twofaClassName}>
                        {twofaLabel}
                      </label>
                    </span>
                  </h5>

                  <div className="mt-2 text-default text-default-color">
                    Two-factor authentication is a method for protection your
                    web account. When it is activated you need to enter not only
                    your password, but also a special code.
                  </div>

                  <div className="row mt-3 flex-sm-nowrap justify-content-center">
                    {qrCodeImg !== "" && (
                      <div className=" ml-2">
                        <img alt="" src={qrCodeImg} height="150" />
                      </div>
                    )}

                    <div className="ml-2 two-factor-desc-text">
                      {!twoFAStatus && (
                        <div className="ml-2">
                          <div className="text-default text-default-color">
                            Open up your 2FA mobile app and scan the following
                            QR barcode. If your 2FA mobile app does not support
                            QR barcodes, enter in the following number:
                          </div>
                          <h5 className="mt-4 text-large text-default-color">
                            {twoFAFormattedKey}
                          </h5>
                        </div>
                      )}
                      <div className="mt-4 ml-2">
                        <label className="text-default text-default-color">
                          2FA Code
                        </label>
                        <input
                          className="form-control two-fa-code-input"
                          type="text"
                          name="twoFAcode"
                          id="twoFAcode"
                          placeholder="Enter 2FA code"
                          onChange={this.onchange}
                          value={twoFAcode}
                        />
                        <button
                          className={
                            "btn default-border-btn" + twofaButtonClassName
                          }
                          onClick={() =>
                            this.twoFaAuthenticationChange(buttonType)
                          }
                        >
                          {buttonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row mt-4 row-eq-height ">
              <div className="col-sm-4 col-xs-4 col-md-3 my-account-details">
                <div className="color-white p-3 dark-blue-theme-color my-account-header-block h-100">
                  <h5>
                    <span className="color-white">Account Information</span>
                    <button
                      className="btn btn-primary account-button pull-right"
                      onClick={() => this.openEditProfileModal()}
                    >
                      Edit
                    </button>
                  </h5>

                  <div className="mt-3">
                    Name : <span className="">{fullName}</span>
                  </div>

                  <div className="mt-3">
                    Email : <span className="">{email}</span>
                  </div>

                  <div className="mt-3">
                    Contact :{" "}
                    <span className="">
                      {phoneNumber !== "" ? phoneNumber : "N/A"}
                    </span>
                  </div>

                  <div className="mt-3 text-center">
                    <button
                      className="btn btn-primary account-button my-account-button"
                      onClick={() => this.openChangePasswordModal()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-8 col-xs-8 col-md-9 my-account-details">
                <div className=" ">
                  <div className="color-white p-3 dark-blue-theme-color my-account-header-block two-factor-block">
                    <h5>
                      <span className="color-white">
                        2 Factor Authentication
                      </span>
                      <span className="pull-right">
                        <span className="color-white">Current Status</span>{" "}
                        <label className={"badge " + twofaClassName}>
                          {twofaLabel}
                        </label>
                      </span>
                    </h5>

                    <div className="mt-2 color-white two-factor-heading-text two-factor-heading-header">
                      Two-factor authentication is a method for protection your
                      web account. When it is activated you need to enter not
                      only your password, but also a special code.
                    </div>

                    <div className="row mt-3">
                      {qrCodeImg !== "" && (
                        <div className="col-md-2">
                          <img alt="" src={qrCodeImg} height="150" />
                        </div>
                      )}

                      <div className="col-md-10">
                        {!twoFAStatus && (
                          <div>
                            <div className="color-white two-factor-heading-text">
                              Open up your 2FA mobile app and scan the following
                              QR barcode. If your 2FA mobile app does not
                              support QR barcodes, enter in the following
                              number:
                            </div>
                            <h5 className="color-white mt-4 text-center">
                              {twoFAFormattedKey}
                            </h5> Received `false` for a non-boolean attribute `cursor`.
                          </div>
                        )}
                        <div className="mt-4">
                          <label>2FA Code</label>
                          <input
                            className="form-control two-fa-code-input"
                            type="text"
                            name="twoFAcode"
                            id="twoFAcode"
                            placeholder="Enter 2FA code"
                            onChange={this.onchange}
                            value={twoFAcode}
                          />
                          <button
                            className={
                              "btn twofa-submit-button pl-3 " +
                              twofaButtonClassName
                            }
                            onClick={() =>
                              this.twoFaAuthenticationChange(buttonType)
                            }
                          >
                            {buttonLabel}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h5 className="mb-3">Relationship Disclosure</h5>
                  <CKEditor
                    onChange={(e) =>
                      this.onEditorChange(e, "relationshipDisclosureData")
                    }
                    data={relationshipDisclosureData}
                  />
                  <div className="mt-3  d-flex justify-content-end">
                    <button
                      className="btn default-border-btn"
                      onClick={(e) => this.saveAgreementDetails(e)}
                    >
                      Update
                      {relationshipDisclosureDataLoading && (
                        <i className="fa-spin fa fa-spinner ml-1" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h5 className="mb-3">
                    Terms Of Engagement&nbsp;
                    <i
                      className="fa fa-info-circle"
                      id="uploadDocumentsInfo"
                    ></i>
                  </h5>

                  <UncontrolledPopover
                    trigger="hover"
                    placement="bottom"
                    target="uploadDocumentsInfo"
                  >
                    <PopoverBody>
                      You may leave this section blank if Terms of Engagement is
                      not required for KYC onboarding.
                    </PopoverBody>
                  </UncontrolledPopover>

                  <CKEditor
                    onChange={(e) =>
                      this.onEditorChange(e, "termsOfEngagementData")
                    }
                    data={termsOfEngagementData}
                  />
                  <div className="mt-3 d-flex justify-content-end">
                    <button
                      className="btn default-border-btn"
                      onClick={(e) =>
                        this.saveAgreementDetails(e, "termsofengagement")
                      }
                    >
                      Update
                      {termsOfEngagementDataLoading && (
                        <i className="fa-spin fa fa-spinner ml-1" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div>
                  <div className="dark-blue-theme-color card-inner-padding">
                    <h5 className="mb-3">
                      FATCA/CRS & Identification detail settings
                    </h5>
                    <div className="form-group row mt-1 mb-1">
                      <div className="col-md-2">
                        <label className="pt-0">FATCA/CRS</label>
                      </div>
                      <div className="col-md-10 col-sm-10">
                        <RadioGroup
                          className="d-flex align-items-center"
                          name="fatcaCrsStatus"
                          radioWrapClassName="form-check form-check-inline"
                          radioWrapTag="div"
                          value={fatcaCrsStatus}
                          onChange={(e) =>
                            this.handleRadioChange(e, "fatcaCrsStatus")
                          }
                        >
                          <Radio
                            label={" " + "Enabled"}
                            radioClassName="iradio_square-blue"
                            value={"true"}
                          />
                          <Radio
                            label={" " + "Disabled"}
                            labelTag="div"
                            labelTagClassName="d-inline"
                            radioClassName="iradio_square-blue"
                            value={"false"}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <label className="pt-0">Identification Details</label>
                      </div>
                      <div className="col-md-10 col-sm-10">
                        <RadioGroup
                          className="d-flex align-items-center"
                          radioWrapClassName="form-check form-check-inline"
                          radioWrapTag="div"
                          name="identificationStatus"
                          value={identificationStatus}
                          onChange={(e) =>
                            this.handleRadioChange(e, "identificationStatus")
                          }
                        >
                          <Radio
                            label={" " + "Enabled"}
                            radioClassName="iradio_square-blue"
                            value={"true"}
                          />
                          <Radio
                            label={" " + "Disabled"}
                            labelTag="div"
                            labelTagClassName="d-inline"
                            radioClassName="iradio_square-blue"
                            value={"false"}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn default-border-btn"
                        onClick={(e) => this.saveFatcaIdentificationDetails()}
                      >
                        Update
                        {crsFatcaLoading && (
                          <i className="fa-spin fa fa-spinner ml-1" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordModal
          {...this.props}
          {...this.state}
          onInputValueChange={this.onchange}
          isFormValid={this.isFormValid}
          displayValidationErrors={this.displayValidationErrors}
          onCloseModal={this.onChangePasswordCloseModal}
          updatePassword={this.updatePassword}
        />
        <EditProfileModal
          optionRef={this.optionRef}
          {...this.props}
          {...this.state}
          onCloseModal={this.onEditProfileCloseModal}
          onInputValueChange={this.onchange}
          onSubmitData={this.onSubmitEditData}
          handleCountryChange={this.handleCountryChange}
          onMenuOpen={this.onMenuOpen}
        />
      </div>
    );
  }
}
export default MyAccount;
