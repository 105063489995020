import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import "react-confirm-alert/src/react-confirm-alert.css";
import AddClientModal from "./AddClientModal";
import { API_ROOT } from "../../services/api-config";

const STATUS = {
  INITIAL: "initial",
  NOT_LOGGED: "not-logged",
  ERROR: "error",
  SUCCESS: "success",
  LOADING: "loading",
  PROCESSING: "processing",
  MISSING_METAMASK: "missing-metamask",
  MISSING_CONTRACT_ADDRESS: "missing-contract-address",
  MISMATCH_FROM_ADDRESS: "mimatch-from-address",
};

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      filterByType: "",
      loading: false,
      status: STATUS.LOADING,
      modalOpen: false,
      clientFile: "",
      clientAddLoading: false,
    };
  }

  refreshCurrenttable = () => {
    this.getRecords();
  };

  getRecords = async () => {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail, filterByType } =
      this.state;
    this.setState({ loading: true });
    const authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/users/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
          filterByType: filterByType,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.users,
            totalSize: response.data.totalUsers,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  };

  updateStatus = (status) => {
    this.setState({
      status,
    });
  };

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  componentDidMount() {
    document.title =
      messageConstants.USERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords = () => {
    this.getRecords();
  };

  clearFilterRecords = () => {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByType: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  showClientModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleUploadFile = (event) => {
    if (event.target.files[0]) {
      this.setState({
        clientFile: event.target.files[0],
      });
    }
  };

  addClientRequest = () => {
    const { clientFile } = this.state;
    const authenticationToken = this.props.authToken;
    const _this = this;
    if (clientFile) {
      this.setState({ clientAddLoading: true });
      const data = new FormData();
      if (clientFile) {
        data.append("clientFile", clientFile);
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authenticationToken,
        },
      };
      axios
        .post(API_ROOT + "/user/dealer/client/add", data, config)
        .then(function (response) {
            if (response.data.code === 200) {
              _this.setState({
                clientAddLoading: false,
              });
              toast.success(response.data.message);
              _this.onCloseModal();
              _this.getRecords();
            } else {
              toast.error(response.data.message);
            }
        })
        .catch(function (error) {
          console.log("error", error);
          _this.setState({
            clientAddLoading: false,
          });
          toast.error( error.response.data && error.response.data.message);
        });
    } else {
      const errorMsg = !clientFile
        ? "Please upload csv file"
        : "Add client operation failed. Please try again later.";
      toast.error(errorMsg);
    }
  };

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      filterByType,
      loading,
      totalSize,
    } = this.state;
    const _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "originalEmail",
        text: "Email",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "role",
        text: "Type",
        formatter: function (cell, row) {
          let labelClassName =
            row.role === "i"
              ? "badge-primary"
              : row.role === "s"
              ? "badge-warning"
              : row.role === "m"
              ? "badge-info"
              : "badge-success";
          let label =
            row.role === "i"
              ? "Individual Investor"
              : row.role === "s"
              ? "Issuer"
              : row.role === "m"
              ? "Miner"
              : row.role === "ci"
              ? "Corporate Investor"
              : "";
          return (
            <div className="text-left">
              <div>
                <span className={"badge"}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "kycStatus",
        isDummyField: true,
        text: "KYC Status",
        sort: true,
        formatter: function (cell, row) {
          let statusLabelClass = row.kycStatusLabelClass || "warning";
          let statusLabel = row.kycStatusLabel || "N/A";
          return (
            <div>
              <div>
                <span className={"badge badge-" + statusLabelClass}>
                  {statusLabel}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        isDummyField: true,
        text: "operations",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div>
              {row.role === "s" && (
                <div className="btn-group mr-1 mb-1">
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="btn default-border-btn dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdownMenuButton6"
                    type="button"
                  >
                    Operation
                  </button>
                  <div
                    aria-labelledby="dropdownMenuButton6"
                    className="dropdown-menu"
                  >
                    <Link
                      to={`/diligence_details/${row._id}`}
                      className="dropdown-item"
                    >
                      Diligence Details
                    </Link>
                    <Link
                      to={`/campaigns/${row._id}`}
                      className="dropdown-item"
                    >
                      Campaigns
                    </Link>
                    <Link
                      to={`/token_settings/${row._id}`}
                      className="dropdown-item"
                    >
                      Token Settings
                    </Link>
                    {
                      <Link
                        to={{
                          pathname: `/user_detail/${row._id}`,
                          state: row.kycDetailId,
                        }}
                        className="dropdown-item"
                      >
                        Details
                      </Link>
                    }
                  </div>
                </div>
              )}

              {row.role !== "s" && (
                <Link
                  to={{
                    pathname: `/user_detail/${row._id}`,
                    state: row.kycDetailId,
                  }}
                >
                  <button className="btn default-border-btn">Details</button>
                </Link>
              )}
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          footerClasses="mt-3"
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          className="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper dealer-users-filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Clients</h5>
                <form className="form-inline">
                  <div className="row width-full-issue">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Name</label>
                        <input
                          className="form-control mb-1 mr-sm-2 mr-xs-1"
                          name="filterByName"
                          id="filterByName"
                          placeholder="Full Name"
                          type="text"
                          onChange={this.onchange}
                          value={filterByName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="sr-only"> Email</label>
                        <input
                          className="form-control mb-1 mr-sm-2 mr-xs-1"
                          placeholder="Email Address"
                          type="text"
                          name="filterByEmail"
                          id="filterByEmail"
                          onChange={this.onchange}
                          value={filterByEmail}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <div className="form-group select-icon">
                        <select
                          className="form-control  mb-1 mr-sm-2 mr-xs-1"
                          onChange={this.onchange}
                          value={filterByType}
                          name="filterByType"
                          id="filterByType"
                          placeholder="Filter by user role"
                          style={{ color: "#395cbe" }}
                        >
                          <option value="">All</option>
                          <option value="i">Individual Investor</option>
                          <option value="ci">Corporate Investor</option>
                          <option value="s">Issuer</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="userList-btn-group">
                    <button
                      className="btn default-btn"
                      type="button"
                      onClick={this.filterRecords}
                    >
                      Filter
                    </button>
                    <button
                      className="btn default-border-btn ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="element-wrapper mt-4">
              <div className="element-box">
                <h5 className="form-header">
                  <div className="pull-left">
                    <span>Manage Clients</span>
                  </div>
                  <div className="pull-right">
                    <button
                      className="btn default-btn"
                      type="button"
                      onClick={() => this.refreshCurrenttable()}
                    >
                      Refresh
                    </button>

                    <button
                      className="btn default-btn ml-2"
                      type="button"
                      onClick={() => this.showClientModal()}
                    >
                      Add Clients{" "}
                      <i className="fa fa-info-circle" id="addClients"></i>
                    </button>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="bottom"
                      target="addClients"
                    >
                      <PopoverBody>Add clients by uploading CSV.</PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </h5>
                <div className="clearfix mt-2"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddClientModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          handleEventsChange={this.handleEventsChange}
          handleUploadFile={this.handleUploadFile}
          addClientRequest={this.addClientRequest}
        />
      </div>
    );
  }
}
export default UsersList;
