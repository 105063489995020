const validator = {
  email: {
    rules: [
      {
        test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
        message: "Please enter valid email",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  password: {
    rules: [
      {
        test: (value) => {
          return value.length >= 6;
        },
        message: "Password must not be shorter than 6 characters",
      },
      {
        test: (value) => {
          return value.length <= 16;
        },
        message: "Password must not be longer than 16 characters",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  currentPassword: {
    rules: [
      {
        test: (value) => {
          return value.length >= 6;
        },
        message: "Password must not be shorter than 6 characters",
      },
      {
        test: (value) => {
          return value.length <= 16;
        },
        message: "Password must not be longer than 16 characters",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  confirmPassword: {
    rules: [
      {
        test: (value) => {
          return value.length >= 6;
        },
        message: "Password must not be shorter than 6 characters",
      },
      {
        test: (value) => {
          return value.length <= 16;
        },
        message: "Password must not be longer than 16 characters",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  fullName: {
    rules: [
      {
        test: /^[a-zA-Z ]+$/i,
        message: "Please enter valid full name.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  contactNo: {
    rules: [
      {
        test: /^[+0-9 ]+$/i,
        message: "Please enter valid contact number.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalUnlockedTokens: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: "Please enter valid number of tokens.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  xprPrice: {
    rules: [
      {
        test: /^[0-9.]+$/i,
        message: "Please enter valid NRT token price.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  PV10Valuation: {
    rules: [
      {
        test: /^[0-9.]+$/i,
        message: "Please enter valid PV10 valuation.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  transactionAmount: {
    rules: [
      {
        // test: /^[0-9.]+$/i,
        test: /^\s*(?=.*[1-9])\d*(?:\.\d{1,})?\s*$/i,
        message: "Please enter valid transaction Amount.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  transactionId: {
    rules: [
      {
        test: /^[a-zA-Z0-9.]+$/i,
        message: "Please enter valid transaction id.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalNoOfOilBarrles: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: "Please enter valid number of oil barrels.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalProvedBarrels: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: "Please enter valid number of total proved barrels.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalProvedTokens: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: "Please enter valid number of total proved tokens.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalprobablePossibleBarrels: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message:
          "Please enter valid number of total probable and possible barrels.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalProbablePossibleTokens: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message:
          "Please enter valid number of total probable & possible reserve tokens.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  totalXprFloatingToken: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: "Please enter valid number of total floating tokens.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  transactionHash: {
    rules: [
      {
        test: /^[0-9.,a-zA-Z]+$/i,
        message: "Please enter valid transaction hash.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  placeOfBirth: {
    rules: [
      {
        test: /^[a-zA-Z ]+$/i,
        message: "Please enter valid birth place name.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  permanentAddressBuildingName: {
    rules: [
      {
        test: /^[a-zA-Z ]+$/i,
        message: "Please enter valid birth place name.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  coyRegisteredName: {
    rules: [
      {
        test: /^[a-zA-Z ]+$/i,
        message: "Please enter valid registraion name.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  coyPlaceOfIncorporation: {
    rules: [
      {
        test: /^[a-zA-Z ]+$/i,
        message: "Please enter valid incorporation place.",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  issuedXPR: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: `Please enter valid number of issued.`,
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  restrictedXPR: {
    rules: [
      {
        test: /^[0-9.,]+$/i,
        message: `Please enter valid number of restricted.`,
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
};
export default validator;
