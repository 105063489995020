import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import Api from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import GetImage from "../GetImage/GetImage";
import browseFileImg from "../../assets/img/browse_file.png?v1";
class KycDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      email: "",
      contactNo: "",
      gender: "",
      dob: "",
      countryName: "",
      state: "",
      city: "",
      postalCode: "",
      userId: "",
      kycStatus: "",
      note: "",
      kycDocumentTypeListTypes: "",
      kycFiles: "",
      objectIdRef: "",
      grossAnnualIncome: "",
      investmentTimeFrame: "",
      representativeName: "",
      identificationDataFilled: "",
      fatcaCrsDataFilled: "",
      investmentKnowledge: "",
      investmentObjective: "",
    };
    this.imageExtension = ["png", "jpg", "jpeg", "gif", "bmp"];
  }

  async componentWillMount() {
    const api = new Api();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const authenticationToken = this.props.authToken;
    const detailId = this.props.match.params.id;
    if (!_.isUndefined(detailId)) {
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("user/kyc/detail", { detailId: detailId });
        if (
          response.code === 200 &&
          response.data &&
          response.data.kycDetail
        ) {
          this.setState(
            {
              userId: response.data.kycDetail.userId,
              fullName: response.data.kycDetail.fullName,
              email: response.data.kycDetail.email,
              contactNo: response.data.kycDetail.contactNo,
              gender: response.data.kycDetail.gender,
              dob: response.data.kycDetail.dob,
              countryName: response.data.kycDetail.countryId.name,
              state: response.data.kycDetail.state,
              city: response.data.kycDetail.city,
              postalCode: response.data.kycDetail.postalCode,
              kycStatus: response.data.kycDetail.status,
              kycDocumentTypeListTypes: response.data.kycDocumentTypeListTypes,
              kycFiles: response.data.kycFiles,
              objectIdRef: response.data.objectIdRef,
              grossAnnualIncome: response.data.kycDetail.grossAnnualIncome,
              note:response.data.kycDetail.note,
              investmentTimeFrame: response.data.kycDetail.investmentTimeFrame,
              insiderOrReportingIssuer:
                response.data.kycDetail.insiderOrReportingIssuer,
              representativeName: `${response.data.kycDetail.representativeFirstName} ${response.data.kycDetail.representativeLastName}`,
              identificationDataFilled:
                response.data.kycDetail.identificationDataFilled,
              fatcaCrsDataFilled: response.data.kycDetail.fatcaCrsDataFilled,
              investmentKnowledge: response.data.kycDetail.investmentKnowledge,
              investmentObjective: response.data.kycDetail.investmentObjective,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
  }

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onAddNote = () => {
    this.onChangetransactionOperation();
  };

  onChangetransactionOperation = async () => {
    const { note } = this.state;
    const api = new Api();
    const detailId = this.props.match.params.id;
    const authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("user/update/kyc/note", {
        note: note,
        detailId,
      });
    if (response.code === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  kycOperation = (userId, type) => {
    let label = type === "approve" ? "approve" : "reject";
    let _self = this;
    let buttons = "";
    if (type === "approve") {
      buttons = [
        {
          label: "Cancel",
        },
        {
          label: "Approve",
          onClick: () => _self.transactionOperation(userId, "approve"),
        },
      ];
    } else {
      buttons = [
        {
          label: "Cancel",
        },
        {
          label: "Reject",
          onClick: () => _self.transactionOperation(userId, "reject"),
        },
      ];
    }
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure want to " + label + " kyc?",
      buttons: buttons,
    });
  };

  transactionOperation = async (userId, type) => {
    const { note } = this.state;
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("user/update/kyc", {
        userId: userId,
        type: type,
        note: note,
      });

    if (response.code === 200) {
      toast.success(response.message);
      this.props.history.push("/kyc");
    } else {
      toast.error(response.message);
    }
  };

  render() {
    const {
      fullName,
      email,
      contactNo,
      gender,
      dob,
      countryName,
      state,
      city,
      postalCode,
      userId,
      kycStatus,
      note,
      kycDocumentTypeListTypes,
      kycFiles,
      objectIdRef,
      grossAnnualIncome,
      investmentTimeFrame,
      insiderOrReportingIssuer,
      representativeName,
      identificationDataFilled,
      fatcaCrsDataFilled,
      investmentKnowledge,
      investmentObjective,
    } = this.state;
    let labelClassName = "";
    let label = "";

    let identificationLabel;
    if (identificationDataFilled === true) {
      identificationLabel = "yes";
    }

    let crsLabel;
    if (fatcaCrsDataFilled === true) {
      crsLabel = "yes";
    }

    let kycStatuslabelClassName =
      kycStatus === "a"
        ? "badge-success"
        : kycStatus === "u"
        ? "Under badge-primary"
        : kycStatus === "r"
        ? "badge-danger"
        : "badge-warning";
    let kycLabel =
      kycStatus === "a"
        ? "Approved"
        : kycStatus === "u"
        ? "Under Review"
        : kycStatus === "r"
        ? "Rejected"
        : "Pending";

    let displayDocumentTypes = "";
    if (!_.isEmpty(kycDocumentTypeListTypes)) {
      displayDocumentTypes = kycDocumentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div className="element-box" key={mainIndex}>
            <h5
              className={
                parentDocumentType.children.length > 1
                  ? "text-center"
                  : "d-none hide"
              }
            >
              <span className="document-type-header">
                {parentDocumentType.label}
              </span>
            </h5>
            <div className="row">
              <div className="col-md-12">
                {parentDocumentType.children.map((documentType, index) => (
                  <div
                    className={
                      parentDocumentType.children.length > 1 ? "mt-4" : ""
                    }
                    key={index}
                  >
                    <h6 className="element-header">
                      <span className="text-xl">{`${documentType.label} ${
                        _.isEmpty(objectIdRef[documentType._id]) ? ": N/A" : ""
                      }`}</span>
                    </h6>
                    <div className="row">
                      {!_.isEmpty(objectIdRef[documentType._id]) &&
                        objectIdRef[documentType._id].map(
                          (image, imageIndex) => (
                            <div
                              className="col-md-6 mt-4 mobile-center-img"
                              key={imageIndex}
                            >
                              <GetImage
                                string={kycFiles[image]}
                                docLabel={documentType.label}
                                browseFileImg={browseFileImg}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span className="text-xl">Details</span>
                      <Link
                        to="/kyc"
                        className="pull-right"
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#395cbe",
                        }}
                      >
                        Back to KYC list
                      </Link>
                    </div>
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Full Name : </span> {fullName || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Email : </span> {email || "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Contact No : </span> {contactNo || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>City : </span> {city || "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      {
                        <div className="text-default text-default-color">
                          <span>State/Province : </span>
                          {state || "N/A"}
                        </div>
                      }
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Country : </span> {countryName || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Postal Code : </span> {postalCode || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Status:</span>{" "}
                        <span className={"badge " + kycStatuslabelClassName}>
                          {kycLabel}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Gross Annual Income : </span>{" "}
                        {grossAnnualIncome || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Investment Time Frame :</span>{" "}
                        <span>{investmentTimeFrame || "N/A"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Insider Status : </span>{" "}
                        {insiderOrReportingIssuer || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Representative Name :</span>{" "}
                        <span>
                          {!!representativeName &&
                          representativeName !== "undefined undefined"
                            ? representativeName
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Investment Knowledge : </span>
                        {investmentKnowledge || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Investment Objective : </span>{" "}
                        <span>{investmentObjective || "N/A"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Identification details : </span>
                        {identificationDataFilled || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Fatca Details : </span>
                        <span>{fatcaCrsDataFilled ? crsLabel : "N/A"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {displayDocumentTypes}
              {(kycStatus === "a" || kycStatus === "r" && !!note) && (
                <div className="element-box">
                  <h6>Note</h6>
                  <span className="note-text">{note}</span>
                </div>
              )}
              {(kycStatus !== "a" && kycStatus !== "r") && (
                <div>
                  <div className="element-box mt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="element-header">Note : </h6>
                        <textarea
                          rows={3}
                          className="form-control"
                          name="note"
                          id="note"
                          onChange={this.onchange}
                          value={note}
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn default-border-btn"
                        type="button"
                        onClick={this.onAddNote}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="card-vertical-padding">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn default-border-btn"
                        type="button"
                        onClick={() => this.kycOperation(userId, "reject")}
                      >
                        {" "}
                        Reject
                      </button>
                      <button
                        className="btn default-btn ml-2"
                        type="button"
                        onClick={() => this.kycOperation(userId, "approve")}
                      >
                        {" "}
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default KycDetail;
