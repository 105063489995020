import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { RECAPTCHASITEKEY } from "../../services/api-config";
import logoImg from "../../assets/img/logo-white.png?v4";
import "icheck/skins/all.css";
import { Checkbox } from "react-icheck";
import validators from "../../validators";
import ReCAPTCHA from "react-google-recaptcha";
import Api from "../../services/api";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      type: "d",
      agreeCheck: false,
      error: null,
      buttonLoading: false,
      apiError: "",
      recaptchaResponse: "",
      isTourOpen: true,
      isShowingMore: false,
    };

    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.signup = this.signup.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(
      this
    );
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
  }

  componentDidMount() {
    document.title =
      messageConstants.SIGNUP_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  handleTypeChange(event, value) {
    this.setState({
      type: value,
    });
  }

  handleAgreeChange(e, checked) {
    this.setState({
      agreeCheck: checked,
    });
  }

  updateValidators(fieldName, value) {
    const { password, confirmPassword } = this.state;
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
    if (
      (fieldName === "confirmPassword" || fieldName === "password") &&
      password !== confirmPassword
    ) {
      this.validators["confirmPassword"].errors = [];
      this.validators["confirmPassword"].errors.push(
        "Passwords entered do not match."
      );
      this.validators["confirmPassword"].valid = false;
    }
  }

  isFormValid() {
    let status = true;
    const validationFields = [
      "fullName",
      "email",
      "password",
      "confirmPassword",
    ];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }
  async signup(event) {
    event.preventDefault();
    const {
      fullName,
      email,
      password,
      confirmPassword,
      type,
      agreeCheck,
      recaptchaResponse,
      dealingRepresentiveName,
      companyName,
    } = this.state;
    const isFormValid = this.isFormValid();
    if (
      !_.isUndefined(agreeCheck) &&
      agreeCheck === true &&
      isFormValid &&
      password.length >= 6 &&
      confirmPassword.length >= 6
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api.create("user/signup", {
        fullName: fullName,
        dealingRepresentiveName,
        companyName,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        recaptchaResponse: recaptchaResponse,
        type: type,
      });

      if (response) {
        this.setState({
          buttonLoading: false,
        });
        if (response.code === 200) {
          this.props.history.push("/signin");
          toast.success(response.message);
        } else {
          toast.error(response.message);
          this.recaptcha.reset();
        }
      }
    } else {
      if (!isFormValid) {
        toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      } else if (password.length < 6 || confirmPassword.length < 6) {
        let pwdMsg =
          password.length < 6
            ? "Please enter valid password."
            : "Please enter valid password.";
        toast.error(pwdMsg);
      } else {
        toast.error("Please accept terms & conditions to proceed.");
      }
      this.recaptcha.reset();
    }
  }

  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  render() {
    const {
      fullName,
      email,
      password,
      confirmPassword,
      buttonLoading,
      agreeCheck,
      // dealingRepresentiveName,
      companyName,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    const isFormValid = this.isFormValid();
    return (
      <div className="register-container ">
        <div className="auth-box-w wider signupwidth">
          <div className="logo-w" data-tut="reactour__logo">
            <Link to="/">
              <img alt="Logo" src={logoImg} width="230" />
            </Link>
          </div>
          <form className="custom-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-user" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Full Name (Dealing Representative)*"
                    type="text"
                    name="fullName"
                    onChange={this.onchange}
                    value={fullName}
                    id="fullName"
                    autoComplete="nope"
                  />
                </div>
                {fullName && this.displayValidationErrors("fullName")}
              </div>
            </div>
            <div className="mt-3"></div>
            <div className="row">
              <div className="col-sm-6 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-user" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Company Name*"
                    type="text"
                    name="companyName"
                    onChange={this.onchange}
                    value={companyName}
                    id="companyName"
                    autoComplete="nope"
                  />
                </div>
              </div>
              <div className="col-sm-6 emailContainer">
                <div className="input-group ">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-envelope" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Email ID*"
                    type="text"
                    name="email"
                    onChange={this.onchange}
                    value={email}
                    id="email"
                    autoComplete="nope"
                  />
                </div>
                {email && this.displayValidationErrors("email")}
              </div>
            </div>
            <div className="mt-3"></div>
            <div className="row mb-3">
              <div className="col-sm-6 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-key" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Password*"
                    type="password"
                    name="password"
                    onChange={this.onchange}
                    value={this.state.password}
                    id="password"
                    autoComplete="nope"
                  />
                </div>
                {password && this.displayValidationErrors("password")}
              </div>
              <div className="col-sm-6 confirmPasswordContainer">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-key" />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    placeholder="Retype Password*"
                    type="password"
                    name="confirmPassword"
                    onChange={this.onchange}
                    value={this.state.confirmPassword}
                    id="confirmPassword"
                    autoComplete="nope"
                  />
                </div>
                {confirmPassword &&
                  this.displayValidationErrors("confirmPassword")}
              </div>
            </div>
            <div className="form-check terms-block mb-3">
              <Checkbox
                id="agreeCheck"
                checkboxClass="icheckbox_square-blue"
                increaseArea="20%"
                name="agreeCheck"
                onChange={this.handleAgreeChange}
                value="y"
              />
              <label htmlFor="agreeCheck" className="ml-2">
                I agree to{" "}
                <Link
                  to="/terms_conditions"
                  className="color-white text-underline"
                  target="_blank"
                >
                  Terms & Conditions
                </Link>
              </label>
              {agreeCheck && this.displayValidationErrors("agreeCheck")}
            </div>
            <div className="form-check terms-block">
              <ReCAPTCHA
                ref={(el) => {
                  this.recaptcha = el;
                }}
                sitekey={RECAPTCHASITEKEY}
                onChange={this.handleCaptchaResponseChange}
              />
            </div>
            <div>
              <div className="buttons-w pull-left width-35 width-100">
                <button
                  className={`btn btn-primary custom-submit-button ${isFormValid ? "" : "disabled"
                    }`}
                  onClick={this.signup}
                  disabled={buttonDisabled}
                >
                  Sign Up
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
              <div className="pull-right signuppage-info-bottom-link signuppt">
                Already have an account?{" "}
                <Link to="/signin" className="color-white">
                  <span className="text-underline">Sign In</span>
                </Link>
              </div>
            </div>
            <div className="clearfix"></div>
          </form>
        </div>
      </div>
    );
  }
}
export default Signup;
