import React from "react";

class GetImage extends React.Component {
  constructor(props) {
    super(props);
  }

  getImage = (string, docLabel, browseFileImg) => {
    {
      const url = string.split("?"); // splitting the url to get string in which we have image extension
      const extensionArr = url[0].split("."); // here url[0] contains extension so splitting the string to get extension
      const extension = extensionArr[extensionArr.length - 1]; // extensionArr[extensionArr.length - 1] is extension
      if (["png", "jpg", "jpeg", "bmp"].includes(extension)) {
        return (
          <a rel="noopener noreferrer" href={string} target="_blank">
            <img
              className="kyc-document-img"
              src={string}
              height={350}
              alt={docLabel}
            />
          </a>
        );
      } else {
        return (
          <a rel="noopener noreferrer" href={string} target="_blank">
            <img
              className="kyc-document-img"
              src={browseFileImg}
              height={200}
              alt={docLabel}
            />
          </a>
        );
      }
    }
  };

  render() {
    const { string, docLabel, browseFileImg } = this.props;

    return <>{this.getImage(string, docLabel, browseFileImg)}</>;
  }
}
export default GetImage;

