import React from "react";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";

class Basepages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { currentLocation } = this.props;
    return (
      <div
        className={`basepage-main-container ${currentLocation}_wrapper_container`}
      >
        <div className="bg-full-image">
          <div className="bg-overlay"></div>
        </div>
        <div className={(currentLocation === 'terms_conditions') ? '' : 'centerofthescreen min-height-90vh'}>
          {this.props.children}
        </div>
        <div className="footer">
          <Footer color="white" />
        </div>
        <ToastContainer hideProgressBar={true} />
      </div>
    );
  }
  /* render() {
        return (
            <div className="wrapper">
                <ToastContainer />
                <div className="bg-full-image">
                    <div className="bg-overlay"></div>
                    <div className="all-wrapper menu-side with-pattern">
                        <div className="main-content-container">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="footer-container basepage-footer-container">
                    <Footer />
                </div>
            </div>
        );
    } */
}
export default Basepages;
