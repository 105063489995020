import React, { Component } from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import { toast } from "react-toastify";
import ReactCrop from "react-image-crop";
import _ from "lodash";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";

class EditProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      phoneNumber: "",
      shortSummary: "",
      websiteLink: "",
      companyName: "",
      position: "",
      linkedinLink: "",
      editProfileButtonLoading: false,
      dealingRepresentiveName: "",
      srcAvatar: "",
      src: null,
      crop: {
        unit: "%",
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    };
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.onchange = this.onchange.bind(this);
    this.onNumberChange = this.onNumberChange.bind(this);
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onNumberChange(value) {
    this.setState({ phoneNumber: value });
  }

  handleUploadFile(event) {
    if (
      (event.target.files[0].type == "image/png" ||
        event.target.files[0].type == "image/jpg" ||
        event.target.files[0].type == "image/jpeg") &&
      event.target.files[0].size < 500000
    ) {
      this.setState({
        avatar: event.target.files[0],
      });
    } else {
      toast.error("Please make sure image size is under 500kb");
      // e.target.files[0] =null;
    }
  }

  async componentDidMount() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const profileDetails = await api
      .setToken(authenticationToken)
      .create("user/getUserDetails");
    if (profileDetails.code === 200) {
      this.setState({
        fullName: profileDetails.data.fullName,
        phoneNumber: profileDetails.data.phoneNumber,
        shortSummary: profileDetails.data.shortSummary,
        websiteLink: profileDetails.data.websiteLink,
        companyName: profileDetails.data.companyName,
        position: profileDetails.data.position,
        linkedinLink: profileDetails.data.linkedinLink,
        srcAvatar: profileDetails.data.avatar,
      });
    }
  }

  async updateProfile() {
    const {
      avatar,
      emdAvatar,
      fullName,
      phoneNumber,
      shortSummary,
      websiteLink,
      companyName,
      position,
      linkedinLink,
      srcAvatar,
    } = this.state;
    const { onCloseModal, onSubmitData, countryName } = this.props;
    let authenticationToken = this.props.authToken;
    if (fullName && phoneNumber && shortSummary && avatar && emdAvatar && countryName) {
      this.setState({ editProfileButtonLoading: true });
      let _this = this;
      const data = new FormData();

      if (fullName) {
        data.append("fullName", fullName);
      }
      if (phoneNumber) {
        data.append("phoneNumber", phoneNumber);
      }
      if (shortSummary) {
        data.append("shortSummary", shortSummary);
      }
      if (websiteLink) {
        data.append("websiteLink", websiteLink);
      }
      if (companyName) {
        data.append("companyName", companyName);
      }
      if (position) {
        data.append("position", position);
      }
      if (linkedinLink) {
        data.append("linkedinLink", linkedinLink);
      }
      if (avatar) {
        data.append("avatar", avatar);
      }
      if (emdAvatar) {
        data.append("emdAvatar", emdAvatar);
      }
      data.append('countryName', countryName);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: this.props.authToken,
        },
      };
      await axios
        .post(API_ROOT + "/user/dealer/profile/edit", data, config)
        .then(function (response) {
          if (!_.isUndefined(response)) {
            _this.setState({
              editProfileButtonLoading: false,
            });
            if (response.data.code === 200) {
              onSubmitData();

              toast.success(response.data.message);

            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch(function (error) {
          _this.setState({
            editProfileButtonLoading: false,
          });
          toast.error(
            "Profile details not saved successfully. Please try again later."
          );
        });
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const profileDetails = await api
        .setToken(authenticationToken)
        .create("user/getUserDetails");
      if (profileDetails.code === 200) {
        this.setState({
          srcAvatar: profileDetails.data.avatar,
        });
      }
      onCloseModal();
    } else {
      const errorMsg =
        !avatar && !shortSummary && !emdAvatar
          ? "Please enter valid details for all required fields."
          : !avatar
            ? "Please upload Avatar"
            : !emdAvatar
              ? "Please upload Company Logo"
              : "";
      toast.error(errorMsg);
    }
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ emdAvatar: croppedImageUrl });
    }
  }

  handleFile = (e) => {
    if (
      (e.target.files[0].type == "image/png" ||
        e.target.files[0].type == "image/jpg" ||
        e.target.files[0].type == "image/jpeg") &&
      e.target.files[0].size < 500000
    ) {
      this.setState({ srcAvatar: "" });
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        this.setState({ src: fileReader.result });
      };
      fileReader.readAsDataURL(e.target.files[0]);
    } else {
      toast.error("Please make sure image size is under 500kb");
      // e.target.files[0] =null;
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ emdAvatar: croppedImage });
  }

  render() {
    const {
      editProfileButtonLoading,
      fullName,
      phoneNumber,
      shortSummary,
      websiteLink,
      linkedinLink,
      companyName,
      position,
      dealingRepresentiveName,
      crop,
      croppedImageUrl,
      src,

    } = this.state;
    const {
      editProfileModalOpen,
      onCloseModal,
      countryName,
      countryIdValue,
      handleCountryChange,
      countriesList,
      optionRef,
      onMenuOpen
    } = this.props;
    let buttonDisabled = editProfileButtonLoading === true ? true : false;
    return (
      <Modal
        open={editProfileModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container dealer-profile-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">Edit Profile</h4>
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="fullName"
                              className="text-default text-default-color"
                            >
                              Full Name (Dealing Representative Name)
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter name"
                              type="text"
                              name="fullName"
                              id="fullName"
                              value={fullName}
                              onChange={this.onchange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="phoneNumber"
                              className="text-default text-default-color"
                            >
                              Contact Number
                              <span className="required-field">*</span>
                            </label>
                            <IntlTelInput
                              preferredCountries={["tw"]}
                              onPhoneNumberChange={(
                                validate,
                                value,
                                countryData
                              ) => this.onNumberChange(value)}
                              numberType="MOBILE"
                              containerClassName="intl-tel-input"
                              inputClassName="form-control"
                              value={phoneNumber}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default-color">
                              Country <span className="required-sign"> * </span>
                            </label>
                            <Select
                              autoFocus
                              className="country-select2--profile"
                              classNamePrefix="cntry"
                              options={countriesList}
                              placeholder="Select Country"
                              name="country"
                              ref={optionRef}
                              onChange={handleCountryChange}
                              value={{
                                label: countryName,
                                value: countryIdValue ? countryIdValue : countriesList.find((country) => country.label === countryName),
                              }}
                              onMenuOpen={onMenuOpen}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              htmlFor="shortSummary"
                              className="text-default text-default-color"
                            >
                              Short Summary
                              <span className="required-field">*</span>
                            </label>
                            <textarea
                              rows="3"
                              name="shortSummary"
                              id="shortSummary"
                              className="form-control"
                              placeholder="Enter short summary"
                              onChange={this.onchange}
                            >
                              {shortSummary}
                            </textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="websiteLink"
                              className="text-default text-default-color"
                            >
                              Website Link
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter website"
                              type="text"
                              name="websiteLink"
                              id="websiteLink"
                              value={websiteLink}
                              onChange={this.onchange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="linkedinLink"
                              className="text-default text-default-color"
                            >
                              LinkedIn Link
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter linkedin link"
                              type="text"
                              name="linkedinLink"
                              id="linkedinLink"
                              value={linkedinLink}
                              onChange={this.onchange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="companyName"
                              className="text-default text-default-color"
                            >
                              Company Name
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter company name"
                              type="text"
                              name="companyName"
                              id="companyName"
                              value={companyName}
                              onChange={this.onchange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="position"
                              className="text-default text-default-color"
                            >
                              Position
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter position"
                              type="text"
                              name="position"
                              id="position"
                              value={position}
                              onChange={this.onchange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Company Logo
                              <span className="required-field">*</span> (Best
                              resolution 210*70)
                            </label>
                            <br />
                            <input
                              type="file"
                              name="avatar"
                              data-max-size="500000"
                              onChange={this.handleFile}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Dealing Representative Avatar
                              <span className="required-field">*</span>
                            </label>
                            <br />
                            <input
                              type="file"
                              name="emdAvatar"
                              onChange={(event) => this.handleUploadFile(event)}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.srcAvatar ? (
                        this.state.srcAvatar !== "" && this.state.srcAvatar !== undefined && this.state.srcAvatar !== null && (<div>
                          <img className="ReactCrop__image" src={this.state.srcAvatar} />
                        </div>)
                      ) : (
                        src &&
                        (<ReactCrop
                          src={src}
                          crop={crop}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                        />)
                      )}
                      <div className="btn-center-group mt-3 d-flex justify-content-end">
                        <button
                          className="btn default-border-btn"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn default-btn ml-2"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={this.updateProfile}
                        >
                          {" "}
                          Update{" "}
                          {editProfileButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white ml-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EditProfileModal;
