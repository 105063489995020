import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import _ from "lodash";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

class CampaignDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingDetails: {},
      kycDocumentTypeListTypes: "",
      listingFiles: "",
      objectIdRef: "",
    };
    this.listingOperation = this.listingOperation.bind(this);
    this.listingStatusChange = this.listingStatusChange.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  async componentDidMount() {
    const api = new Api();
    document.title =
      messageConstants.LISTING_DETAIL_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    let authenticationToken = this.props.authToken;
    let listingId = this.props.match.params.id;

    if (!_.isUndefined(listingId)) {
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("user/listing/details", { listingId: listingId });
        if (response.code === 200) {
          this.setState(
            {
              listingDetails: response.data.listingDetail,
              documentTypeListTypes: response.data.documentTypeListTypes,
              listingFiles: response.data.listingFiles,
              objectIdRef: response.data.objectIdRef,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
    this.props.pageProgress("remove");
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  listingOperation(listingId, type) {
    let label = type === "approve" ? "accept" : "reject";
    let _self = this;
    let buttons = "";
    if (type === "approve") {
      buttons = [
        {
          label: "Accept",
          onClick: () => _self.listingStatusChange(listingId, "approve"),
        },
        {
          label: "Cancel",
        },
      ];
    } else {
      buttons = [
        {
          label: "Reject",
          onClick: () => _self.listingStatusChange(listingId, "reject"),
        },
        {
          label: "Cancel",
        },
      ];
    }
    confirmAlert({
      title: "Are you sure want to " + label + " campaign?",
      buttons: buttons,
    });
  }

  async listingStatusChange(listingId, type) {
    let { statusNote } = this.state;
    if (listingId !== "" && type && type !== "") {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .create("user/dealer/listing/update/status", {
          listingId: listingId,
          type: type,
          statusNote: statusNote,
        });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          toast.success(response.message);
          this.props.history.push("/users");
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error("Invalid request.");
    }
  }

  render() {
    const {
      listingDetails,
      documentTypeListTypes,
      listingFiles,
      objectIdRef,
    } = this.state;
    let displayDocumentTypes = "";
    var date =
      listingDetails && listingDetails.createdOnUTC
        ? moment(listingDetails.createdOnUTC).format("DD/MM/YYYY")
        : "";
    if (
      !_.isUndefined(documentTypeListTypes) &&
      !_.isEmpty(documentTypeListTypes)
    ) {
      displayDocumentTypes = documentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div className="col-md-6">
            {objectIdRef[parentDocumentType._id] && (
              <div className="mt-2">
                <span className="text-default-color text-default">
                  {parentDocumentType.label} :
                </span>{" "}
                <a
                  href={
                    listingFiles[
                    objectIdRef[parentDocumentType.children[0]._id][0]
                    ]
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </div>
            )}
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            {/* <h2 className="text-center mb-4">
              Campaign{" "}
              {listingDetails && listingDetails.listingId
                ? "#" + listingDetails.listingId
                : ""}{" "}
              Details
            </h2> */}
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <span className="pull-left text-xl">General Details</span>
                    <Link to="/users" className="pull-right" style={{ marginTop: '4px', fontSize: '14px', color: '#395cbe' }}>
                      Back to users list
                    </Link>
                  </h6>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                        User Name  :
                        </span>{" "}
                        {listingDetails?listingDetails.userId?listingDetails.userId.fullName:"":""}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                        User Email :
                        </span>{" "}
                        {listingDetails?listingDetails.userId?listingDetails.userId.email:"":""}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                        Daily Production (MCF) :
                        </span>{" "}
                        {listingDetails.dailyProductionMcf}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                        Estimated Power Capacity (kWh) :
                        </span>{" "}
                        {listingDetails.estimatedPoweCapacity}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                          Average Daily Production In BBL :
                        </span>{" "}
                        {listingDetails.averageDailyProductionInBBL}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          Average Daily Production In BOE :
                        </span>{" "}
                        {listingDetails.averageDailyProductionInBOE}
                      </div>
                    </div>
                  </div> */}

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                          Oil total Proved Reserves :
                        </span>{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          Oil total Probable Reserves :
                        </span>{" "}
                        {listingDetails.oilTotalProbableReserves}{" "}
                        {listingDetails.oilTotalProbableReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                          Oil total Possible Reserves :
                        </span>{" "}
                        {listingDetails.oilTotalPossibleReserves}{" "}
                        {listingDetails.oilTotalPossibleReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          Gas total Proved Reserves :
                        </span>{" "}
                        {listingDetails.gasTotalProvedReserves}{" "}
                        {listingDetails.gasTotalProvedReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                          Gas total Probable Reserves :
                        </span>{" "}
                        {listingDetails.gasTotalProbableReserves}{" "}
                        {listingDetails.gasTotalProbableReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          Gas total Possible Reserves :
                        </span>{" "}
                        {listingDetails.gasTotalPossibleReserves}{" "}
                        {listingDetails.gasTotalPossibleReservesUnit}
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                          NGL total Proved Reserves :
                        </span>{" "}
                        {listingDetails.nglTotalProvedReserves}{" "}
                        {listingDetails.nglGasTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          NGL total Probable Reserves :
                        </span>{" "}
                        {listingDetails.nglGasTotalProbableReserves}{" "}
                        {listingDetails.nglGasTotalProbableReservesUnit}
                      </div>
                    </div>
                  </div> */}

                  
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>Location :</span>{" "}
                        {listingDetails &&
                          listingDetails.location &&
                          listingDetails.location !== ""
                          ? listingDetails.location
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          Latitude / Longitude :
                        </span>{" "}
                        {listingDetails.latitude ? listingDetails.latitude : ""}{" "}
                        {listingDetails.longitude
                          ? `/ ${listingDetails.longitude}`
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default-color text-default">
                        <span>
                          {" "}
                          Created Date :{" "}

                        </span>{" "}
                        {date}

                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default-color text-default">
                        <span>
                          {/* Created Date :{" "} */}
                        </span>
                        {/* {date} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="element-box">
                <h6 className="element-header">
                  <span className="text-xl">Campaign Documents</span>
                </h6>
                <div className="row">
                  {displayDocumentTypes}
                  {!documentTypeListTypes && (
                    <div className="col-md-12">
                      No campaign documents has been uploaded yet.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CampaignDetails;
