/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import _ from "lodash";
import * as messageConstants from "../../utils/Messages";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { toast } from "react-toastify";
import Api from "../../services/api";
import auth from "../../utils/auth";
import { niceNumberDecimalDisplay } from "../../utils/Util";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      sizePerPage: 10,
      renderFlag: false,
      totalClients: 0,
      totalInvestors: 0,
      totalIssuers: 0,
      kycPending: 0,
      kycApproved: 0,
      kycRejected: 0,
      totalWhitelistingRequests: 0,
      totalWhitelisted: 0,
    };
  }

  async componentDidMount() {
    this.getTokenDetails();
    this.getRecords();
    document.title =
      messageConstants.DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    let authUserInfo = this.props.authUserInfo;
    let kycVerifiedResponse = await this.props.checkKycVerified();
    if (kycVerifiedResponse.status === true) {
      const api = new Api();
      if (
        !_.isUndefined(authUserInfo) &&
        authUserInfo !== null &&
        authUserInfo !== "" &&
        authUserInfo.isKycVerified === true &&
        !_.isUndefined(authUserInfo.appovedScreenViwed) &&
        authUserInfo.appovedScreenViwed === false
      ) {
        const response = await api.create("appvoedKycScreenViwed", {
          authenticationToken: authenticationToken,
        });
        if (response.status === "success") {
          let userInfo = {};
          let authUserInfo = this.props.authUserInfo;
          userInfo.fullName = authUserInfo.fullName;
          userInfo.userDisplayName = authUserInfo.userDisplayName;
          userInfo.email = authUserInfo.email;
          userInfo.isKycVerified = true;
          userInfo.appovedScreenViwed = true;
          auth.setUserInfo(userInfo);
        }
      }
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
  }

  async getTokenDetails() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("user/dealer/statistics");
    if (response) {
      const responseData = response.data;
      if (!responseData) {
        return "";
      }
      // floatingTokenValue = niceNumberDecimalDisplay(floatingTokenValue, 2);
      let stateObj = {
        totalClients: responseData.totalClients || 0,
        totalInvestors: responseData.totalInvestors || 0,
        totalIssuers: responseData.totalIssuers || 0,
        kycPending: responseData.kycPending || 0,
        kycApproved: responseData.kycApproved || 0,
        kycRejected: responseData.kycRejected || 0,
        totalWhitelistingRequests: responseData.totalWhitelistingRequests || 0,
        totalWhitelisted: responseData.totalWhitelisted || 0,
      };
      if (stateObj) {
        this.setState(stateObj, () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        });
      }
    }
  }

  async getTokenBalanceDetails() {
    const { tokenSymbol, tokenPrice } = this.state;
    if (tokenSymbol) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/balance", { tokenSymbol });
      if (response) {
        const responseData = response.data;
        let tokenUsdValue = responseData.tokenBalance
          ? parseFloat(responseData.tokenBalance) * parseFloat(tokenPrice)
          : 0;
        if (tokenUsdValue) {
          tokenUsdValue = niceNumberDecimalDisplay(tokenUsdValue, 2);
        }
        let stateObj = {
          tokenBalance: responseData.tokenBalance
            ? niceNumberDecimalDisplay(responseData.tokenBalance, 2)
            : 0,
          myTransactions: responseData.transactions,
          tokenUsdValue,
          totalTransaction: _.size(responseData.transactions),
        };
        if (stateObj) {
          this.setState(stateObj);
        }
      }
    }
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true });

    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/recent/tokens", {
          sizePerPage: sizePerPage,
          page: page,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.tokens,
            totalSize: response.data.totalTokens,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      } else {
        this.setState({
          loading: false,
        });
        toast.message(response.message);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByContactno: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      loading,
      totalClients,
      totalInvestors,
      totalIssuers,
      kycPending,
      kycApproved,
      kycRejected,
      totalWhitelistingRequests,
      totalWhitelisted,
    } = this.state;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "listedTokenName",
        text: "Token Name",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "listedTokenSymbol",
        text: "Ticker",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "listedTokenPrice",
        text: "Price",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>${cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Issuer Name",
      },
      {
        headerClasses: "text-bold",
        dataField: "email",
        text: "Email",
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-md-12">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    Clients
                  </h4>
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div>
                        <span className="text-default text-default-color widget-title">
                          Total Clients
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {totalClients}
                        </span>
                      </div>
                      <div className="ml-4">
                        <span className="text-default text-default-color widget-title">
                          Total Investors
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {totalInvestors}
                        </span>
                      </div>
                      <div className="ml-4">
                        <span className="text-default text-default-color widget-title">
                          Total Issuers
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {totalIssuers}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">KYC</h4>
                  <div className="row">
                    <div className="col-md-9 d-flex">
                      <div>
                        <span className="text-default widget-title">
                          KYC Pending
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {kycPending}
                        </span>
                      </div>
                      <div className="ml-4">
                        <span className="text-default widget-title">
                          KYC Approved
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {kycApproved}
                        </span>
                      </div>
                      <div className="ml-4">
                        <span className="text-default widget-title">
                          KYC Rejected
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {kycRejected}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mgtp-sm-4">
                <div className="dark-blue-theme-color card-inner-padding">
                  <h4 className="card-title text-xl text-default-color">
                    Whitelisting
                  </h4>
                  <div className="row">
                    <div className="col-md-9 d-flex">
                      <div>
                        <span className="text-default widget-title">
                          Whitelisting Requests
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {totalWhitelistingRequests}
                        </span>
                      </div>
                      <div className="ml-4">
                        <span className="text-default widget-title">
                          Whitelisting Approved
                        </span>
                        <br />
                        <span className="text-large font-700 dashboard-card-badge">
                          {totalWhitelisted}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="element-wrapper mt-4">
              <div className="element-box">
                <h4 className="card-title text-xl text-default-color">
                  <span className="pull-left">Recent Tokens</span>
                  <span className="pull-right">
                    <button className="mr-2 mb-2 btn default-btn" type="button">
                      Refresh
                    </button>
                  </span>
                </h4>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;
