import React, { Component } from "react";
import Modal from "react-responsive-modal";

class AddTransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      transactionModalOpen,
      onCloseModal,
      onInputValueChange,
      AddTransactionRequest,
      buttonLoading,
      transactionAmount,
      /* transactionNote, */
      fullName,
      myTokensList,
      tokens,
    } = this.props;
    let buttonDisabled = buttonLoading === true ? true : false;

    let tokenListHtml = "";
    if (myTokensList) {
      tokenListHtml = myTokensList.map((tokenDetails, mainIndex) => (
        <option value={tokenDetails._id} key={mainIndex}>
          {tokenDetails.listedTokenSymbol}
        </option>
      ));
    }

    return (
      <Modal
        open={transactionModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container transaction-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">
                      {" "}
                      Add tokens to {fullName} account{" "}
                    </h4>
                    <form>
                      <div className="form-group">
                        <label htmlFor="transactionAmount">
                          Token
                          <span className="required-field">*</span>
                        </label>
                        <select
                          name="tokenId"
                          className="form-control"
                          onChange={onInputValueChange}
                        >
                          <option>Please select token</option>
                          {tokenListHtml}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="transactionAmount">
                          Transaction Amount (in USD)
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="transactionAmount"
                          placeholder="Enter transaction amount"
                          type="text"
                          value={transactionAmount}
                          name="transactionAmount"
                          onChange={onInputValueChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="tokens">Tokens</label>
                        <input
                          className="form-control"
                          id="tokens"
                          placeholder="Enter tokens"
                          type="text"
                          value={tokens}
                          name="tokens"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="btn default-border-btn"
                          type="button"
                          onClick={onCloseModal}>
                          Cancel
                        </button>
                        <button
                          className="btn default-btn ml-2"
                          type="button"
                          onClick={AddTransactionRequest}
                          disabled={buttonDisabled}
                        >
                          {" "}
                          Submit{" "}
                          {buttonLoading && (
                            <i className="fa-spin fa fa-spinner text-white ml-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddTransactionModal;
