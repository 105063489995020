import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import _ from "lodash";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import EditEventModal from "./EditEventModal";

class AdminManageEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByTitle: "",
      loading: false,
      modalOpen: false,
      editModalOpen: false,
      eventDetails: [],
      minimumVerifiers: 0,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    // this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.updateVerificationEvent = this.updateVerificationEvent.bind(this);
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByTitle } = this.state;
    let authenticationToken = this.props.authToken;
    this.setState({ loading: true });
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("admin/verification/events/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByTitle: filterByTitle,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.verificationRequests,
            totalSize: response.data.totalVerificationEventCounter,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      } else {
        this.setState({
          loading: false,
        });
        toast.message(response.message);
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByTitle: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }

  componentWillMount() {
    document.title =
      messageConstants.MANAGE_VERIFICATION_EVENTS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  showEventModal() {
    this.setState({ modalOpen: true });
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  openEditModal(row) {
    this.setState({
      eventDetails: row,
      minimumVerifiers:
        row && row.minimumVerifiers > 0 ? row.minimumVerifiers : 0,
      editModalOpen: true,
    });
  }

  closeEditModal() {
    this.setState({
      eventDetails: [],
      editModalOpen: false,
    });
  }

  async updateVerificationEvent() {
    let authenticationToken = this.props.authToken;
    const { minimumVerifiers, eventDetails } = this.state;
    if (minimumVerifiers && minimumVerifiers > 0 && !_.isEmpty(eventDetails)) {
      this.setState({ buttonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("admin/verification/event/update", {
          eventId: eventDetails._id,
          minimumVerifiers,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          editModalOpen: false,
          minimumVerifiers: 0,
          eventDetails: [],
        });
        this.getRecords();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = "Please enter valid mandate minimum verifiers.";
      toast.error(msg);
    }
  }

  render() {
    const { data, sizePerPage, page, renderFlag, filterByTitle, loading } =
      this.state;
    let _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "eventTitle",
        text: "Title",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "eventDescription",
        text: "Description",
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "totalVerifiers",
        text: "Total Verifiers",
        formatter: function (cell, row) {
          const totalVerifiersCounter = cell > 0 ? cell : "N/A";
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">{totalVerifiersCounter}</div>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div className="d-inline-block">
              <button
                className="btn btn-primary"
                onClick={() => _this.openEditModal(row)}
              >
                Edit
              </button>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          loading={loading}
          keyField="_id"
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">Filter Verification Events</h5>
                <form className="form-inline">
                  <label className="sr-only"> Title</label>
                  <input
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    name="filterByTitle"
                    id="filterByTitle"
                    placeholder="Filter By Title"
                    type="text"
                    onChange={this.onchange}
                    value={filterByTitle}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.filterRecords}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </form>
              </div>
            </div>

            <div className="element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span className="pull-left">Verification Events List</span>
                </h5>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditEventModal
          {...this.props}
          {...this.state}
          onCloseModal={this.closeEditModal}
          onInputValueChange={this.onchange}
          updateVerificationEvent={this.updateVerificationEvent}
        />
      </div>
    );
  }
}
export default AdminManageEvents;
