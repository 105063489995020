import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import logoImg from "../../assets/img/logo-white.png";
import ReactTooltip from "react-tooltip";
import dashboardIcon from "../../assets/img/sidebar-icons/dashboard.svg?v1";
import kycIcon from "../../assets/img/sidebar-icons/kyc.svg?v1";
import logoutIcon from "../../assets/img/sidebar-icons/logout.svg?v1";
import logoutLightIcon from "../../assets/img/sidebar-icons/logout_light.svg?v1";
import resourceIcon from "../../assets/img/sidebar-icons/resources.svg?v1";
import userIcon from "../../assets/img/sidebar-icons/user.svg?v1";
import userLightIcon from "../../assets/img/sidebar-icons/user_light.svg?v1";
import usersIcon from "../../assets/img/sidebar-icons/users.svg?v1";
import verifiersIcon from "../../assets/img/sidebar-icons/verifiers.svg?v1";
import whitelistingsIcon from "../../assets/img/sidebar-icons/whitelistings.svg?v1";
class UserDashboardMenu extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.menuMenu = React.createRef();
    this.closeMenu = this.closeMenu.bind(this);
  }
  closeMenu = () => {
    this.menuMenu.current.style.display = 'none';
  }
  
  activeRoute = (routeName) => {
    return window.location.pathname.indexOf(routeName) > -1
      ? "menu_active"
      : "cursor-pointer";
  };

  render() {
    let { authUserInfo } = this.props;
    let userDisplayName =
      !_.isUndefined(authUserInfo) &&
      authUserInfo !== null &&
      authUserInfo !== ""
        ? authUserInfo.userDisplayName
        : "";
    return (
      <>
        <div className="menu-container">
          <div className="menu-mobile menu-activated-on-click color-scheme-dark">
            <div className="mm-logo-buttons-w">
              <Link to="/my_account">
                <div className="mm-logo">
                  <img
                    className="customLogo"
                    src={logoImg}
                    height={70}
                    alt="Dealer"
                  />
                </div>
              </Link>
              <div className="mm-buttons">
                <div className="mobile-menu-trigger">
                  <div className="os-icon os-icon-hamburger-menu-1"></div>
                </div>
              </div>
            </div>
            <div className="menu-and-user" ref={this.menuMenu}>
              <div className="logged-user-w">
                <div className="logged-user-info-w">
                  <div className="logged-user-name">
                    Welcome {userDisplayName}
                  </div>
                </div>
              </div>
              <ul className="main-menu">
                <li className={this.activeRoute("dashboard")} onClick={this.closeMenu}>
                  <Link to="/dashboard" className="mobile-menu-link">
                    <div className="icon-w">
                      {/* <div className="os-icon os-icon-layout"></div> */}
                      <img
                        src={dashboardIcon}
                        alt="dashboard"
                        height="20"
                        width="20"
                      />
                    </div>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className={this.activeRoute("my_account")} onClick={this.closeMenu}>
                  <Link to="/my_account" className="mobile-menu-link">
                    <div className="icon-w">
                      {/* <div className="fa fa-user" data-tip="My Account"></div> */}
                      <img src={userIcon} alt="user" height="20" width="20" />
                    </div>
                    <span>My Account</span>
                  </Link>
                </li>
                <li className={this.activeRoute("users")} onClick={this.closeMenu}>
                  <Link to="/clients">
                    <div className="icon-w">
                      {/* <div className="fa fa-users" data-tip="Users"></div> */}
                      <img
                        src={usersIcon}
                        alt="clients"
                        height="20"
                        width="20"
                      />
                    </div>
                    <span>Clients</span>
                  </Link>
                </li>

                <li className={this.activeRoute("kyc")} onClick={this.closeMenu}>
                  <Link to="/kyc">
                    <div className="icon-w">
                      {/* <div className="fa fa-check" data-tip="KYC"></div> */}
                      <img src={kycIcon} alt="kyc" height="20" width="20" />
                    </div>
                    <span>KYC</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/events">
                    <div className="icon-w">
                      <div className="fa fa-history" data-tip="Events"></div>
                    </div>
                    <span>Events</span>
                  </Link>
                </li> */}
                <li className={this.activeRoute("manage_verifiers")} onClick={this.closeMenu}>
                  <Link to="/manage_verifiers">
                    <div className="icon-w">
                      <img
                        src={verifiersIcon}
                        alt="verifiers"
                        height="20"
                        width="20"
                      />
                    </div>
                    <span>Verifiers</span>
                  </Link>
                </li>
                <li className={this.activeRoute("whitelistings")} onClick={this.closeMenu}>
                  <Link to="/whitelistings">
                    <div className="icon-w">
                      {/* <div className="Whitelist-icon-mobile" data-tip="Whitelist"></div> */}
                      <img
                        src={whitelistingsIcon}
                        alt="whitelisting"
                        height="20"
                        width="20"
                      />
                    </div>
                    <span>Whitelist</span>
                  </Link>
                </li>
                <li className={this.activeRoute("resources")} onClick={this.closeMenu}>
                  <Link to="/resources">
                    <div className="icon-w">
                      {/* <div className="Whitelist-icon-mobile" data-tip="Whitelist"></div> */}
                      <img
                        src={resourceIcon}
                        alt="Resource"
                        height="20"
                        width="20"
                      />
                    </div>
                    <span>Resources</span>
                  </Link>
                </li>

                {/* <li>
                <Link to="/transactions">
                  <div className="icon-w">
                    <div className="fa fa-history"></div>
                  </div>
                  <span>Transactions</span>
                </Link>
              </li> */}
                <li>
                  <Link to="/logout" className="mobile-menu-link" onClick={this.closeMenu}>
                    <div className="icon-w">
                      {/* <div className="fa fa-sign-out" data-tip="Logout"></div> */}
                      <img
                        src={logoutIcon}
                        alt="logout"
                        height="20"
                        width="20"
                      />
                    </div>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="menu-w color-scheme-light color-style-transparent menu-position-side
           menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link user-dashboard-menu"
          >
            <div className="logged-user-w avatar-inline pl-0">
              <div className="logged-user-i">
                <div className="logged-user-info-w submenuleftpadding">
                  <div className="logged-user-name">
                    Welcome {userDisplayName}
                  </div>
                  <div className="logged-user-role">Exempt Market Dealer</div>
                </div>
                <div className="logged-user-toggler-arrow">
                  <div className="os-icon os-icon-chevron-down"></div>
                </div>
                <div className="logged-user-menu color-style-bright">
                  <div className="logged-user-avatar-info display-block">
                    <div className="logged-user-info-w menu-padding-set">
                      <div className="logged-user-name">{userDisplayName}</div>
                      <div className="logged-user-role">EMD</div>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="os-icon os-icon-wallet-loaded"></i>
                  </div>
                  <ul>
                    <li>
                      <Link to="/my_account">
                        {/* <i className="os-icon os-icon-user-male-circle2"></i> */}
                        <img
                          src={userLightIcon}
                          alt="user"
                          height="16"
                          width="16"
                        />
                        <label className="color-white ml-1">My Account</label>
                      </Link>
                    </li>
                    {/*  <li>
                    <Link to="/settings"><i className="fa fa-cog"></i><label className="color-white">Settings</label></Link>
                  </li> */}
                    <li>
                      <Link to="/logout">
                        {/* <i className="os-icon os-icon-signs-11"></i> */}
                        <img
                          src={logoutLightIcon}
                          alt="logout"
                          height="16"
                          width="16"
                        />
                        <label className="color-white ml-1">Logout</label>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ul className="main-menu">
              <li className={this.activeRoute("dashboard")}>
                <Link to="/dashboard">
                  <div className="icon-w">
                    {/* <div className="os-icon os-icon-layout"></div> */}
                    <img
                      src={dashboardIcon}
                      alt="dashboard"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className={this.activeRoute("clients")}>
                <Link to="/clients">
                  <div className="icon-w">
                    <img src={usersIcon} alt="clients" height="20" width="20" />
                  </div>
                  <span>Clients</span>
                </Link>
              </li>
              <li className={this.activeRoute("kyc")}>
                <Link to="/kyc">
                  <div className="icon-w">
                    {/* <div className="fa fa-check"></div> */}
                    <img src={kycIcon} alt="kyc" height="20" width="20" />
                  </div>
                  <span>KYC</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/manage_verification_events">
                  <div className="icon-w">
                    <div className="fa fa-history"></div>
                  </div>
                  <span>Events</span>
                </Link>
            </li> */}
              <li className={this.activeRoute("manage_verifiers")}>
                <Link to="/manage_verifiers">
                  <div className="icon-w">
                    {/* <div className="fa fa-user-plus"></div> */}
                    <img
                      src={verifiersIcon}
                      alt="verifiers"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Verifiers</span>
                </Link>
              </li>
              <li className={this.activeRoute("whitelistings")}>
                <Link to="/whitelistings">
                  <div className="icon-w">
                    <div>
                      <img
                        src={whitelistingsIcon}
                        alt="whitelisting"
                        height="20"
                        width="20"
                      />
                    </div>
                  </div>
                  <span>Whitelisting</span>
                </Link>
              </li>
              <li className={this.activeRoute("resources")}>
                <Link to="/resources">
                  <div className="icon-w">
                    <div>
                      <img
                        src={resourceIcon}
                        alt="Resource"
                        height="20"
                        width="20"
                      />
                    </div>
                  </div>
                  <span>Resources</span>
                </Link>
              </li>

              {/* <li className="selected cursor-pointer">
              <Link to="/whitelist_address">
                <div className="icon-w">
                  <div className="fa fa-check"></div>
                </div>
                <span>Whitelist</span>
              </Link>
            </li>
            <li className="selected cursor-pointer">
              <Link to="/transactions">
                <div className="icon-w">
                  <div className="fa fa-history"></div>
                </div>
                <span>Transactions</span>
              </Link>
            </li> */}
              <li className="selected cursor-pointer">
                <Link to="/logout">
                  <div className="icon-w">
                    {/* <div className="fa fa-power-off"></div> */}
                    <img src={logoutIcon} alt="logout" height="20" width="20" />
                  </div>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ReactTooltip place="right" effect="solid" />
      </>
    );
  }
}
export default UserDashboardMenu;
