import { tokenSymbol } from "../services/api-config";

/* Page title */
export const SIGNUP_PAGE_TITLE = "Sign Up";
export const SIGNIN_PAGE_TITLE = "Sign In";
export const FORGOT_PAGE_TITLE = "Forgot Password";
export const RESET_PASSWORD_PAGE_TITLE = "Reset Password";

export const KYC_BASIC_DETAILS_PAGE_TITLE = "Identity & Contact Details";
export const KYC_UPLOAD_FILES_PAGE_TITLE = "Upload KYC files";
export const KYC_SUBMIT_PAGE_TITLE = "Submit KYC";
export const KYC_STATUS_PAGE_TITLE = "KYC Status";

export const DASHBOARD_PAGE_TITLE = "Dashboard";
export const MY_ACCOUNT_PAGE_TITLE = "My Account";
export const SUBSCRIBE_NRT_PAGE_TITLE = `Subscribe ${tokenSymbol}`;
export const MY_INVESTMENT_PAGE_TITLE = "My Investment";
export const EXCHANGE_ORDER_PAGE_TITLE = "Exchange Orders";
export const LISTINGS_PAGE_TITLE = "Campaigns";
export const CAMPAIGNS_MAP_PAGE_TITLE = "Campaigns On Map";
export const WITHDRAWAL_REQUESTS_PAGE_TITLE = "Withdrawal Requests";
export const TERMS_CONDITIONS_PAGE_TITLE = "Terms & Conditions";
export const RESOURCES_PAGE_TITLE = "Resources";
export const TOKEN_HOLDERS_PAGE_TITLE = "Token Holders";
export const TRANSACTIONS_PAGE_TITLE = "Transactions";
export const MANAGE_EVENTS_PAGE_TITLE = "Manage Events";
export const MANAGE_VERIFICATION_EVENTS_PAGE_TITLE =
  "Manage Verification Events";
export const MANAGE_VERIFIERS_PAGE_TITLE = "Manage Verifiers";

export const INDIVIDUAL_PERSONAL_DETAILS_PAGE_TITLE =
  "Personal Details Identification";
export const INDIVIDUAL_CONTACT_DETAILS_PAGE_TITLE =
  "Contact Details Identification";
export const INDIVIDUAL_PROFESSIONAL_DETAILS_PAGE_TITLE =
  "Professional Details Identification";
export const INDIVIDUAL_BUSINESS_INTENSIONS_PAGE_TITLE =
  "Business Intensions Identification";
export const INDIVIDUAL_WEALTH_PAGE_TITLE = "Wealth Identification";
export const INDIVIDUAL_UNDERSTANDING_PAGE_TITLE =
  "Experience & understanding of financial markets and instruments";
export const INDIVIDUAL_SUBMIT_DETAILS_PAGE_TITLE =
  "Submit Identification Details";
export const LISTING_PROGRESS_REPORT_PAGE_TITLE = "Campaign Progress Reports";

export const INDIVIDUAL_FATCA_CRS_INFORMATION_PAGE_TITLE =
  "Instructions for completion";
export const INDIVIDUAL_FATCA_CRS_IDENTIFICATION_PAGE_TITLE =
  "Identification Details";
export const INDIVIDUAL_FATCA_CRS_DECLARATION_PAGE_TITLE =
  "Declaration of U.S. Citizenship";
export const INDIVIDUAL_FATCA_CRS_TAX_RESIDENCY_PAGE_TITLE =
  "CRS Declaration of Tax Residency";
export const EXCHANGE_PAGE_TITLE = "Exchange";

export const CORPORATE_COMPANY_DETAILS_PAGE_TITLE =
  "Company Details Identification";
export const CORPORATE_CONTACT_DETAILS_PAGE_TITLE =
  "Contact Details Identification";
export const CORPORATE_PROFESSIONAL_DETAILS_PAGE_TITLE =
  "Professional Details Identification";
export const CORPORATE_BUSINESS_INTENSIONS_PAGE_TITLE =
  "Business Intensions Identification";
export const CORPORATE_WEALTH_PAGE_TITLE = "Wealth Identification";
export const CORPORATE_UNDERSTANDING_PAGE_TITLE =
  "Experience & understanding of financial markets and instruments";
export const CORPORATE_SUBMIT_DETAILS_PAGE_TITLE =
  "Submit Identification Details";

export const CORPORATE_FATCA_CRS_INFORMATION_PAGE_TITLE =
  "Instructions for completion";
export const CORPORATE_FATCA_CRS_IDENTIFICATION_PAGE_TITLE =
  "Identification Details";
export const CORPORATE_FATCA_CRS_DECLARATION_PAGE_TITLE = "Declaration Details";
export const CORPORATE_FATCA_CRS_CLASSIFICATION_PAGE_TITLE =
  "Classification Details";
export const CORPORATE_FATCA_CRS_TAX_DECLARATION_PAGE_TITLE =
  "Tax declaration Details";
export const CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_PAGE_TITLE =
  "Entity’s CRS Classification Details";
export const CORPORATE_FATCA_CRS_DECLARATION_AND_UNDERSTANDING_PAGE_TITLE =
  "Declarations and Undertakings";
export const LISTING_DETAIL_PAGE_TITLE = "Campaign Details";
export const LISTING_PROGRESS_REPORTS_PAGE_TITLE = "Progress Reports";
export const USERS_PAGE_TITLE = "Clients";
export const KYC_PAGE_TITLE = "KYC";
export const TOKEN_SETTINGS_PAGE_TITLE = "Token Settings";
export const SITE_SETTINGS_PAGE_TITLE = "Site Settings";
export const PAGE_TITLE_SEPERATOR = " - ";

/* Page title ends */

export const PERMIAN_LABEL = "PermianChain";
export const LABEL_SELECT_COUNTRY = "Select country";

export const IDENTIFICATION_FILL_MSG_TO_USER =
  "Please fill identification details.";
export const SOMETHING_WENT_WRONG =
  "Something went wrong. Please try again later.";
export const RESOURCE_RECORD_NOT_FOUND = "There are no resources to display.";
export const REQUIRED_FIELDS_VALID_MSG =
  "Please enter valid details for required fields.";
export const PLEASE_ACCEPT_DECLARATION = "Please accept all declaration.";
export const KYC_DOCUMENTS_VERIFIED_SUCCESS =
  "Your KYC documents verified successfully.";
export const KYC_DOCUMENTS_REJECTED =
  "Your KYC documents has been rejected. Please submit it again.";
export const KYC_DOCUMENTS_UNDER_REVIEW =
  "Your KYC details are under review. We will notify you once it is completed.";

/* Error Message Display */

export const ERROR_MSG_VALID_RESOURCE_TITLE =
  "Please enter valid resource title.";
export const ERROR_MSG_VALID_RESOURCE_FILE = "Please add resource file.";
export const ERROR_MSG_SIGNATURE = "Please add your signature.";
export const ERROR_MSG_VALID_FILES = "Please upload valid files.";
export const ERROR_MSG_REQUIRED_FILES = "Please upload all required files.";
export const ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS =
  "Please enter valid receiving ETH address.";
export const ERROR_MSG_VALID_TWO_FA_CODE =
  "Please enter valid two factor code.";
export const ERROR_US_TIN_REQUIRED = "Please enter valid U.S. TIN no.";
export const METAMASK_CONNECT_TO_MAINNET =
  "Please connect to mainnet in metamask.";
export const METAMASK_CONNECT_TO_RINKEBY =
  "Please connect to rinkeby test network in metamask.";
export const METAMASK_CONNECT_TO_KOVAN =
  "Please connect to Kovan test network in metamask.";
export const ENOUGHT_NRT_TOKEN_BALANCE = `You does not have enough ${tokenSymbol} token balance to send.`;
export const TOKEN_TRANSFERED_SUCCESSFULLY = "Tokens transfered successfully.";
export const NOT_ENOUGHT_ETHER_TO_TRANSFER_TOKENS =
  "You dont have enough ethers to transfer Tokens.";
export const PLEASE_CONNECT_METAMASK_ACCOUNT =
  "Please connect with metamask account.";
export const USER_KYC_PENDING = "User is not KYC Verified.";
export const INVALID_TOKEN = "Invalid token request.";
export const INVALID_REQUEST = "Invalid request";
export const PROGRESS_REPORTS_RECORD_NOT_FOUND = "No progress reports found.";
export const PLEASE_ENTER_VALID_AMOUNT =
  "Please enter valid buy mandate amount.";
export const PLEASE_ENTER_VALID_QUANTITY =
  "Please enter valid sell mandate quantity";
export const WALLET_ADDRESS_COPIED_SUCCESSFULLY =
  "Custodian address copied successfully.";
export const MANDATE_CANCELLED_SUCCESSFULLY =
  "Mandate is cancelled successfully.";
export const MANDATE_NOT_PROVIDED_INVALID_REQUEST =
  "Mandate id not provided. Invalid request.";
export const NO_RECORDS_FOUND = "No records found.";
export const METAMASK_DEFAULT_MSG =
  "Connection to Metamask failed! Please try again. If problem persists then contact our support team.";
export const METAMASK_USER_REJECTED_MSG =
  "You have rejected the transaction. Please retry when ready.";
export const METAMASK_SELECT_WALLET_ADDRESS_MSG =
  "Please select wallet address";
export const METAMASK_SENDING_ADDRESS_MISMATCH_MSG =
  "Please select your whitelisted ETH address";
export const METAMASK_INVALID_AMOUNT_MSG = "Please enter amount";
export const ACCEPT_SUBSCRIBE_TERMS = "Please accept all subscribe terms.";
export const PERMIANCHAIN_ETHEREUM_CONTRIBUTION_ADDRESS_COPIED =
  "PermianChain ethereum contribution address copied successfully.";
export const PERMIANCHAIN_BITCOIN_CONTRIBUTION_ADDRESS_COPIED =
  "PermianChain bitcoin contribution address copied successfully.";
export const PERMIANCHAIN_USDC_CONTRIBUTION_ADDRESS_COPIED =
  "Permian USDC contribution address copied successfully.";
export const PERMIANCHAIN_CONTRIBUTION_ADDRESS_COPIED =
  "Contribution address copied successfully.";
export const ERROR_MSG_REQUIRED_MESSAGE_FOR_WIRE_TRANSFER =
  "Wire transfer details required.";
export const WHITELIST_ADDRESS_COPIED = "Whitelist address copied successfully.";

/* Error Message Display */
export const LABEL_INDIVIDUAL_PROFESSIONAL_STATUS = {
  E: "Employed",
  SE: "Self Employed",
  R: "Retired",
  NE: "Not Employed",
};

export const LABEL_INDIVIDUAL_LEARNED_ABOUT_OUR_SERVICE = {
  OE: "By one of your employees",
  OWN: "On my own initiative (marketing, website…)",
  R: "Referral by one of your other customers",
  ROUB: "Referral by one of your business introducers/intermediaries",
};

export const LABEL_INDIVIDUAL_EXPECTED_IN_OUT_FLOW = {
  L500000USD: "Less than 500,000 USD",
  "500000TO1000000USD": "500,000 to 1,000,000 USD",
  M1000000USD: "More than 1,000,000 USD",
};

export const LABEL_INDIVIDUAL_ESTIMATED_GLOBAL_WEALTH = {
  L500000USD: "Less than 500,000 USD",
  "500000TO1000000USD": "500,000 to 1,000,000 USD",
  "1000000TO5000000USD": "1,000,000 to 5,000,000 USD",
  "5000000TO10000000USD": "5,000,000 to 10,000,000 USD",
  M10000000USD: "More than 10,000,000 USD",
  OTHER: "Other",
};

export const LABEL_INDIVIDUAL_BUILTUP_GLOBAL_NET_WEALTH = {
  PROFESSIONALBUSINESSACTIVITIES: "Professional/business activities",
  INHERITANCEGIFTS: "Inheritance / Gifts",
  PENSIONORSTOCKOPTIONPAYOUT: "Pension or Stock option pay out",
  REALESTATETRANSACTIONS: "Real estate transactions",
  MANAGINGPERSONALINVESTMENTS: "Managing personal investments",
  SALEOFOWNBUSINESS: "Sale of own business",
  OTHER: "Other",
};

export const LABEL_INDIVIDUAL_ANNUAL_REGULAR_NET_INCOME = {
  UPTO100000USD: "Up to 100,000 USD",
  "100000TO250000USD": "100,000 to 250,000 USD",
  "250000TO500000USD": "250,000 to 500,000 USD",
  "500000TO1000000USD": "500,000 to 1,000,000 USD",
  M1000000USD: "More than 1,000,000 USD",
};

export const LABEL_INDIVIDUAL_HOW_YOU_MAKE_REGULAR_NET_INCOME = {
  SALARYORPENSION: "Salary or pension",
  INCOMEFROMINVESTMENTS: "Income from investments",
  BUSINESSINCOME: "Business income",
  RENTALINCOME: "Rental income",
  M1000000USD: "More than 1,000,000 USD",
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_MARKET = {
  EXCELLENT: "Excellent",
  GOOD: "Good",
  MODERATE: "Moderate",
  POOR: "Poor",
  OTHER: "Other",
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET = {
  L1YEAR: "Less than 1 year",
  "1TO2YEARS": "1 to 2 years",
  "3TO5YEARS": "3 to 5 years",
  M5YEARS: "More than 5 years",
  OTHER: "Other",
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_ASSETS_MANAGED_BEFORE = {
  WITHADVICEROFFINANCIALADVISOR: "With advice of a financial advisor",
  NONDISCRETIONARYMANAGEMENT: "Non-discretionary management",
  DISCRETIONARYMANAGEMENT: "Discretionary management",
  BYMYSELF: "By Myself",
  OTHER: "Other",
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_FINANCIAL_PRODUCTS_ALREADY_INVESTED =
{
  SHARES: "Shares (equity)",
  BONDS: "Bonds (debentures)",
  COLLECTIVEINVESTMENTFUND: "Collective investment funds",
  SUKUK: "Sukuk",
  STRUCTUREDPRODUCTS: "Structured products",
  DERIVATIVES: "Derivatives (options, futures)",
  INSURANCEINVESTMENTS: "Insurance investments",
  COMMODITIES: "Commodities",
  CURRENCIES: "Currencies",
  HEDGEFUND: "Hedge funds",
  PRIVATEEQUITY: "Private equity",
  OTHER: "Other",
};

export const LABEL_INDIVIDUAL_UNDERSTANDING_INVESTMENT_DECISIONS = {
  SELDOM: "Seldom",
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  DAILY: "Daily",
  OTHER: "Other",
};

export const LABEL_CORPORATE_BUSINESS_ACTIVITIES = {
  COMMERCIAL_ACTIVITIES: "Commercial activities",
  INVESTMENT_ACTIVITIES: "Investment activities",
  PERSONAL_INVESTMENT_VEHICLE: "Personal investment vehicle",
  ANY_OTHER_ACTIVITIES: "Any other activities",
};

export const LABEL_CORPORATE_LEARNED_ABOUT_OUR_SERVICE = {
  OE: "By one of your employees",
  OWN: "On my own initiative (marketing, website…)",
  R: "Referral by one of your other customers",
  ROUB: "Referral by one of your business introducers/intermediaries",
};

export const LABEL_CORPORATE_EXPECTED_IN_OUT_FLOW = {
  L500000USD: "Less than 500,000 USD",
  "500000TO1000000USD": "500,000 to 1,000,000 USD",
  M1000000USD: "More than 1,000,000 USD",
};

export const LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH = {
  L500000USD: "Less than 500,000 USD",
  "500000TO1000000USD": "500,000 to 1,000,000 USD",
  "1000000TO5000000USD": "1,000,000 to 5,000,000 USD",
  "5000000TO10000000USD": "5,000,000 to 10,000,000 USD",
  M10000000USD: "More than 10,000,000 USD",
  OTHER: "Other",
};

export const LABEL_CORPORATE_ESTIMATED_GLOBAL_WEALTH_BUILD_UP = {
  BUSINESS_INCOME: "Business income",
  INCOME_FROM_INVESTMENT: "Income from Investments",
  SHAREHOLDER_EQUITY: "Shareholder’s equity",
  PRIVATE_PLACEMENT: "Private Placement",
  SALE_OF_INVESTMENT: "Sale of Investments",
  OTHER: "Other",
};

export const LABEL_CORPORATE_VEHICLE_CAPITAL_SOURCE = {
  PROFESSIONAL_INCOME: "Professional Income",
  SALE_FROM_INVESTMENT: "Sale of Investments",
  REAL_ESTATE_TRANSACTION: "Real estate transactions",
  GIFT_INHERITANCE: "Gift /Inheritance",
  INCOME_FROM_INVESTMENT: "Income from Investments",
  OTHER: "Other",
};

export const LABEL_CORPORATE_UNDERSTANDING_FINANCIAL_MARKET = {
  EXCELLENT: "Excellent",
  GOOD: "Good",
  MODERATE: "Moderate",
  POOR: "Poor",
  OTHER: "Other",
};

export const LABEL_CORPORATE_UNDERSTANDING_ACTIVE_ON_FINANCIAL_MARKET = {
  L1YEAR: "Less than 1 year",
  "1TO2YEARS": "1 to 2 years",
  "3TO5YEARS": "3 to 5 years",
  M5YEARS: "More than 5 years",
  OTHER: "Other",
};

export const LABEL_CORPORATE_UNDERSTANDING_ASSETS_MANAGED_BEFORE = {
  WITHADVICEROFFINANCIALADVISOR: "With advice of a financial advisor",
  NONDISCRETIONARYMANAGEMENT: "Non-discretionary management",
  DISCRETIONARYMANAGEMENT: "Discretionary management",
  BYMYSELF: "By Myself",
  OTHER: "Other",
};

export const LABEL_CORPORATE_UNDERSTANDING_INVESTMENT_DECISIONS = {
  SELDOM: "Seldom",
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  DAILY: "Daily",
  OTHER: "Other",
};

export const LABEL_CORPORATE_FATCA_CRS_DECLARATION = {
  SPECIFIEDUSPERSON:
    "The Subscriber is a Specified U.S. Person and the Subscriber’s U.S. Federal Taxpayer Identifying number (U.S. TIN) is as follows:",
  NOTSPECIFIEDUSPERSON:
    "The Subscriber is not a Specified U.S. Person (please also complete Sections 3, 4 and 5).",
  ENTITYUSPERSON:
    "The Entity is a US person but not a Specified U.S. Person (please also complete Sections 3, 4 and 5).",
};

export const LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION = {
  PARTNER: "Partner Jurisdiction Financial Institution",
  DEEMEDCOMPLIANT: "Registered Deemed Compliant Foreign Financial Institution",
  PARTICIPATING: "Participating Foreign Financial Institution",
};

export const LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_FINANCIAL_INSTITUTION_WITHOUT_GIIN =
{
  SPONSOREDBY:
    "The Subscriber has not yet obtained a GIIN but is sponsored by another entity which does have a GIIN (its “Sponsor”).  Please provide the sponsor’s name and sponsor’s GIIN",
  EXEMPTBENEFICIALOWNER: "Exempt Beneficial Owner",
  CERTIFIEDDEEMEDCOMPLIANT:
    "Certified Deemed Compliant Foreign Financial Institution (including a deemed compliant Financial Institution under Annex II of the Agreement)",
  NONPARTICIPATING: "Non-Participating Foreign Financial Institution",
  EXCEPTED: "Excepted Foreign Financial Institution",
};

export const LABEL_CORPORATE_FATCA_CRS_CLASSIFICATION_NON_FINANCIAL_INSTITUTION =
{
  ACTIVE: "Active Non-Financial Foreign Entity",
  PASSIVE: "Passive Non-Financial Foreign Entity",
  EXCEPTED: "Excepted Non-Financial Foreign Entity",
};

export const LABEL_CORPORATE_FATCA_CRS_ENTITY_CLASSIFICATION_FINANCIAL_INSTITUTION =
{
  UNDERCRS: "Financial Institution under CRS",
  OTHERFINANCIALINSTITUTION:
    "An Investment Entity located in a Non-Participating Jurisdiction and managed by another Financial Institution",
};

export const LABEL_CORPORATE_FATCA_CRS_ENTITY_NON_FINANCIAL_INSTITUTION = {
  CORPORATION:
    "Active Non-Financial Entity – a corporation the stock of which is regularly traded on an established securities market or a corporation which is a related entity of such a corporation",
  GOVERNMENT:
    "Active Non-Financial Entity – a Government Entity or Central Bank",
  INTERNATIONALORGANIZATION:
    "Active Non-Financial Entity – an International Organization",
  OTHER:
    "Active Non-Financial Entity – other than (I)-(III) (for example a start-up Non-Financial Entity or a non-profit Non-Financial Entity)",
  PASSIVE: "Passive Non-Financial Entity",
};

export const LABEL_INDIVIDUAL_FATCA_CRS_DECLARATION_US_PERSON = {
  Y: "I confirm that [I am]/[the Subscriber is] a U.S. citizen and/or resident in the U.S. for tax purposes and [my]/[its] U.S. federal taxpayer identifying number (U.S. TIN) is as follows:",
  N: "I confirm that [I am not]/[the Subscriber is not] a U.S. citizen or resident in the U.S. for tax purposes.",
};

/* Messages */
