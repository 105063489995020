import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import Api from "../../services/api";
import auth from "../../utils/auth";
import Basepages from "./Basepages";
import Resources from "../Resources/Resources";
import EmailVerifyBasePages from "./EmailVerifyBasePages";
import Base from "./Base";
import Signin from "../Signin/Signin";
import Signup from "../Signup/Signup";
import EmailVerify from "../EmailVerify/EmailVerify";
import SubscribeEmailVerify from "../EmailVerify/SubscribeEmailVerify";
import Forgot from "../Forgot/Forgot";
import ResetPassword from "../ResetPassword/ResetPassword";
import Dashboard from "../Dashboard/Dashboard";
import MyAccount from "../MyAccount/MyAccount";
import UsersList from "../UsersList/UsersList";
import KycList from "../KycList/KycList";
import KycDetail from "../KycList/KycDetail";
import Terms from "../Terms/Terms";
import Logout from "../Logout/Logout";
import ManageVerifiers from "../ManageVerifiers/ManageVerifiers";
import ManageEvents from "../ManageEvents/ManageEvents";
import DiligenceDetail from "../DiligenceDetail/DiligenceDetail";
import TokenSettings from "../TokenSettings/TokenSettings";
/* import SiteSettings from "../SiteSettings/SiteSettings"; */
import Campaigns from "../Campaigns/Campaigns";
import CampaignDetails from "../Campaigns/CampaignDetails";
import CampaignProgressList from "../Campaigns/CampaignProgressList";
import Whitelistings from "../Whitelistings/Whitelistings";
import UserDetail from "../UserDetail/UserDetail";
import UserIdentificationDetail from "../UserIdentificationDetail/UserIdentificationDetail";
import UserFatcaDetail from "../UserFatcaDetail/UserFatcaDetail";
import IdentificationDetailPdfGenerate from "../IdentificationPdfGenerate/IdentificationPdfGenerate";

let currentLocation = "";
let loginCheckInterval;

const PrivateRoute = ({ component: Component, ...rest }) => {
  let messageLoginPage = "",
    messageClass = "";
  const authToken = auth.getToken();
  if (authToken === null) {
    messageLoginPage = "LOGIN_REQUIRED";
    messageClass = "warning";
  }
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          !_.isNull(authToken) ? (
            <Component {...rest} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: {
                  from: props.location,
                  message: messageLoginPage,
                  messageClass,
                },
              }}
            />
          )
        }
      />
    </div>
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const authToken = auth.getToken();
  //const authUserInfo = auth.getUserInfo();
  return (
    <Route
      {...rest}
      render={(props) =>
        _.isNull(authToken) ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logout = this.logout.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.checkKycVerified = this.checkKycVerified.bind(this);
    this.pageProgress = this.pageProgress.bind(this);
    /*this.checkKycUrlValid = this.checkKycUrlValid.bind(this);*/
  }
  logout(msg, message_type) {
    auth.clearToken();
    auth.clearUserInfo();
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
    this.props.history.push({
      pathname: "/signin",
    });
  }
  checkLogin() {
    const authToken = auth.getToken();
    if (!_.isNull(authToken)) {
      loginCheckInterval = setInterval(() => {
        const updatedToken = auth.getToken();
        if (_.isNull(updatedToken)) {
          this.logout("TOKEN_EXPIRED", "warning");
        }
      }, 1500);
    }
  }

  pageProgress(action) {
    var body = document.body;
    if (action === "remove") {
      body.classList.add("page-loaded");
    } else if (action === "force_remove") {
      setTimeout(() => {
        body.classList.add("page-loaded");
      }, 5000);
    } else {
      body.classList.remove("page-loaded");
    }
  }

  async checkKycVerified(flag = false) {
    const authToken = auth.getToken();
    //const authUserInfo = auth.getUserInfo();
    let params = {};
    let response = { status: true };
    if (!_.isUndefined(authToken) && authToken !== "" && authToken !== null) {
      const api = new Api();
      if (flag === true) {
        params.twoFA = true;
      }
      const userDetailsResponse = await api
        .setToken(authToken)
        .create("user/getUserDetails", params);
      if (!_.isUndefined(userDetailsResponse)) {
        if (
          (_.isUndefined(userDetailsResponse.data.userKycDetail) ||
            userDetailsResponse.data.userKycDetail === null ||
            userDetailsResponse.data.userKycDetail === "") &&
          userDetailsResponse.data.isKycVerified === false
        ) {
          response.status = false;
          response.redirectUrl = "/kyc";
        } else if (
          userDetailsResponse.data.kycStatus === "u" ||
          userDetailsResponse.data.kycStatus === "r"
        ) {
          response.status = false;
          response.redirectUrl =
            userDetailsResponse.data.kycStatus === "u"
              ? "kyc_status/pending"
              : "/kyc_status/rejected";
        } else {
          response.userDetailsResponse = userDetailsResponse;
        }
      }
    } else {
      this.props.history.push("/signin");
    }
    return response;
  }

  componentDidMount() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];
    this.checkLogin();
    /*
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    */
  }
  componentWillUnmount() {
    clearInterval(loginCheckInterval);
    loginCheckInterval = false;
    /*window.removeEventListener("resize", this.updateWindowDimensions);*/
  }
  updateWindowDimensions = () => {
    const windowHeight = window.innerHeigh;
    const windowWidth = window.innerWidth;
    const isMobileSized = window.innerWidth < 700 ? true : false;
    this.setState({ windowWidth, windowHeight, isMobileSized });
  };

  renderAuthenticationRoute() {
    return (
      <Basepages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <Route
          path="/terms_conditions"
          render={() => (
            <Terms
              {...this.props}
              {...this.state}
              pageProgress={this.pageProgress}
            />
          )}
        />
        <PublicRoute
          {...this.state}
          path="/signin"
          exact={true}
          component={Signin}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/signup"
          component={Signup}
          urlpath={currentLocation}
          checkLogin={this.checkLogin}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/forgot"
          component={Forgot}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/reset_password/:id"
          component={ResetPassword}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
      </Basepages>
    );
  }

  renderEmailVerifyRoute() {
    return (
      <EmailVerifyBasePages
        {...this.state}
        logout={this.logout}
        currentLocation={currentLocation}
      >
        <PublicRoute
          {...this.state}
          path="/email_verification/:id"
          component={EmailVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
        <PublicRoute
          {...this.state}
          path="/subscribe_email_verification/:id"
          component={SubscribeEmailVerify}
          urlpath={currentLocation}
          pageProgress={this.pageProgress}
        />
      </EmailVerifyBasePages>
    );
  }

  renderAuthenitcatedRoute() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    return (
      <Base
        logout={this.logout}
        currentLocation={currentLocation}
        {...this.state}
        authUserInfo={authUserInfo}
        authToken={authToken}
      >
        <Switch>
          <PrivateRoute
            {...this.state}
            path="/dashboard"
            component={Dashboard}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/my_account"
            component={MyAccount}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/users"
            component={UsersList}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/clients"
            component={UsersList}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/kyc"
            component={KycList}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/kyc_detail/:id"
            component={KycDetail}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/diligence_details/:id"
            component={DiligenceDetail}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          {/* <PrivateRoute
            {...this.state}
            path="/settings"
            component={SiteSettings}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          /> */}
          <PrivateRoute
            {...this.state}
            path="/token_settings/:id"
            component={TokenSettings}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/logout"
            component={Logout}
            authToken={authToken}
            logout={this.logout}
            authUserInfo={authUserInfo}
          />
          <PrivateRoute
            {...this.state}
            path="/manage_verifiers"
            component={ManageVerifiers}
            urlpath={currentLocation}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/manage_verification_events"
            component={ManageEvents}
            urlpath={currentLocation}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/campaigns/:id"
            component={Campaigns}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/campaign_detail/:id"
            component={CampaignDetails}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/progress_reports/:id"
            component={CampaignProgressList}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/whitelistings"
            component={Whitelistings}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/resources"
            component={Resources}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/user_detail/:id"
            component={UserDetail}
            checkKycVerified={this.checkKycVerified}
            authToken={authToken}
            authUserInfo={authUserInfo}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/user_identificaiton_detail/:id"
            component={UserIdentificationDetail}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/user_fatca_crs_detail/:id"
            component={UserFatcaDetail}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
          <PrivateRoute
            {...this.state}
            path="/identificaiton_details_pdf/:id"
            component={IdentificationDetailPdfGenerate}
            authUserInfo={authUserInfo}
            authToken={authToken}
            pageProgress={this.pageProgress}
          />
        </Switch>
      </Base>
    );
  }

  renderHomeRoute() {}

  renderMainPage() {
    const authToken = auth.getToken();
    const authUserInfo = auth.getUserInfo();
    if (_.isNull(authToken) || _.isUndefined(authToken)) {
      return (
        <Basepages
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <Signin
                {...this.props}
                {...this.state}
                urlpath={currentLocation}
                checkLogin={this.checkLogin}
                pageProgress={this.pageProgress}
              />
            )}
          />
        </Basepages>
      );
    } else {
      return (
        <Base
          {...this.state}
          logout={this.logout}
          currentLocation={currentLocation}
        >
          <Route
            exact={true}
            path="/"
            render={() => (
              <Dashboard
                {...this.props}
                {...this.state}
                pageProgress={this.pageProgress}
                checkKycVerified={this.checkKycVerified}
                authToken={authToken}
                authUserInfo={authUserInfo}
              />
            )}
          >
            <Redirect to="/dashboard" />
          </Route>
        </Base>
      );
    }
  }
  render() {
    const urlString = this.props.location.pathname.substr(1);
    const urlStringArr = urlString.split("/");
    currentLocation = urlStringArr[0];
    let path = [
      "signin",
      "signup",
      "forgot",
      "reset_password",
      "terms_conditions",
    ];
    let emailVerify = ["email_verification", "subscribe_email_verification"];
    let homeRoutes = ["privacy"];
    if (currentLocation === "") {
      return <div>{this.renderMainPage()}</div>;
    } else if (currentLocation === "admin") {
      const authToken = auth.getToken();
      if (_.isNull(authToken) || _.isUndefined(authToken)) {
        toast.error("Invalid URL.");
        this.props.history.push("/signin");
      } else {
        this.props.history.push("/dashboard");
      }
      return "<div></div>";
    } else if (
      currentLocation !== "" &&
      homeRoutes.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderHomeRoute()}</div>;
    } else if (
      currentLocation !== "" &&
      emailVerify.indexOf(currentLocation) > -1
    ) {
      return <div>{this.renderEmailVerifyRoute()}</div>;
    } else if (currentLocation !== "" && path.indexOf(currentLocation) > -1) {
      return <div>{this.renderAuthenticationRoute()}</div>;
    } else {
      return <div>{this.renderAuthenitcatedRoute()}</div>;
    }
  }
}
export default withRouter(Layout);
