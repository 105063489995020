import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import CKEditor from "ckeditor4-react";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import * as messageConstants from "../../utils/Messages";

class TokenSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      subscriptionLoading: false,
      xAxisTimeUnit: "Year",
      xAxisTitle: "",
      subscriptionRejected: false,
      subscriptionRejectedReason: "",
      filePath: "",
      tokenDetailsButtonLoading: false,
      subscriptionAgreementNote: "",
    };
    this.onchange = this.onchange.bind(this);
    this.saveTokenSettings = this.saveTokenSettings.bind(this);
    this.saveSubscriptionAgreement = this.saveSubscriptionAgreement.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.TOKEN_SETTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
    this.getTokenDetails();
  }

  async getTokenDetails() {
    try {
      const api = new Api();
      let issuerId = this.props.match.params.id;
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/details", { issuerId: issuerId });
      if (response.code === 200) {
        /* const listedUnissuedToken =
          parseFloat(response.data.listedTokenInitialSupply) -
          parseFloat(response.data.listedTokenIssued);
        const listedFloatingToken =
          parseFloat(response.data.listedTokenIssued) -
          parseFloat(response.data.listedTokenRestricted);
        const outstandingToken =
          parseFloat(response.data.listedTokenIssued) -
          parseFloat(response.data.listedTokenTreasury); */

        let stateObj = {
          listedTokenCompanyName: response.data.listedTokenCompanyName,
          listedTokenCompanyEmail: response.data.listedTokenCompanyEmail,
          listedTokenCompanyLicenseId:
            response.data.listedTokenCompanyLicenseId,
          listedTokenCompanySecurityId:
            response.data.listedTokenCompanySecurityId,
          listedTokenCompanyUrl: response.data.listedTokenCompanyUrl,
          listedTokenWhitepaerLink: response.data.listedTokenWhitepaerLink,
          companyOfficialAddress: response.data.companyOfficialAddress,
          listedTokenName: response.data.listedTokenName,
          listedTokenSymbol: response.data.listedTokenSymbol,
          listedTokenDecimalPlace: response.data.listedTokenDecimalPlace,
          listedTokenInitialSupply: response.data.listedTokenInitialSupply,
          listedTokenMaxOwnership: response.data.listedTokenMaxOwnership,
          listedTokenOfficialAddress: response.data.listedTokenOfficialAddress,
          listedTokenOutstanding: response.data.listedTokenOutstanding,
          listedTokenOwnersERC20Address:
            response.data.listedTokenOwnersERC20Address,
          listedTokenTotalUnlocked: response.data.listedTokenTotalUnlocked,
          listedTokenUnIssued: response.data.listedTokenUnIssued,
          listedTokenAuthorized: response.data.listedTokenAuthorized,
          listedUnissuedToken: response.data.listedUnissuedToken,
          listedFloatingToken: response.data.listedFloatingToken,
          outstandingToken: response.data.outstandingToken,
          listedTokenRestricted:
            response.data.listedTokenRestricted > 0
              ? response.data.listedTokenRestricted
              : 0,
          listedTokenTreasury:
            response.data.listedTokenTreasury > 0
              ? response.data.listedTokenTreasury
              : 0,
          listedTokenIssued: response.data.listedTokenIssued,
          listedTokenPrice: response.data.listedTokenPrice,
          subscriptionAgreementData: response.data.subscriptionAgreementData,
          listedTokenNetPresentValue: response.data.listedTokenNetPresentValue,

          listedTokenProvince: response.data.listedTokenProvince,
          listedTokenCountry: response.data.listedTokenCountry,
          listedTokenTotalOfferings: response.data.listedTokenTotalOfferings,
          listedTokenContractAddress: response.data.listedTokenContractAddress,
          listedTokenTotalAmountRaised:
            response.data.listedTokenTotalAmountRaised,
          listedTokenOutstandingTokens:
            response.data.listedTokenOutstandingTokens,
          subscriptionAgreementNote: response.data.subscriptionAgreementNote,
          filePath: response.data.agreementFile
            ? response.data.agreementFile
            : "",
        };

        this.setState(stateObj, async () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (err) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onRejectAgreement() {
    this.setState({ subscriptionRejected: true });
  }

  async saveTokenSettings(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    let issuerId = this.props.match.params.id;
    try {
      const {
        listedTokenCompanyName,
        listedTokenCompanyLicenseId,
        listedTokenCompanySecurityId,
        listedTokenCompanyUrl,
        listedTokenWhitepaerLink,
        companyOfficialAddress,
        listedTokenName,
        listedTokenSymbol,
        listedTokenDecimalPlace,
        listedTokenInitialSupply,
        listedTokenMaxOwnership,
        listedTokenOwnersERC20Address,
        listedTokenIssued,
        listedTokenRestricted,
        listedTokenTreasury,
        listedTokenTotalUnlocked,
        listedTokenAuthorized,
        listedTokenOfficialAddress,
        listedTokenPrice,
        listedTokenCompanyEmail,
        listedTokenNetPresentValue,
      } = this.state;
      if (
        !_.isUndefined(listedTokenIssued) &&
        !_.isUndefined(listedTokenRestricted) &&
        !_.isUndefined(listedTokenTreasury)
      ) {
        this.setState({
          buttonLoading: true,
        });
        const api = new Api();
        const response = await api
          .setToken(authenticationToken)
          .create("user/dealer/token/details", {
            listedTokenCompanyName,
            listedTokenCompanyLicenseId,
            listedTokenCompanySecurityId,
            listedTokenCompanyUrl,
            listedTokenWhitepaerLink,
            companyOfficialAddress,
            listedTokenName,
            listedTokenSymbol,
            listedTokenDecimalPlace,
            listedTokenInitialSupply,
            listedTokenMaxOwnership,
            listedTokenOwnersERC20Address,
            listedTokenIssued,
            listedTokenRestricted,
            listedTokenTreasury,
            listedTokenTotalUnlocked,
            listedTokenOfficialAddress,
            listedTokenAuthorized,
            listedTokenPrice,
            issuerId,
            listedTokenCompanyEmail,
            listedTokenNetPresentValue,
          });
        if (response) {
          this.setState({ buttonLoading: false });
        }
        if (response.code === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async saveSubscriptionAgreement(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    let issuerId = this.props.match.params.id;
    try {
      this.setState({ subscriptionLoading: true });
      const { subscriptionAgreementNote } = this.state;
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/dealer/subscription/agreement/details", {
          issuerId,
          subscriptionAgreementNote,
        });
      if (response) {
        this.setState({ subscriptionLoading: false });
      }
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (event) {
      console.log("event", event);
    }
  }

  render() {
    const {
      listedTokenName,
      listedTokenCompanyName,
      listedTokenCompanyLicenseId,
      listedTokenCompanySecurityId,
      listedTokenCompanyUrl,
      listedTokenSymbol,
      listedTokenDecimalPlace,
      listedTokenOwnersERC20Address,
      listedTokenAuthorized,
      listedTokenTreasury,
      listedTokenIssued,
      listedTokenRestricted,
      listedTokenTotalUnlocked,
      listedTokenPrice,
      subscriptionLoading,
      tokenDetailsFetch,
      listedTokenProvince,
      listedTokenCountry,
      listedTokenOutstandingTokens,
      listedTokenTotalOfferings,
      listedTokenTotalAmountRaised,
      tokenDetailsButtonLoading,
      subscriptionAgreementNote,
      filePath,
    } = this.state;
    let tokenButtonDisabled = tokenDetailsFetch === true ? true : false;
    let tokenSettingsButtonDisabled =
      tokenDetailsButtonLoading === true ? true : false;
    return (
      <div className="token-settings-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header">
                      <span className="pull-left text-xl">Token Settings</span>
                      <Link
                        to="/users"
                        className="pull-right"
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#395cbe",
                        }}
                      >
                        Back to users list
                      </Link>
                    </h6>

                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Company Name*
                            </label>
                            <input
                              className="form-control"
                              readonly=""
                              placeholder={`Please Enter Company Name`}
                              type="text"
                              name="listedTokenCompanyName"
                              onChange={this.onchange}
                              value={listedTokenCompanyName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Business Number
                              <i
                                className="fa fa-info-circle ml-1"
                                id="totalAsicsTooltip"
                                title="Total ASICs"
                              ></i>
                              <UncontrolledPopover
                                trigger="hover"
                                placement="bottom"
                                target="totalAsicsTooltip"
                              >
                                <PopoverBody>
                                  Insert the Issuer's Business Number (BN) as
                                  provided by the Canada Revenue Agency (CRA).
                                </PopoverBody>
                              </UncontrolledPopover>
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter Company License Id`}
                              type="text"
                              readonly=""
                              name="listedTokenCompanyLicenseId"
                              onChange={this.onchange}
                              value={listedTokenCompanyLicenseId}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Company URL
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter Company URL`}
                              type="text"
                              readonly=""
                              name="listedTokenCompanyUrl"
                              onChange={this.onchange}
                              id={listedTokenCompanyUrl}
                              value={listedTokenCompanyUrl}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Country
                            </label>
                            <input
                              className="form-control"
                              readonly=""
                              placeholder={`Please Enter Country`}
                              type="text"
                              name="listedTokenCountry"
                              onChange={this.onchange}
                              value={listedTokenCountry}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Province
                            </label>
                            <input
                              className="form-control"
                              readonly=""
                              placeholder={`Please Enter Province`}
                              type="text"
                              name="listedTokenProvince"
                              onChange={this.onchange}
                              value={listedTokenProvince}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label class="text-default text-default-color">
                              Token Name*
                            </label>
                            <input
                              className="form-control"
                              readonly=""
                              placeholder={`Please Enter Token Name`}
                              type="text"
                              name="listedTokenName"
                              onChange={this.onchange}
                              value={listedTokenName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Token Ticker*
                            </label>
                            <input
                              className="form-control"
                              readonly=""
                              placeholder={`Please Enter Token Ticker`}
                              type="text"
                              name="listedTokenSymbol"
                              onChange={this.onchange}
                              value={listedTokenSymbol}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Token Decimal Place*
                            </label>
                            <input
                              readonly=""
                              className="form-control"
                              placeholder={`Please Enter Decimal Place`}
                              type="text"
                              name="listedTokenDecimalPlace"
                              onChange={this.onchange}
                              value={listedTokenDecimalPlace}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Issued Tokens
                            </label>
                            <input
                              readonly=""
                              className="form-control"
                              placeholder={`Enter Issued Tokens`}
                              type="text"
                              name="listedTokenIssued"
                              onChange={this.onchange}
                              value={listedTokenIssued}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Owner ERC20 Address*
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter Owner's ERC20 Address`}
                              type="text"
                              readonly=""
                              name="listedTokenOwnersERC20Address"
                              onChange={this.onchange}
                              value={listedTokenOwnersERC20Address}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Offering Tokens
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Enter Total Offering Tokens`}
                              type="text"
                              name="listedTokenTotalOfferings"
                              onChange={this.onchange}
                              value={listedTokenTotalOfferings}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Outstanding Tokens
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter Initial Supply`}
                              type="text"
                              name="listedTokenOutstandingTokens"
                              onChange={this.onchange}
                              value={listedTokenOutstandingTokens}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Treasury Tokens
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Enter Token In Treasury`}
                              type="text"
                              name="listedTokenTreasury"
                              onChange={this.onchange}
                              value={listedTokenTreasury}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Authorized Tokens
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Enter Authorized Tokens`}
                              type="text"
                              name="listedTokenAuthorized"
                              onChange={this.onchange}
                              value={listedTokenAuthorized}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Token Price*
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Enter Token Price`}
                              type="text"
                              name="listedTokenPrice"
                              onChange={this.onchange}
                              value={listedTokenPrice}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-default text-default-color">
                              Total Amount Raised
                            </label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter Total Amount Raised`}
                              type="text"
                              name="listedTokenTotalAmountRaised"
                              onChange={this.onchange}
                              value={listedTokenTotalAmountRaised}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-buttons-w custom-submit-button2 mt-0 border-top-none m-btn-center
                    d-flex justify-content-end"
                    >
                      <button
                        className="btn default-border-btn"
                        type="submit"
                        onClick={(e) => this.saveTokenSettings(e)}
                        disabled={tokenSettingsButtonDisabled}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header text-xl">
                      Subscription Agreement
                    </h6>

                    {!filePath && (
                      <div>Issuer has not uploaded subscription agreement.</div>
                    )}

                    {filePath && (
                      <a
                        href={filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Agreement
                      </a>
                    )}
                    <div className="mt-3"></div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-default text-default-color">
                          Note:
                        </label>
                        <textarea
                          className="form-control"
                          placeholder={`Please Enter Your Note`}
                          name="subscriptionAgreementNote"
                          onChange={this.onchange}
                          rows={4}
                          value={subscriptionAgreementNote}
                        ></textarea>
                      </div>
                    </div>

                    {filePath && (
                      <div className="form-buttons-w custom-submit-button2 mt-0 border-top-none m-btn-center d-flex justify-content-end">
                        <button
                          className="btn default-border-btn"
                          type="submit"
                          onClick={(e) => this.onRejectAgreement(e)}
                        >
                          Reject
                        </button>
                        <button
                          className="btn default-btn"
                          type="submit"
                          onClick={(e) => this.saveSubscriptionAgreement(e)}
                        >
                          Accept{" "}
                          {subscriptionLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TokenSettings;
