import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  ethAddressDisplay,
} from "../../utils/Util";
import Api from "../../services/api";
import AddTransactionModal from "./AddTransactionModal";
import validators from "../../validators";
import _ from "lodash";
import browseFileImg from "../../assets/img/browse_file.png?v1";
import GetImage from "../GetImage/GetImage";

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      fullname: "",
      email: "",
      api: new Api(),
      manualContributed: 0,
      ethContributed: 0,
      btcContributed: 0,
      ltcContributed: 0,
      usdcContributed: 0,
      isKycVerified: "",
      isEmailVerified: "",
      phoneNumber: "N/A",
      role: "",
      page: 1,
      sizePerPage: 10,
      filters: "",
      emailStatus: "",
      userRole: "",
      kycStatus: "",
      loading: false,
      buttonLoading: false,
      transactionModalOpen: false,
      transactionAmount: 0,
      tokenId: "",
      tokens: "",
      receivingEthOriginalAddress: "",
      receivingEthAddress: "",
      incomeStatementData: [],
    };
    this.validators = validators;
  }

  getUserDetails = async () => {
    try {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const userId = this.props.match.params.id;
      const response = await api
        .setToken(authenticationToken)
        .get("user/get/user/details", { userId: userId });
      if (response.code === 200 && response.data && response.data.user) {
        this.setState(
          {
            fullName: response.data.user.fullName,
            email: response.data.user.email,
            userRole: response.data.user && response.data.user.role,
            manualContributed: response.data.user.manualContributed,
            ethContributed: response.data.user.ethContributed,
            btcContributed: response.data.user.btcContributed,
            ltcContributed: response.data.user.ltcContributed,
            usdcContributed: response.data.user.usdcContributed,
            isKycVerified: response.data.user.isKycVerified,
            isEmailVerified: response.data.user.isEmailVerified,
            phoneNumber: response.data.user.phoneNumber,
            depositeAddress: response.data.user._id,
            identificationDataFilled:
              response.data.user.identificationDataFilled,
            fatcaCrsDataFilled: response.data.user.fatcaCrsDataFilled,
            tokens: response.data.user.tokens,
            receivingEthOriginalAddress:
              response.data.user &&
              response.data.user.receivingEthOriginalAddress
                ? response.data.user.receivingEthOriginalAddress
                : "",
            receivingEthAddress:
              response.data.user && response.data.user.receivingEthAddress
                ? response.data.user.receivingEthAddress
                : "N/A",
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  async componentWillMount() {
    try {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("display");
      }
      this.getMyTokenList();
      this.getUserDetails();
      this.getKycDetails();
      this.getRecords();

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  refreshCurrenttable = () => {
    this.getRecords();
  };

  getKycDetails = async () => {
    const authenticationToken = this.props.authToken;
    const response = await this.state.api
      .setToken(authenticationToken)
      .get("user/kyc/detail", { detailId: this.props.location.state });
    if (response.code === 200 && response.data && response.data.kycDetail) {
      this.setState({
        userId: response.data.kycDetail.userId,
        kycfullName: response.data.kycDetail.fullName,
        kycemail: response.data.kycDetail.email,
        contactNo: response.data.kycDetail.contactNo,
        dob: response.data.kycDetail.dob,
        countryName: response.data.kycDetail.countryId.name,
        state: response.data.kycDetail.state,
        city: response.data.kycDetail.city,
        postalCode: response.data.kycDetail.postalCode,
        kycStatus: response.data.kycDetail.status,
        kycDocumentTypeListTypes: response.data.kycDocumentTypeListTypes,
        kycFiles: response.data.kycFiles,
        objectIdRef: response.data.objectIdRef,
        grossAnnualIncome: response.data.kycDetail.grossAnnualIncome,
        investmentTimeFrame: response.data.kycDetail.investmentTimeFrame,
        insiderOrReportingIssuer:
          response.data.kycDetail.insiderOrReportingIssuer,
        representativeName: `${response.data.kycDetail.representativeFirstName} ${response.data.kycDetail.representativeLastName}`,
        investmentKnowledge: response.data.kycDetail.investmentKnowledge,
        investmentObjective: response.data.kycDetail.investmentObjective,
      });
    }
  };
  updateValidators = (fieldName, value) => {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  };

  onchange = async (event) => {
    try {
      const { tokenId } = this.state;
      const authenticationToken = this.props.authToken;
      const eventTargetName = event.target.name;
      const eventTargetValue = event.target.value;
      this.setState({
        [event.target.name]: event.target.value,
      });
      if (
        event.target.name === "tokenId" &&
        event.target.value === "Please select token"
      ) {
        this.setState({ tokenId: "" });
      }
      if (
        event.target.name === "transactionAmount" ||
        event.target.name === "tokens"
      ) {
        const api = new Api();
        const tokenResponse = await api
          .setToken(authenticationToken)
          .get("user/issuer/token/price", {
            tokenId,
          });
        if (eventTargetName === "transactionAmount") {
          const tokens = eventTargetValue / tokenResponse.data.tokenPrice;
          if (tokens) {
            this.setState({
              tokens,
            });
          }
        } else if (eventTargetName === "tokens") {
          const transactionAmount =
            eventTargetValue * tokenResponse.data.tokenPrice;
          if (transactionAmount) {
            this.setState({
              transactionAmount,
            });
          }
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  getRecords = async () => {
    const api = new Api();
    const { sizePerPage, page, txHash, depositeAddress } = this.state;
    const { email } = this.props.authUserInfo;
    const authenticationToken = this.props.authToken;
    const userId = this.props.match.params.id;
    this.setState({ loading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("user/token/transactions/", {
        sizePerPage: sizePerPage,
        page: page,
        txHash: txHash,
        depositeAddress: depositeAddress,
        userId: userId,
        dealerEmail: email
      });
    if (response.code === 200) {
      this.setState({
        renderFlag: true,
        loading: false,
        data: response.data.transactions,
        totalSize: response.data.totalTransactions,
      });
    }
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
      }
    }
  };

  showTransactionModal = () => {
    this.setState({
      transactionModalOpen: true,
      tokenId: "",
      transactionAmount: 0,
      tokens: "",
    });

    if (!this.state.myTokensList || this.state.myTokensList.length === 0) {
      toast.error("There are no registered tokens at this time");
    }
  };

  onCloseModal = () => {
    this.setState({
      transactionModalOpen: false,
      tokenId: "",
      transactionAmount: 0,
      tokens: "",
    });
  };

  isFormValid = () => {
    let status = true;
    const validationFields = ["transactionAmount"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  };

  AddTransactionRequest = async () => {
    const authenticationToken = this.props.authToken;
    const { tokenId, transactionAmount } = this.state;
    const isFormValid = this.isFormValid();
    if (tokenId && transactionAmount && transactionAmount > 0 && isFormValid) {
      let userId = this.props.match.params.id;
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/manual/transaction/add", {
          tokenId,
          userId: userId,
          transactionAmount: transactionAmount,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        this.setState({
          transactionAmount: 0,
          userNote: "",
          tokenId: "",
        });
        this.getRecords();
        toast.success(response.message);
        this.onCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      const msg =
        !isFormValid && !tokenId && !transactionAmount
          ? "Please enter valid details for required fields."
          : tokenId !== "Please select token" && transactionAmount <= 0
          ? "Transaction Amount is invalid"
          : "Please Select Token";
      toast.error(msg);
    }
  };

  getMyTokenList = async () => {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/my/tokens/list", {});
      if (response.code === 200) {
        this.setState({
          myTokensList: response.data.tokens,
        });
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  };

  render() {
    const {
      fullName,
      kycfullName,
      email,
      kycemail,
      isKycVerified,
      phoneNumber,
      data,
      incomeStatementData,
      sizePerPage,
      page,
      renderFlag,
      loading,
      isEmailVerified,
      identificationDataFilled,
      fatcaCrsDataFilled,
      receivingEthOriginalAddress,
      receivingEthAddress,
      userRole,
      contactNo,
      dob,
      countryName,
      state,
      city,
      postalCode,
      kycStatus,
      note,
      kycDocumentTypeListTypes,
      kycFiles,
      objectIdRef,
      grossAnnualIncome,
      investmentTimeFrame,
      insiderOrReportingIssuer,
      representativeName,
      investmentKnowledge,
      investmentObjective,
    } = this.state;
    const showInvestordetails =
      userRole === "i" || userRole === "ci" ? true : false;
    const extension = ["png", "jpg", "jpeg", "bmp"];
    let displayDocumentTypes = "";
    if (!_.isEmpty(kycDocumentTypeListTypes)) {
      displayDocumentTypes = kycDocumentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div className="element-box" key={mainIndex}>
            <h5
              className={
                parentDocumentType.children.length > 1
                  ? "text-center"
                  : "d-none hide"
              }
            >
              <span className="document-type-header">
                {parentDocumentType.label}
              </span>
            </h5>
            <div className="row">
              <div className="col-md-12">
                {parentDocumentType.children.map((documentType, index) => (
                  <div
                    className={
                      parentDocumentType.children.length > 1 ? "mt-4" : ""
                    }
                    key={index}
                  >
                    <h6 className="element-header">
                      <span className="text-xl">{`${documentType.label} ${
                        _.isEmpty(objectIdRef[documentType._id]) ? ": N/A" : ""
                      }`}</span>
                    </h6>
                    <div className="row">
                      {!_.isEmpty(objectIdRef[documentType._id]) &&
                        objectIdRef[documentType._id].map(
                          (image, imageIndex) => (
                            <div
                              className="col-md-6 mt-4 mobile-center-img"
                              key={imageIndex}
                            >
                              <GetImage
                                string={kycFiles[image]}
                                docLabel={documentType.label}
                                browseFileImg={browseFileImg}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      );
    }

    let emailStatuslabelClassName = "badge-primary";
    let emailLabel = isEmailVerified === true ? "Verified" : "Not Verified";

    let kycStatuslabelClassName = "badge-primary";
    let kycLabel = isKycVerified === true ? "Verified" : "Not Verified";
    let phoneNumberDisplay =
      phoneNumber !== "" && phoneNumber !== null ? phoneNumber : "N/A";
    let userId = this.props.match.params.id;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "txnId",
        text: "Txn Id",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
    ];

    if (userRole === "s") {
      columns.push(
        {
          headerClasses: "text-bold",
          dataField: "userId",
          text: "User Name",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{cell && cell.fullName}</div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "userId",
          text: "Email",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{cell && cell.email}</div>
              </div>
            );
          },
        }
      );
    }

    columns.push(
      {
        headerClasses: "text-bold",
        dataField: "issuerId",
        text: "Token Symbol",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell && cell.listedTokenSymbol}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "CreatedOnUTC",
        text: "Transaction Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div> {moment(cell).format("YYYY-MM-DD HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Amount Sent",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amountRecieve,
                  row.transactionType
                )}
              >
                {niceNumberDecimalDisplay(cell, 4)} {row.transactionType}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "coins",
        text: `Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {niceNumberDecimalDisplay(cell, 2)}{" "}
              {row.issuerId.listedTokenSymbol}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "usdAmount",
        text: "Contributed Amount",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let usdAmount =
            row.transactionType !== "MANUAL" && row.transactionType !== "CARD"
              ? parseFloat(row.amountRecieve) * parseFloat(row.cryptoLiveRate)
              : row.amountRecieve;
          return <div>${niceNumberDecimalDisplay(usdAmount, 2)}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "depositeAddress",
        text: "Deposit Address",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.transactionType !== "MANUAL" && (
                <span className="custom-tooltip" tooltip-title={cell}>
                  {ethAddressDisplay(cell)}
                </span>
              )}
              {row.transactionType === "MANUAL" && <span>N/A</span>}
              {row.transactionType === "CARD" && <span>N/A</span>}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        headerAlign: 'center',
        dataField: "detail",
        text: "Detail",
        style: {
          minWidth: 200
        },
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.paymentFileUrl && (
                <div>
                  <a href={row.paymentFileUrl} target="_blank">
                    View Payment Proof
                  </a>
                </div>
              )}
              {row.subscriptionAgreementFileUrl && (
                <div>
                  <a href={row.subscriptionAgreementFileUrl} target="_blank">
                    View Subscription Agreement
                  </a>
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.status === 1) {
            label = "Completed";
            className = "badge-success";
          } else if (row.status === 2) {
            label = "Rejected";
            className = "badge-danger";
          }
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <span className={"badge"}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      }
    );

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={loading}
          data={data}
          columns={columns}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box o-auto">
                <div>
                  <h6 className="element-header flex-header">
                    <span className="text-xl">User Details</span>
                    <span className="d-flex mgtp-sm-3">
                      <div>
                        {identificationDataFilled && (
                          <span>
                            <Link
                              to={"/user_identificaiton_detail/" + userId}
                              className="color-white"
                            >
                              <button
                                className="mr-2 mb-2 btn default-border-btn"
                                type="button"
                              >
                                {" "}
                                Identification Details
                              </button>
                            </Link>
                            <Link
                              to={"/identificaiton_details_pdf/" + userId}
                              className="color-white"
                            >
                              <button
                                className="mr-2 mb-2 btn default-border-btn"
                                type="button"
                              >
                                PDF
                              </button>
                            </Link>
                          </span>
                        )}
                        {fatcaCrsDataFilled && (
                          <Link
                            to={"/user_fatca_crs_detail/" + userId}
                            className="color-white"
                          >
                            <button
                              className="mr-2 mb-2 btn default-border-btn"
                              type="button"
                            >
                              {" "}
                              FATCA Details
                            </button>
                          </Link>
                        )}
                      </div>
                      <Link
                        to="/users"
                        className="pull-right"
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#395cbe",
                        }}
                      >
                        Back to users list
                      </Link>
                    </span>
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Full Name :</span> {fullName}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Email : </span> {email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Email Verified :</span>{" "}
                        <span className={"badge " + emailStatuslabelClassName}>
                          {emailLabel}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Kyc Verified :</span>{" "}
                        <span className={"badge " + kycStatuslabelClassName}>
                          {kycLabel}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Contact No : </span> {phoneNumberDisplay}
                      </div>
                    </div>
                    <div className="col-md-6 wallet-address mt-xs-3">
                      <span className="text-default text-default-color">
                        Whitelisted wallet address :
                      </span>{" "}
                      <span className="text-default text-default-color">
                        {receivingEthOriginalAddress &&
                        receivingEthOriginalAddress !== ""
                          ? receivingEthOriginalAddress
                          : receivingEthAddress}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Identification details submitted:</span>{" "}
                        {identificationDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Fatca/CRS details submitted:</span>{" "}
                        {fatcaCrsDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3"></div>
                </div>
              </div>
              <div className="element-box o-auto">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span className="text-xl">Kyc Details</span>
                    </div>
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Full Name : </span> {kycfullName || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Email : </span> {kycemail || "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Contact No : </span>{" "}
                        {contactNo ? contactNo : "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>City : </span> {city || "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      {
                        <div className="text-default text-default-color">
                          <span>State/Province : </span>
                          {state || "N/A"}
                        </div>
                      }
                    </div>
                    <div className="col-md-6 mt-xs-3">
                      <div className="text-default text-default-color">
                        <span>Country : </span> {countryName || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Postal Code : </span> {postalCode || "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-default text-default-color">
                        <span>Insider Status : </span>{" "}
                        {insiderOrReportingIssuer || "N/A"}
                      </div>
                    </div>
                  </div>
                  {showInvestordetails && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="text-default text-default-color">
                          <span>Gross Annual Income : </span>{" "}
                          {grossAnnualIncome || "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 mt-xs-3">
                        <div className="text-default text-default-color">
                          <span>Investment Time Frame :</span>{" "}
                          <span>{investmentTimeFrame || "N/A"}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {showInvestordetails && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="text-default text-default-color">
                          <span>Investment Knowledge : </span>
                          {investmentKnowledge || "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 mt-xs-3">
                        <div className="text-default text-default-color">
                          <span>Investment Objective : </span>{" "}
                          <span>{investmentObjective || "N/A"}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showInvestordetails && (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="text-default text-default-color">
                          <span>Representative Name :</span>{" "}
                          <span>
                            {!!representativeName &&
                            representativeName !== "undefined undefined"
                              ? representativeName
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {displayDocumentTypes}

              <div className="element-box mt-4 o-auto">
                <div>
                  <h6 className="element-header flex-header">
                    <span className="text-xl">Transactions</span>
                    <span className="mgtp-sm-3">
                      <button
                        className="btn default-btn"
                        type="button"
                        onClick={() => this.refreshCurrenttable()}
                      >
                        Refresh
                      </button>
                      {(userRole !== "s") && <button
                        className="btn default-btn ml-2"
                        type="button"
                        onClick={() => this.showTransactionModal()}
                      >
                        Add Transaction
                      </button>}
                    </span>
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        {renderFlag === true && (
                          <RemoteAll
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={this.state.totalSize}
                            onTableChange={this.handleTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="element-box mt-4 o-auto">
                <div>
                  <h6 className="element-header d-flex justify-content-between">
                    <span className="text-xl">Income Statement</span>
                    <button
                      className="mr-2 mb-2 btn default-btn"
                      type="button"
                      onClick={() => this.refreshCurrenttable()}
                    >
                      Refresh
                    </button>
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        {renderFlag === true && (
                          <RemoteAll
                            data={incomeStatementData}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={this.state.totalSize}
                            onTableChange={this.handleTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddTransactionModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          AddTransactionRequest={this.AddTransactionRequest}
          isFormValid={this.isFormValid}
          displayValidationErrors={this.displayValidationErrors}
          fullName={fullName}
        />
      </div>
    );
  }
}
export default UserDetail;
