import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";

const listingStatusDetails = {
  PENDING: {
    text: "Pending",
    value: "PENDING",
    classname: "danger",
  },
  PROCESSING: {
    text: "Processing",
    value: "PROCESSING",
    classname: "info",
  },
  APPROVED: {
    text: "Approved",
    value: "APPROVED",
    classname: "success",
  },
  REJECTED: {
    text: "Rejected",
    value: "REJECTED",
    classname: "danger",
  },
};

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.LISTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    let issuerId = this.props.match.params.id;
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/listings", {
          issuerId,
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.campaigns,
          totalSize: response.data.totalCampaigns,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag } = this.state;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "listingId",
        text: "#ID",
      },
      {
        headerClasses: "text-bold",
        dataField: "location",
        text: "Location",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var location = cell !== "" && cell !== null ? cell : "N/A";
          return <div>{location}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "dailyProductionMcf",
        text: "DAILY PRODUCTION (MCF)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return <div className="text-center">{cell}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "estimatedPoweCapacity",
        text: "ESTIMATED POWER CAPACITY (KWH)",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-center">
              {cell}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          const currentStatusDetails = listingStatusDetails[cell];
          return (
            <div>
              <div>
                <span
                  className={"badge badge-" + currentStatusDetails.classname}
                >
                  {currentStatusDetails.value}
                </span>
              </div>
            </div>
          );
        },
      },

      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var date = moment(cell).format("DD/MM/YYYY HH:mm");
          return <div>{date}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let redirectLink = "/campaign_detail/" + row._id;
          return (
            <div>
              <div className="d-inline-block">
                <Link to={redirectLink} className="color-white">
                  <button
                    className="mr-2 mb-2 btn default-border-btn"
                    type="button"
                  >
                    Details
                  </button>
                </Link>
                {row.status === "APPROVED" && (
                  <Link
                    to={`/progress_reports/${row._id}`}
                    className="color-white">
                    <button className="mr-2 mb-2 btn btn-info" type="button">
                      Progress Reports
                    </button>
                  </Link>
                )}
              </div>
            </div>
          );
        },
      },
    ];
    // const columns = [
    //   {
    //     headerClasses: "text-bold",
    //     style: { "white-space": "nowrap" },
    //     dataField: "listingId",
    //     text: "#ID",
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "location",
    //     text: "Location",
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       var location = cell !== "" && cell !== null ? cell : "N/A";
    //       return <div>{location}</div>;
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "averageDailyProductionInBBL",
    //     text: "Average Daily Production In BBL",
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       return <div className="text-center">{cell}</div>;
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "averageDailyProductionInBOE",
    //     text: "Average Daily Production In BOE",
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       return (
    //         <div className="text-center">
    //           {cell} {row.totalProbablePossibleReservesUnit}
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "status",
    //     text: "Status",
    //     sort: true,
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       const currentStatusDetails = listingStatusDetails[cell];
    //       return (
    //         <div>
    //           <div>
    //             <span className={"badge"}>{currentStatusDetails.value}</span>
    //           </div>
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "createdOnUTC",
    //     text: "Date",
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       var date = moment(cell).format("DD/MM/YYYY HH:mm");
    //       return <div>{date}</div>;
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "operations",
    //     text: "Operations",
    //     isDummyField: true,
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       let redirectLink = "/campaign_detail/" + row._id;
    //       return (
    //         <div>
    //           <div className="d-inline-block">
    //             <Link to={redirectLink} className="color-white">
    //               <button
    //                 className="mr-2 mb-2 btn default-border-btn"
    //                 type="button"
    //               >
    //                 Details
    //               </button>
    //             </Link>
    //             {row.status === "APPROVED" && (
    //               <Link
    //                 to={`/progress_reports/${row._id}`}
    //                 className="color-white">
    //                 <button className="mr-2 mb-2 btn btn-info" type="button">
    //                   Progress Reports
    //                 </button>
    //               </Link>
    //             )}
    //           </div>
    //         </div>
    //       );
    //     },
    //   },
    // ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive campaignTable">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="element-box" style={{overflow: 'auto'}}>
                <h5 className="form-header">
                  <span className="pull-left">
                    <span>Campaigns</span>
                  </span>
                  <Link
                    to="/users"
                    className="pull-right"
                    style={{
                      marginTop: "4px",
                      fontSize: "14px",
                      color: "#395cbe",
                    }}
                  >
                    Back to users list
                  </Link>
                </h5>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Campaigns;
