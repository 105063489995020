import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";
import _ from "lodash";

class AddVerifierModal extends Component {
  render() {
    const {
      verifierModalOpen,
      onCloseModal,
      onInputValueChange,
      AddVerifierRequest,
      buttonLoading,
      verificationEvents,
      handleEventsChange,
      verifierDetails,
      fullName,
      email,
      phoneNumber,
      selectedOption,
    } = this.props;
    let buttonDisabled = buttonLoading === true ? true : false;
    let eventList = [];
    verificationEvents.forEach((value) => {
      let obj = { value: value._id, label: value.eventTitle };
      eventList.push(obj);
    });

    return (
      <Modal
        open={verifierModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content verifier-modal">
                    <h4 className="onboarding-title">
                      {" "}
                      {verifierDetails && !_.isEmpty(verifierDetails)
                        ? "Edit"
                        : "Add"}{" "}
                      Verifier{" "}
                    </h4>
                    <form>
                      <div className="form-group">
                        <label
                          htmlFor="fullName"
                          className="text-default-color"
                        >
                          Full Name<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="fullName"
                          placeholder="Enter Full Name"
                          type="text"
                          value={
                            verifierDetails && verifierDetails.fullName
                              ? verifierDetails.fullName
                              : fullName
                          }
                          name="fullName"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email" className="text-default-color">
                          Email<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          placeholder="Enter Email"
                          type="text"
                          value={
                            verifierDetails && verifierDetails.email
                              ? verifierDetails.email
                              : email
                          }
                          name="email"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="phoneNumber"
                          className="text-default-color"
                        >
                          Phone Number<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Enter Phone Number"
                          type="text"
                          value={
                            verifierDetails && verifierDetails.phoneNumber
                              ? verifierDetails.phoneNumber
                              : phoneNumber
                          }
                          name="phoneNumber"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="text-default-color">
                          Events<span className="required-field">*</span>
                        </label>
                        <br />
                        <Select
                          value={selectedOption}
                          options={eventList}
                          placeholder="Select Events"
                          isMulti={true}
                          onChange={handleEventsChange}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn default-border-btn"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn default-btn ml-2"
                          type="button"
                          onClick={AddVerifierRequest}
                          disabled={buttonDisabled}
                        >
                          Submit
                          {buttonLoading && (
                            <i className="fa-spin fa fa-spinner text-white ml-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddVerifierModal;
