import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

class IndividualFatca extends React.Component {
  constructor(props) {
    super(props);
    let fatcaData = this.props.fatcaData;
    this.state = {
      fatcaData: fatcaData,
    };
  }

  render() {
    const { fatcaData } = this.state;
    var birthDate = !_.isUndefined(fatcaData.birthDate)
      ? moment(fatcaData.birthDate).format("DD MMMM YYYY")
      : "";
    var date = !_.isUndefined(fatcaData.date)
      ? moment(fatcaData.date).format("DD MMMM YYYY")
      : "";
    return (
      <div className="identification-data-container">
        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Subscriber identification details</span>
                <Link to="/users" className="pull-right">
                  Back to users list
                </Link>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>Investor Name :</span>{" "}
                  {!_.isUndefined(fatcaData.investorName) &&
                    fatcaData.investorName
                    ? fatcaData.investorName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  <span>
                    Town or City of Birth :
                  </span>{" "}
                  {!_.isUndefined(fatcaData.birthCity) && fatcaData.birthCity
                    ? fatcaData.birthCity
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  Country of Birth :{" "}
                  {!_.isUndefined(fatcaData.birthCountry) &&
                    fatcaData.birthCountry.name
                    ? fatcaData.birthCountry.name
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">Date of Birth : {birthDate ? birthDate : "N/A"}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Contact Details </span>
              </div>
            </h6>

            <h3>Current Residential Address</h3>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  Building name :{" "}
                  {!_.isUndefined(fatcaData.residentialBuildingName) &&
                    fatcaData.residentialBuildingName
                    ? fatcaData.residentialBuildingName
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  Street :{" "}
                  {!_.isUndefined(fatcaData.residentialStreet) &&
                    fatcaData.residentialStreet
                    ? fatcaData.residentialStreet
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  City/Town :{" "}
                  {!_.isUndefined(fatcaData.residentialCity) &&
                    fatcaData.residentialCity
                    ? fatcaData.residentialCity
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  State :{" "}
                  {!_.isUndefined(fatcaData.residentialState) &&
                    fatcaData.residentialState
                    ? fatcaData.residentialState
                    : "N/A"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  Country :{" "}
                  {!_.isUndefined(fatcaData.residentialCountry) &&
                    fatcaData.residentialCountry.name
                    ? fatcaData.residentialCountry.name
                    : "N/A"}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  Postal Code :{" "}
                  {!_.isUndefined(fatcaData.residentialPostalCode) &&
                    fatcaData.residentialPostalCode
                    ? fatcaData.residentialPostalCode
                    : "N/A"}
                </div>
              </div>
            </div>

            <h3 className="mt-3">Mailing address</h3>
            {fatcaData.mailingAddressSame === "y" && (
              <h6 className="text-default font-400">Mailing address is same as residential address.</h6>
            )}

            {fatcaData.mailingAddressSame !== "y" && (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Building name :{" "}
                      {!_.isUndefined(fatcaData.mailingAddressBuildingName) &&
                        fatcaData.mailingAddressBuildingName
                        ? fatcaData.mailingAddressBuildingName
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Street :{" "}
                      {!_.isUndefined(fatcaData.mailingAddressStreet) &&
                        fatcaData.mailingAddressStreet
                        ? fatcaData.mailingAddressStreet
                        : "N/A"}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      City/Town :{" "}
                      {!_.isUndefined(fatcaData.mailingAddressCity) &&
                        fatcaData.mailingAddressCity
                        ? fatcaData.mailingAddressCity
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      State :{" "}
                      {!_.isUndefined(fatcaData.mailingAddressState) &&
                        fatcaData.mailingAddressState
                        ? fatcaData.mailingAddressState
                        : "N/A"}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Country :{" "}
                      {!_.isUndefined(fatcaData.mailingAddressCountry) &&
                        fatcaData.mailingAddressCountry.name
                        ? fatcaData.mailingAddressCountry.name
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Postal Code :{" "}
                      {!_.isUndefined(fatcaData.mailingAddesssPostalCode) &&
                        fatcaData.mailingAddesssPostalCode
                        ? fatcaData.mailingAddesssPostalCode
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">
                  FATCA Declaration of U.S. Citizenship or U.S. Residence for
                  Tax purposes
                </span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-12">
                <div className="text-default text-default-color">
                  Is subscriber an U.S. citizen and/or resident in the U.S. for
                  tax purposes? :{" "}
                  {!_.isUndefined(fatcaData.specifiedUsPerson) &&
                    fatcaData.specifiedUsPerson === "y"
                    ? "Yes"
                    : "No"}
                </div>
              </div>

              {fatcaData.specifiedUsPerson === "y" &&
                fatcaData.usTinNo !== "" && (
                  <div className="col-md-12">
                    U.S. TIN :{" "}
                    {!_.isUndefined(fatcaData.usTinNo) && fatcaData.usTinNo
                      ? fatcaData.usTinNo
                      : "N/A"}
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">CRS Declaration of Tax Residency</span>
              </div>
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div className="text-default text-default-color">
                  Country of Tax Residency :{" "}
                  {!_.isUndefined(fatcaData.taxResidencyCountry) &&
                    fatcaData.taxResidencyCountry.name
                    ? fatcaData.taxResidencyCountry.name
                    : "N/A"}
                </div>
              </div>

              <div className="col-md-6 text-default text-default-color">
                Taxpayer Identification Number :{" "}
                {!_.isUndefined(fatcaData.taxPayerIdentificationNo) &&
                  fatcaData.taxPayerIdentificationNo
                  ? fatcaData.taxPayerIdentificationNo
                  : "N/A"}
              </div>
            </div>

            {(!_.isUndefined(fatcaData.taxResidencyCountry2) ||
              !_.isUndefined(fatcaData.taxPayerIdentificationNo2)) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Country of Tax Residency :{" "}
                      {!_.isUndefined(fatcaData.taxResidencyCountry2) &&
                        fatcaData.taxResidencyCountry2.name
                        ? fatcaData.taxResidencyCountry2.name
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6 text-default text-default-color">
                    Taxpayer Identification Number :{" "}
                    {!_.isUndefined(fatcaData.taxPayerIdentificationNo2) &&
                      fatcaData.taxPayerIdentificationNo2
                      ? fatcaData.taxPayerIdentificationNo2
                      : "N/A"}
                  </div>
                </div>
              )}

            {(!_.isUndefined(fatcaData.taxResidencyCountry3) ||
              !_.isUndefined(fatcaData.taxPayerIdentificationNo3)) && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-default text-default-color">
                      Country of Tax Residency :{" "}
                      {!_.isUndefined(fatcaData.taxResidencyCountry3) &&
                        fatcaData.taxResidencyCountry3.name
                        ? fatcaData.taxResidencyCountry3.name
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col-md-6 text-default text-default-color">
                    Taxpayer Identification Number :{" "}
                    {!_.isUndefined(fatcaData.taxPayerIdentificationNo3) &&
                      fatcaData.taxPayerIdentificationNo3
                      ? fatcaData.taxPayerIdentificationNo3
                      : "N/A"}
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Type of Controlling Person</span>
              </div>
            </h6>
          </div>

          {!_.isUndefined(fatcaData.legalPersonOwnership) &&
            fatcaData.legalPersonOwnership === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal person – control by ownership
                    :{" "}
                    {!_.isUndefined(fatcaData.legalPersonOwnership) &&
                      fatcaData.legalPersonOwnership === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.legalPersonOwnerEntityName) &&
                    fatcaData.legalPersonOwnerEntityName
                    ? fatcaData.legalPersonOwnerEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalPersonOther) &&
            fatcaData.legalPersonOther === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal person – control by other
                    means :{" "}
                    {!_.isUndefined(fatcaData.legalPersonOther) &&
                      fatcaData.legalPersonOther === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.legalPersonOtherEntityName) &&
                    fatcaData.legalPersonOtherEntityName
                    ? fatcaData.legalPersonOtherEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalPersonSeniorManagingOfficial) &&
            fatcaData.legalPersonSeniorManagingOfficial === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal person – senior managing
                    official :{" "}
                    {!_.isUndefined(
                      fatcaData.legalPersonSeniorManagingOfficial
                    ) && fatcaData.legalPersonSeniorManagingOfficial === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(
                    fatcaData.legalPersonSeniorManagingOfficialEntityName
                  ) && fatcaData.legalPersonSeniorManagingOfficialEntityName
                    ? fatcaData.legalPersonSeniorManagingOfficialEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.trustSettlor) &&
            fatcaData.trustSettlor === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a trust - settlor :{" "}
                    {!_.isUndefined(fatcaData.trustSettlor) &&
                      fatcaData.trustSettlor === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.trustSettlorEntityName) &&
                    fatcaData.trustSettlorEntityName
                    ? fatcaData.trustSettlorEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.trustTrustee) &&
            fatcaData.trustTrustee === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a trust – trustee :{" "}
                    {!_.isUndefined(fatcaData.trustTrustee) &&
                      fatcaData.trustTrustee === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.trustTrusteeEntityName) &&
                    fatcaData.trustTrusteeEntityName
                    ? fatcaData.trustTrusteeEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.trustProtector) &&
            fatcaData.trustProtector === true && (
              <div className="row">
                <div className="col-md-12">
                  <div>
                    Controlling Person of a trust – protector :{" "}
                    {!_.isUndefined(fatcaData.trustProtector) &&
                      fatcaData.trustProtector === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.trustProtectorEntityName) &&
                    fatcaData.trustProtectorEntityName
                    ? fatcaData.trustProtectorEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.trustBeneficiary) &&
            fatcaData.trustBeneficiary === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a trust – beneficiary :{" "}
                    {!_.isUndefined(fatcaData.trustBeneficiary) &&
                      fatcaData.trustBeneficiary === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.trustBeneficiaryEntityName) &&
                    fatcaData.trustBeneficiaryEntityName
                    ? fatcaData.trustBeneficiaryEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.trustOther) &&
            fatcaData.trustOther === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a trust – other :{" "}
                    {!_.isUndefined(fatcaData.trustOther) &&
                      fatcaData.trustOther === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.trustOtherEntityName) &&
                    fatcaData.trustOtherEntityName
                    ? fatcaData.trustOtherEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalArrangementSettlor) &&
            fatcaData.legalArrangementSettlor === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal arrangement (non-trust) –
                    settlor-equivalent :{" "}
                    {!_.isUndefined(fatcaData.legalArrangementSettlor) &&
                      fatcaData.legalArrangementSettlor === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(
                    fatcaData.legalArrangementSettlorEntityName
                  ) && fatcaData.legalArrangementSettlorEntityName
                    ? fatcaData.legalArrangementSettlorEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalArrangementTrustee) &&
            fatcaData.legalArrangementTrustee === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal arrangement (non-trust) –
                    trustee-equivalent :{" "}
                    {!_.isUndefined(fatcaData.legalArrangementTrustee) &&
                      fatcaData.legalArrangementTrustee === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(
                    fatcaData.legalArrangementTrusteeEntityName
                  ) && fatcaData.legalArrangementTrusteeEntityName
                    ? fatcaData.legalArrangementTrusteeEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalArrangementProtector) &&
            fatcaData.legalArrangementProtector === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal arrangement (non-trust) –
                    protector-equivalent :{" "}
                    {!_.isUndefined(fatcaData.legalArrangementProtector) &&
                      fatcaData.legalArrangementProtector === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(
                    fatcaData.legalArrangementProtectorEntityName
                  ) && fatcaData.legalArrangementProtectorEntityName
                    ? fatcaData.legalArrangementProtectorEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalArrangementBeneficiary) &&
            fatcaData.legalArrangementBeneficiary === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal arrangement (non-trust) –
                    beneficiary-equivalent :{" "}
                    {!_.isUndefined(fatcaData.legalArrangementBeneficiary) &&
                      fatcaData.legalArrangementBeneficiary === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(
                    fatcaData.legalArrangementBeneficiaryEntityName
                  ) && fatcaData.legalArrangementBeneficiaryEntityName
                    ? fatcaData.legalArrangementBeneficiaryEntityName
                    : "N/A"}
                </div>
              </div>
            )}

          {!_.isUndefined(fatcaData.legalArrangementOther) &&
            fatcaData.legalArrangementOther === true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="text-default text-default-color">
                    Controlling Person of a legal arrangement (non-trust) –
                    other-equivalent :{" "}
                    {!_.isUndefined(fatcaData.legalArrangementOther) &&
                      fatcaData.legalArrangementOther === true
                      ? "TRUE"
                      : "FALSE"}
                  </div>
                </div>
                <div className="col-md-12 text-default text-default-color">
                  Entity Name :{" "}
                  {!_.isUndefined(fatcaData.legalArrangementOtherEntityName) &&
                    fatcaData.legalArrangementOtherEntityName
                    ? fatcaData.legalArrangementOtherEntityName
                    : "N/A"}
                </div>
              </div>
            )}
        </div>

        <div className="element-box">
          <div>
            <h6 className="element-header">
              <div>
                <span className="text-xl">Declaration and Undertakings</span>
              </div>
            </h6>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="text-default text-default-color">
                Print Name :{" "}
                {!_.isUndefined(fatcaData.printName)
                  ? fatcaData.printName
                  : "N/A"}
              </div>
            </div>
            <div className="col-md-6 text-default text-default-color">
              Capacity :{" "}
              {!_.isUndefined(fatcaData.capacity) ? fatcaData.capacity : "N/A"}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="text-default text-default-color">Date : {!_.isUndefined(date) ? date : "N/A"}</div>
            </div>
          </div>
        </div>

        {!_.isUndefined(fatcaData.signature) && fatcaData.signature !== "" && (
          <div className="element-box">
            <div>
              <h6 className="element-header">
                <div>
                  <span className="text-xl">Signature</span>
                </div>
              </h6>

              <div className="row">
                <div className="col-md-12">
                  <div>
                    <img
                      src={fatcaData.signature}
                      height={200}
                      className="signature-container-img"
                      alt="Signature"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default IndividualFatca;
