// let backendHost = 'http://localhost:3000';
// let googleMapKeyValue = 'AIzaSyC7DsgoUfC1dO2Heqyl5X36-ARrlYk5i3U';
// let recaptchaSiteKey = '6LdTt5IUAAAAAHr8LILfR5h6e6PDmsqfxYHHed4K';
let tokenReceivingAddressValue = "0x8345a32fed4ddf798386a7C80C921c92cAC88259";
let contractAddressValue = "0xC02f22EF822EBAC7A74122Aa45EE9f1899E46Fe5";
let contractAbiUrlValue = "https://api-rinkeby.etherscan.io/api";
let web3NetworkValue = 4;
let txnLinkValue = "https://rinkeby.etherscan.io/tx/";
let btcExplorerLinkValue = "https://www.blockchain.com/btc/tx";
let smartContractAddress = "0xc02f22ef822ebac7a74122aa45ee9f1899e46fe5";

let backendHost = "http://localhost:3000";
// let backendHost = 'https://api.permianchain.com';
let recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
let googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";

if (process.env.REACT_APP_NODE_ENV === "production") {
    backendHost = "https://masterapi.permianchain.com";
    recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
    tokenReceivingAddressValue = "";
    contractAddressValue = "0x271a01dc2e37fdc4cd7e9d1548bd61c6ab3e90e0";
    contractAbiUrlValue = "https://api.etherscan.io/api";
    web3NetworkValue = 1;
    txnLinkValue = "https://etherscan.io/tx/";
    googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
    smartContractAddress = "";
} else if (process.env.REACT_APP_NODE_ENV === "test") {
    backendHost = "https://api.permianchain.com";
    recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
    tokenReceivingAddressValue = "0x8345a32fed4ddf798386a7C80C921c92cAC88259";
    contractAddressValue = "0xC02f22EF822EBAC7A74122Aa45EE9f1899E46Fe5";
    contractAbiUrlValue = "https://api-rinkeby.etherscan.io/api";
    web3NetworkValue = 4;
    googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
    smartContractAddress = "0xc02f22ef822ebac7a74122aa45ee9f1899e46fe5";
}

export const API_ROOT = `${backendHost}`;
export const RECAPTCHASITEKEY = `${recaptchaSiteKey}`;
export const tokenReceivingAddress = tokenReceivingAddressValue;
export const tokenSymbol = "NRT";
export const contractAddress = contractAddressValue;
export const contractAbiUrl = contractAbiUrlValue;
export const web3Network = web3NetworkValue;
export const txnLink = txnLinkValue;
export const btcExplorerLink = btcExplorerLinkValue;
export const GOOGLE_MAP_KEY = googleMapKeyValue;
export const PERMIAN_SMART_CONTRACT_ADDRESS = smartContractAddress;