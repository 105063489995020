import React, { Component } from "react";
import axios from "axios";
import investorLogoImg from "../../assets/img/logo-white.png";
import { Link } from "react-router-dom";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import { API_ROOT } from "../../services/api-config";

class CustomHeader extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {
      ethUnitUsdPrice: 0,
      btcUnitUsdPrice: 0,
    };
  }

  componentDidMount() {
    this.setCryptoUnitPrice();
  }

  async setCryptoUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }

  render() {
    /* let { isExchange } = this.props; */
    let { ethUnitUsdPrice, btcUnitUsdPrice } = this.state;
    /* let userDisplayName =
      !_.isUndefined(authUserInfo) &&
      authUserInfo !== null &&
      authUserInfo !== ""
        ? authUserInfo.userDisplayName
        : ""; */
    /* let headerClass =
      isExchange && isExchange === true
        ? "top-bar exchange-top-bar"
        : "top-bar custom-top-bar"; */
    let imagePath = investorLogoImg;
    return (
      <div>
        <div className="top-bar custom-top-bar">
          <div>
            <Link to="/">
              <img
                className="customLogo"
                src={imagePath}
                height={70}
                alt="Investor"
              />
            </Link>
          </div>

          <span className="text-white text-center mr-4">
            ETH/USD : ${niceNumberDecimalDisplay(ethUnitUsdPrice)} | BTC/USD : $
            {niceNumberDecimalDisplay(btcUnitUsdPrice)}{" "}
          </span>
        </div>
      </div>
    );
  }
}
export default CustomHeader;
