import React, { Component } from 'react';
import Modal from "react-responsive-modal";
class TwoFaValidateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};        
    }

    render() {
        const { twoFaModalOpen, onCloseModal, onchange, twoFaAuthenticationCheck } = this.props;
        /*let buttonDisabled = (paymentRequestButtonLoading === true) ? true : false;*/
        return (
            <Modal open={twoFaModalOpen} onClose={onCloseModal} center classNames={{closeIcon: 'pull-right cursor-pointer','modal':'instructon-modal-container'}}>
                <div className="onboarding-modal">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onCloseModal}>
                          <span className="os-icon os-icon-close"></span>
                        </button>
                        <div className="onboarding-slider-w">
                          <div className="onboarding-slide">
                            <div className="onboarding-content">
                              <h4 className="onboarding-title text-center">
                                Two factor validate Key
                              </h4>

                              <div className="text-left">
                              <ul className="features-list">
                                <li>
                                  Two-factor authentication adds an additional layer of security to the authentication process by making it harder for attackers to gain access to your account.
                                </li>
                                <li>
                                  Two-factor authentication has long been used to control access to sensitive data.
                                </li>
                              </ul>
                              </div>
                              <form>
                                
                                <div className="form-group">
                                  <label>Two factor validate code<span className="required-field">*</span></label>
                                  <input className="form-control" placeholder="Enter two factor code" type="text" name="twoFAcode" id="twoFAcode" onChange={onchange} />
                                </div>

                                <div>
                                  <button className="btn btn-primary permian-button" type="button" onClick={twoFaAuthenticationCheck}> Submit</button>
                                </div>
                              </form>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                </div>
            </Modal>
        );
    }
}
export default TwoFaValidateModal;