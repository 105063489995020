import React from "react";
import { Link } from "react-router-dom";
import validators from "../../validators";
import logoImg from "../../assets/img/logo-white.png?v4";
import ReCAPTCHA from "react-google-recaptcha";
import Api from "../../services/api";
import { toast } from "react-toastify";
import { RECAPTCHASITEKEY } from '../../services/api-config';
import * as messageConstants from "../../utils/Messages";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      buttonLoading: false
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(
      this
    );
  }

  async componentWillMount() {
    document.title = messageConstants.RESET_PASSWORD_PAGE_TITLE + messageConstants.PAGE_TITLE_SEPERATOR + messageConstants.PERMIAN_LABEL;
    if (typeof (this.props.pageProgress) === 'function') {
      this.props.pageProgress('display');
    }
    try {
      const api = new Api();
      let resetCode = this.props.match.params.id;
      const response = await api.setToken(resetCode).create("user/verifyResetPasswordCode");
      if (response.code !== 200) {
        this.props.history.push("/signin");
        toast.error(response.message);
      }
      else {
        if (typeof (this.props.pageProgress) === 'function') {
          this.props.pageProgress('remove');
        }
      }
    }
    catch (e) {
      toast.error(messageConstants.SOMETHING_WENT_WRONG);
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach(rule => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["password", "confirmPassword"];
    validationFields.forEach(field => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async resetPassword(event) {
    event.preventDefault();
    const { password, confirmPassword, recaptchaResponse } = this.state;
    let resetCode = this.props.match.params.id;
    const isFormValid = this.isFormValid();
    if (isFormValid && password !== "" && password.length >= 6 && confirmPassword !== '' && confirmPassword.length >= 6 && password === confirmPassword) {
      this.setState({
        buttonLoading: true
      });
      const api = new Api();
      const response = await api.setToken(resetCode).create("user/resetPassword", {
        password: password,
        confirmPassword: confirmPassword,
        recaptchaResponse: recaptchaResponse
      });
      if (response) {
        this.setState({
          buttonLoading: false
        });
      }

      if (response.code === 200) {
        this.props.history.push("/signin");
        toast.success(response.message);
      } else {
        toast.error(response.message);
        this.recaptcha.reset();
      }
    } 
    else 
    {
      if (!isFormValid) {
        toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      }
      else {
        let msg = (password !== confirmPassword) ? 'Password and confirm password should be same.' : 'Please enter valid password.';
        toast.error(msg);
      }
      this.recaptcha.reset();
    }
  }

  render() {
    const { password, confirmPassword, buttonLoading } = this.state;
    let buttonDisabled = (buttonLoading === true) ? true : false;
    const isFormValid = this.isFormValid();
    return (
      <div className="login-container">
        <div className="auth-box-w login-box centerofscreen">
          <div className="logo-w">
            <Link to="/"><img alt="Logo" src={logoImg} width="230" /></Link>
          </div>
          <form action="" className="custom-form">
            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-key" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Password*"
                  type="password"
                  name="password"
                  onChange={this.onchange}
                  value={password}
                  id="password"
                />
              </div>
              {password && this.displayValidationErrors("password")}
            </div>
            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-key" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Confirm Password*"
                  type="password"
                  name="confirmPassword"
                  onChange={this.onchange}
                  value={confirmPassword}
                  id="confirmPassword"
                />
              </div>
              {confirmPassword && this.displayValidationErrors("confirmPassword")}
            </div>
            <div className="form-check terms-block">
              <ReCAPTCHA
                ref={el => {
                  this.recaptcha = el;
                }}
                sitekey={RECAPTCHASITEKEY}
                onChange={this.handleCaptchaResponseChange}
              />
            </div>
            <div className="pull-right mt-3 info-bottom-link">
              <Link to="/signin" className="color-white">
                Back to Sign In?
              </Link>
            </div>

            <div className="buttons-w mt-3">
              <button
                className={`btn btn-primary custom-submit-button ${isFormValid ? '' : 'disabled'}`}
                onClick={this.resetPassword}
                disabled={buttonDisabled}
              >
                Reset Password
                {buttonLoading && (
                  <i className="fa-spin fa fa-spinner text-white m-1" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default ResetPassword;