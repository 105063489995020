import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { toast } from "react-toastify";
import { whitelistAddress } from "../../utils/Util";
import { CopyToClipboard } from "react-copy-to-clipboard";

class Whitelistings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      filterByType: "",
      loading: false,
      selectedRowIds: [],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onchange = this.onchange.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
    this.approveWhitelisting = this.approveWhitelisting.bind(this);
    this.getSelectedAddresses = this.getSelectedAddresses.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.tokenWhitelisting = this.tokenWhitelisting.bind(this);
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail, filterByType } =
      this.state;
    this.setState({ loading: true });
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/users/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName: filterByName,
          filterByEmail: filterByEmail,
          filterByType: filterByType,
          isWhitelistingRequest: true,
        });
      if (response.code === 200) {
        const usersData = response.data.users.map((item) => {
          const { ercWhitelistedAddress: address } = item;
          return {
            ...item,
            customizedWhitelistedAddress:
              address.substr(0, 4) +
              "..." +
              address.substr(address.length - 4, address.length),
          };
        });
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: usersData,
            totalSize: response.data.totalUsers,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  updateStatus = (status) => {
    this.setState({
      status,
    });
  };

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount() {
    document.title =
      messageConstants.USERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
    this.getMyTokenList();
  }

  async getMyTokenList() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/my/tokens/list", {});
      if (response.code === 200) {
        this.setState({
          myTokensList: response.data.tokens,
        });
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  filterRecords() {
    this.getRecords();
  }

  clearFilterRecords() {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByType: "",
      },
      async () => {
        await this.getRecords();
      }
    );
  }

  copyWhiteListAddress() {
    let msg = messageConstants.WHITELIST_ADDRESS_COPIED;
    toast.success(msg);
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  getSelectedAddresses() {
    const { data, selectedRowIds } = this.state;
    let addresses = [];
    data.map(row => {
      const index = selectedRowIds.findIndex(id => row._id === id);
      if (index > -1 && row.ercWhitelistedAddress) {
        addresses.push(row.ercWhitelistedAddress);
      }
    });
    return addresses;
  }

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({
        selectedRowIds: rows.map(row => row._id),
      });
    } else {
      this.setState({
        selectedRowIds: []
      });
    }
  };

  handleOnSelect = (row, isSelect) => {
    const { selectedRowIds } = this.state;
    if (isSelect) {
      this.setState({
        selectedRowIds: [
          ...selectedRowIds,
          row._id
        ]
      });
    } else {
      this.setState({
        selectedRowIds: selectedRowIds.filter(id => id !== row._id)
      });
    }
  };

  async tokenWhitelisting(tokenName, listedTokenContractAddress) {
    if (tokenName !== "") {
      const selectedEthAddresses = this.getSelectedAddresses();
      if (!selectedEthAddresses || _.isEmpty(selectedEthAddresses)) {
        toast.error("Please select users.");
        return false;
      }
      await window.ethereum.enable();
      whitelistAddress(listedTokenContractAddress, selectedEthAddresses);
    } else {
      toast.error("Please select token to whitelist.");
    }
  }

  async getUserAddress() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("user/whitelisted/address");
    return response && response.ethAddress ? response.ethAddress : "";
  }

  async approveWhitelisting(userDetails = "") {
    const { myTokensList } = this.state;
    if (!userDetails || !userDetails.ercWhitelistedAddress) {
      toast.error("User receiving address not found.");
      return false;
    }
    await window.ethereum.enable();

    if (myTokensList) {
      _.forEach(myTokensList, function (value, key) {
        whitelistAddress(value.listedTokenContractAddress, [
          userDetails.ercWhitelistedAddress,
        ]);
      });
    }
  }

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      loading,
      myTokensList,
    } = this.state;

    const _this = this;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "Name",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "originalEmail",
        text: "Email",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "role",
        text: "Type",
        formatter: function (cell, row) {
          let labelClassName =
            row.role === "i"
              ? "badge-primary"
              : row.role === "s"
              ? "badge-warning"
              : row.role === "m"
              ? "badge-info"
              : "badge-success";
          let label =
            row.role === "i"
              ? "Individual Investor"
              : row.role === "s"
              ? "Issuer"
              : row.role === "m"
              ? "Miner"
              : row.role === "ci"
              ? "Corporate Investor"
              : "";
          return (
            <div className="text-left">
              <div>
                <span className={"badge"}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "kycStatus",
        isDummyField: true,
        text: "KYC Status",
        sort: true,
        formatter: function (cell, row) {
          let statusLabelClass = !_.isUndefined(row.kycStatusLabelClass)
            ? row.kycStatusLabelClass
            : "warning";
          let statusLabel = !_.isUndefined(row.kycStatusLabel)
            ? row.kycStatusLabel
            : "N/A";
          return (
            <div>
              <div>
                <span className={"badge"}>{statusLabel}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "customizedWhitelistedAddress",
        text: "Wallet Address",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div>
              <span>{cell}</span>
              <CopyToClipboard
                text={row.ercWhitelistedAddress}
                onCopy={() => _this.copyWhiteListAddress()}
              >
                <i
                  className="fa fa-clipboard cursor-pointer ml-2"
                  aria-hidden="true"
                ></i>
              </CopyToClipboard>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        isDummyField: true,
        text: "operations",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div>
              <button
                className="btn btn-sm default-border-btn"
                onClick={() => _this.approveWhitelisting(row)}
              >
                Whitelist
              </button>
            </div>
          );
        },
      },
    ];

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      // selected: this.state.selectedEthAddresses,
      selected: this.state.selectedRowIds
    };

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          selectRow={selectRow}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : undefined
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    let tokenListHtml = "";
    if (myTokensList) {
      tokenListHtml = myTokensList.map((tokenDetails, mainIndex) => (
        <a
          key={mainIndex}
          className="dropdown-item"
          href={void 0}
          onClick={() =>
            this.tokenWhitelisting(
              tokenDetails.listedTokenSymbol,
              tokenDetails.listedTokenContractAddress
            )
          }
        >
          {tokenDetails.listedTokenSymbol}
        </a>
      ));
    }
    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper dealer-users-filter-element-wrapper">
              <div className="element-box">
                <h5 className="form-header">
                  <span>Whitelist Address Requests</span>
                </h5>
                <div className="clearfix"></div>
                <form className="form-inline mt-3">
                  <div className="row w-100">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <label className="sr-only"> Name</label>
                      <input
                        className="form-control input-full-width mb-2 mr-sm-2 mr-xs-1"
                        name="filterByName"
                        id="filterByName"
                        placeholder="Full Name"
                        type="text"
                        onChange={this.onchange}
                        value={filterByName}
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <label className="sr-only"> Email</label>
                      <input
                        className="form-control input-full-width mb-2 mr-sm-2 mr-xs-1"
                        placeholder="Email Address"
                        type="text"
                        name="filterByEmail"
                        id="filterByEmail"
                        onChange={this.onchange}
                        value={filterByEmail}
                      />
                    </div>
                  </div>
                </form>
                <div className="userList-btn-group">
                  <button
                    className="btn default-btn"
                    type="button"
                    onClick={this.filterRecords}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    className="btn default-border-btn ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    {" "}
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="element-wrapper mt-4">
              <div className="element-box">
                <h5 className="form-header flex-header flex-header-md">
                  <span>Manage Whitelisting Requests</span>
                  <span className="mgtp-xs-3">
                    <button
                      className="btn default-btn"
                      type="button"
                      onClick={() => this.refreshCurrenttable()}
                    >
                      Refresh
                    </button>
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="btn default-btn dropdown-toggle"
                      data-toggle="dropdown"
                      id="dropdownMenuButton6"
                      type="button"
                    >
                      Token Whitelisting
                    </button>
                    <div
                      aria-labelledby="dropdownMenuButton6"
                      className="dropdown-menu"
                    >
                      {tokenListHtml}
                    </div>
                  </span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Whitelistings;
