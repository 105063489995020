import React from "react";
import _ from "lodash";
import Api from "../../services/api";
import { Link } from "react-router-dom";

class DiligenceDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      diligenceDetails: [],
      diligenceTitles: {},
    };
  }

  async componentDidMount() {
    const api = new Api();
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;
    if (!_.isUndefined(userId)) {
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("user/diligence/details", { userId: userId });
        if (response.code === 200) {
          this.setState(
            {
              diligenceDetails: response.data.diligenceDetails,
              diligenceTitles: response.data.diligenceTitlesFinal,
              dataroomDiligenceDetails: response.data.dataroomDiligenceDetails,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
  }

  render() {
    const {
      diligenceDetails,
      diligenceTitles,
      dataroomDiligenceDetails,
    } = this.state;
    let availableDocumentsHtml = "";
    if (!_.isUndefined(diligenceDetails) && !_.isEmpty(diligenceDetails)) {
      const displayArray = {};
      _.map(diligenceDetails, function (questionaire) {
        if (_.isUndefined(displayArray[questionaire["digilenceId"]["type"]])) {
          displayArray[questionaire["digilenceId"]["type"]] = [];
        }
        let temp = {
          question: questionaire["digilenceId"]["label"],
          answer: questionaire["answerText"],
        };
        displayArray[questionaire["digilenceId"]["type"]].push(temp);
      });
      Object.keys(displayArray).forEach(function (key) {
        const values = displayArray[key];
        let options = "";
        _.map(values, function (questionaireTitle) {
          options += `<li><div class="mt-2 ml-2 text-default text-default-color">${questionaireTitle.question}</div><div class="ml-2 mt-1 text-default-color text-large"><b>${questionaireTitle.answer}</b></div></li>`;
        });
        availableDocumentsHtml += `<div class="element-box"><div><h6 class="element-header"><div><span class="text-xl">${diligenceTitles[key]}</span></div></h6><ol>${options}</ol></div></div>`;
      });
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <Link to="/users" className="pull-right m-3" style={{ marginTop: '4px', fontSize: '14px', color: '#395cbe' }}>
                Back to users list
              </Link>
              <div
                dangerouslySetInnerHTML={{ __html: availableDocumentsHtml }}
              ></div>
              {dataroomDiligenceDetails && (
                <div class="element-box mt-4">
                  <div>
                    <h6 className="element-header">
                      <div>
                        <span className="text-xl">Data Room URL</span>
                      </div>
                    </h6>
                    <p className="text-default text-default-color">
                      Cloud folder link which includes above documents{" "}
                      <a
                        href={dataroomDiligenceDetails.dataroomUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        URL
                      </a>
                    </p>
                  </div>
                </div>
              )}
              {((_.isNull(dataroomDiligenceDetails) || _.isEmpty(dataroomDiligenceDetails) || _.isUndefined(dataroomDiligenceDetails))  && (_.isEmpty(diligenceDetails) || _.isEmpty(diligenceDetails) || _.isUndefined(diligenceDetails))) && (
                <div className="element-box mt-4">
                  <div>
                    <h6 className="element-header">
                      <div>
                        <span className="text-xl">Due Dilligence Details not available!</span>
                      </div>
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DiligenceDetail;
