import React from 'react';
import { toast } from 'react-toastify';
class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};       
  }
  
  async componentDidMount(){
    let authenticationToken = this.props.authToken;
    if(authenticationToken !== '' && authenticationToken !== null) {
      toast.success('Logged out successfully.');
      this.props.logout();
    }
  }

  render() {
    return (
      <div className="register-container"></div>
      );
  }
}
export default Logout;